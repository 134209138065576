import styled from "styled-components";

export const Container = styled.div`
  display: flex; 
  align-items: flex-start; 
  justify-content: space-between;
  max-width: 862px;
  /* width: 100%; */
  height: 3rem;
  min-height: 3rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  overflow: hidden;
`;

export const RangeDataDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* max-width: 400px; */
  width: 100%;
  height: 100%;
`;

interface selectedDate {
  selectedIndex: boolean;
}

export const RangeData = styled.button<selectedDate>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;
  font-size: 13px;
  color: ${({ selectedIndex }) => selectedIndex ? "#0565D9" : "#656A6E"};
  background-color: ${({ selectedIndex }) => selectedIndex ? "#E6F0FB" : "transparent"};
  font-weight: bold;
  border-left: 1px solid #d1d5db; 
  border-right: 1px solid #d1d5db;
  cursor: pointer;
`;

export const NavigationButton = styled.button`
  height: 100%;
  width: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  padding: 6px;

  :disabled {
    background-color: #F5F5F5;
    
   .icon { 
    color: grey;
    }
  }
`;