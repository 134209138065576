import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1250px;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px;
`;

export const HeaderDataStepSession = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
`;

export const HeaderButtonsSession = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

export const Body = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
`;

export const Footer = styled.div`
  max-width: 100%;
  width: 99.6%;
  height: 60px;
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

  .buttonsFooterDiv {
    max-width: 1700px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
