import React, { useEffect, useState } from 'react';
import {
  Container,
  NavigationButton,
  RangeData,
  RangeDataDiv,
} from './styles/StyledDateNavigator';
import { ListTeleShifts } from '~/domain/usecases/shifts/remote';
import { makeRemoteListTeleShifts } from '~/main/factories/usecases/shifts/ListTeleShiftsFactory';
import { Icon } from '@wisecare-tech/design-system-web';
import { format, parseISO } from 'date-fns';

interface DateRange {
  shiftDate: {
    start: Date;
    end: Date;
  };
  setShiftDate: (data: ListTeleShifts.Model['records'][0]) => void;
  professionalId?: number;
  refetch?: boolean;
}

const DateRangeNavigator: React.FC<DateRange> = ({
  shiftDate,
  setShiftDate,
  professionalId,
  refetch,
}) => {
  const [ranges, setRanges] = useState<ListTeleShifts.Model['records']>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handlePrev = () => {
    setCurrentIndex(prev => prev - 1);
  };

  const handleNext = () => {
    setCurrentIndex(prev => prev + 1);
  };

  const handleDateRangeSelected = (index: number) => {
    setSelectedIndex(index);
    const selectedRange = ranges[index];

    if (selectedRange) {
      setShiftDate(selectedRange);
    }
  };

  const formatDateRange = (startDate: Date, endDate: Date): string => {
    const isoStartDate = format(parseISO(startDate.toString()), 'yyyy-MM-dd');

    const formattedStart = format(parseISO(startDate.toString()), 'dd/MM/yyyy');
    const formattedEnd = format(parseISO(endDate.toString()), 'dd/MM/yyyy');
    return `${formattedStart} até ${formattedEnd}`;
  };

  useEffect(() => {
    makeRemoteListTeleShifts()
      .list({
        filters: {
          user: professionalId,
        },
      })
      .then(response => {
        setRanges(response.records);
        const firstRange = response.records?.[0];
        setShiftDate(firstRange);
      })
      .catch(error => {
        console.log(error);
      });
  }, [professionalId, refetch]);

  if (!ranges.length) return null;

  return (
    <Container>
      <NavigationButton onClick={handlePrev} disabled={currentIndex === 0}>
        <Icon className="iconButton" name="chevron_left" size="large" />
      </NavigationButton>
      {!!ranges.length &&
        ranges.slice(currentIndex, currentIndex + 2).map((range, index) => (
          <RangeDataDiv key={index}>
            <RangeData
              onClick={() => handleDateRangeSelected(currentIndex + index)}
              selectedIndex={selectedIndex === currentIndex + index}
              autoFocus={currentIndex === 0}
            >
              {formatDateRange(range.start, range.end)}
            </RangeData>
          </RangeDataDiv>
        ))}
      <NavigationButton
        onClick={handleNext}
        disabled={currentIndex >= ranges.length - 2}
      >
        <Icon className="iconButton" name="chevron_right" size="large" />
      </NavigationButton>
    </Container>
  );
};

export default DateRangeNavigator;
