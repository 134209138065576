import { validateDate } from '~/utils/validDate';
import { validateCpf } from '~/utils/validCpf';
import * as z from 'zod';

export const schemaNewProfessionalShift = (requiredIndex?: boolean[]) =>
  z.object({
    startDate: z.date(),
    endDate: z.date(),
    checkedDays: z.array(
      z.object({ day: z.string().optional(), checked: z.boolean().optional() }),
    ),
    scheduleDays: z
      .array(
        z
          .object({
            start: z.date().optional(),
            end: z.date().optional(),
            day: z.string().optional(),
            restrictions: z
              .array(
                z.object({
                  module: z.string().optional(),
                  orgUnit: z.string().optional(),
                  specialty: z.string().optional(),
                  macroregion: z.string().optional(),
                }),
              )
              .optional(),
          })
          .optional(),
      )
      .optional()
      .refine(
        items =>
          requiredIndex?.every((item, index) => {
            if (items?.length) {
              console.log('>>>refine', item, items[index]);
              return item
                ? items[index]?.start &&
                    items[index]?.end &&
                    items[index]?.restrictions?.length &&
                    items[index]?.restrictions?.every(
                      restriction =>
                        restriction.module &&
                        restriction.orgUnit &&
                        restriction.specialty &&
                        restriction.macroregion,
                    )
                : true;
            } else return false;
          }),
        { message: 'Campo obrigatório' },
      ),
  });

export const createShiftValidator = z.object({
  professional: z.number().nonnegative('Profissional é obrigatório'),
  begin: z
    .string()
    .nonempty('Período inicial inválido')
    .min(5, 'Período inicial inválido'),
  end: z
    .string()
    .nonempty('Período final inválido')
    .min(5, 'Período final inválido'),
  day: z.enum(['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']),
  recurrence: z.any().nullable(),
});

export const updateShiftValidator = z.object({
  professional: z.number().nonnegative('Profissional é obrigatório').nullable(),
  begin: z
    .string()
    .nonempty('Período inicial inválido')
    .min(5, 'Período inicial inválido'),
  end: z
    .string()
    .nonempty('Período final inválido')
    .min(5, 'Período final inválido'),
});
