import { Center } from '@chakra-ui/react';
import { Schedule } from './../../components/menu/styles/StyledMenu';
import { columns } from './../DutyRequester/List/columns';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 72px);
  padding: 24px 72px;
  gap: 16px;
  overflow-y: auto;
  justify-content: center;
`;

export const Footer = styled.div`
  display: fixed;
  justify-content: space-between;
  padding: 16px 72px;
  height: 72px;
  border-top: 1px solid #dedede;
  background: #ffffff;

  div {
    height: 100%;
    display: flex;
    gap: 16px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 112px);
`;

export const Required = styled.span`
  color: red;
`;

export const ShiftDataContainer = styled.form`
  width: 100%;
  height: fit-content;
  border: 1px solid #dedede;
  border-radius: 8px;
  background: #ffffff;
  max-width: 78.5em;
`;

export const SchedulePlanContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
  padding: 16px 24px;

  .title {
    display: flex;
    flex-direction: column;
  }

  .schedule {
    display: flex;
    align-items: start;
    gap: 8px;
  }
`;

export const ScheduleDaysContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
`;
