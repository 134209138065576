import React from 'react';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import {
  Container,
  ContainerModal,
  BackgroundIcon,
  ConsultInfo,
  Content,
  Footer,
  Header,
  Information,
  Main,
} from '../styles/StyledAvailabilityModal';
import { Button, Icon, Typography } from '@wisecare-tech/design-system-web';

type Props = {
  message: iMessage;
};

export const AvailabilityModal: React.FC<Props> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.availabilityProfessionalScale;

  console.log('#data', data);

  return (
    <>
      {active === msgName && (
        <ContainerModal>
          <Container>
            <Header>
              <BackgroundIcon variant={data?.variant}>
                <Icon
                  name={data?.variant === 'UNAVAILABILITY' ? 'warning' : 'info'}
                  color={
                    data?.variant === 'UNAVAILABILITY' ? 'orange-7' : 'blue-7'
                  }
                  size="h6"
                />
              </BackgroundIcon>

              <Information>
                <Typography variant="t1_18semibold">
                  Informar {data?.variant === 'UNAVAILABILITY' && 'in'}
                  disponibilidade
                </Typography>
                <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
                  {data?.variant === 'UNAVAILABILITY' ? (
                    <>
                      Você está avisando que o profissional abaixo não poderá
                      estar presente. Deseja prosseguir?
                    </>
                  ) : (
                    <>
                      Você está avisando que o profissional abaixo ficará
                      disponível nessa data e hora. Deseja prosseguir?
                    </>
                  )}
                </Typography>
              </Information>
            </Header>
            <Main>
              <Content>
                <ConsultInfo>
                  <Typography
                    variant="b4_14regular"
                    style={{ color: '#656A6E' }}
                  >
                    Data:
                  </Typography>
                  <Typography variant="b4_14regular">{data?.date}</Typography>
                </ConsultInfo>
                <ConsultInfo>
                  <Typography
                    variant="b4_14regular"
                    style={{ color: '#656A6E' }}
                  >
                    Horário:
                  </Typography>
                  <Typography variant="b4_14regular">
                    {data?.hourStart}
                  </Typography>
                </ConsultInfo>
                <ConsultInfo>
                  <Typography
                    variant="b4_14regular"
                    style={{ color: '#656A6E' }}
                  >
                    Profissional:
                  </Typography>
                  <Typography variant="b4_14regular">
                    {data?.professionalName}
                  </Typography>
                </ConsultInfo>
                <ConsultInfo>
                  <Typography
                    variant="b4_14regular"
                    style={{ color: '#656A6E' }}
                  >
                    Profissão:
                  </Typography>
                  <Typography variant="b4_14regular">
                    {data?.profession}
                  </Typography>
                </ConsultInfo>
              </Content>
            </Main>
            <Footer>
              <Button variant="secondary" onClick={actionCancel}>
                Voltar
              </Button>
              <Button
                variant={
                  data?.variant === 'UNAVAILABILITY' ? 'destructive' : 'primary'
                }
                onClick={actionOk}
              >
                Sim, informar {data?.variant === 'UNAVAILABILITY' && 'in'}
                disponibilidade
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};
