import {
  Avatar,
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  Typography,
} from '@wisecare-tech/design-system-web';
import { format, parse, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { ShiftByDay } from '~/domain/interfaces/models/TeleShifts';
import { useTeleShifts } from '~/presentation/hooks/teleShifts';
import { ScaleProfessionalInfo } from '../scaleProfessionalInfo';
import { Popover } from '../UI/popover';
import {
  Container,
  DoctorInfo,
  DynamicContainer,
} from './style/StyledShiftsTable';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

interface ownProps {
  shift: ShiftByDay;
}

const ShiftsTable: React.FC<ownProps> = ({ shift }) => {
  const addTimezone = (time: string, timezone: number) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes + timezone);
    date.setSeconds(seconds);
    return format(date, 'HH:mm:ss');
  };

  const { selectUser } = useSelector((state: iStore) => state.auth);

  const marginByTime = (start: string, end: string, timezoneMin: number) => {
    const daySeconds = 24 * 60 * 60;

    const timeToSeconds = (time: string) => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return hours * 3600 + minutes * 60 + (seconds || 0);
    };

    const adjustedEnd = end === '00:00:00' ? '23:59:59' : end;

    const startSeconds = timeToSeconds(addTimezone(start, timezoneMin));
    const endSeconds = timeToSeconds(addTimezone(adjustedEnd, timezoneMin));

    const startPercentage = (startSeconds * 100) / daySeconds;
    const endPercentage = (endSeconds * 100) / daySeconds;

    return {
      start: startPercentage,
      end: 100 - endPercentage,
    };
  };

  const colorBySpecialtyMap: {
    [key: string]: { color: string; textColor: string };
  } = {
    Médico: {
      color: '#E6F0FB',
      textColor: '#0565D9',
    },
    Enfermeiro: {
      color: '#F4FDE9',
      textColor: '#458108',
    },
    'Assistente Social': {
      color: '#F4EDFC',
      textColor: '#723AB2',
    },
    Psicologia: {
      color: '#FFEFEA',
      textColor: '#9A3C1C',
    },
    Dentista: {
      color: '#FFF4F4',
      textColor: '#DD0404',
    },
    Nutricionista: {
      color: '#FAE8F4',
      textColor: '#A31775',
    },
  };

  const tableHeaderMap = [
    '00:00 - 06:00',
    '06:00 - 12:00',
    '12:00 - 18:00',
    '18:00 - 00:00',
  ];

  return (
    <>
      <Container>
        <Table>
          <TableHeader>
            <TableHead className="text-left">
              {shift.weekDay} -{' '}
              {format(parseISO(shift?.shiftDay), 'dd/MM/yyyy')}
            </TableHead>
            {tableHeaderMap.map((header, index) => (
              <TableHead
                key={index}
                className="text-left"
                style={{ padding: '0.3rem 0.5rem' }}
              >
                {header}
              </TableHead>
            ))}
          </TableHeader>
          {shift.shifts.map((shiftConverted, index) => (
            <TableRow className="custom-table-style" key={index}>
              <TableCell
                width={290}
                colSpan={1}
                className="last-child-border"
                style={{
                  borderRight: '1px solid #dedede',
                  padding: 0,
                  backgroundColor: '#FBFBFB',
                }}
              >
                <DoctorInfo
                  isTruncated={
                    shiftConverted?.unavailable && selectUser?.role === 'ADM'
                  }
                >
                  <div>
                    <Avatar
                      name={`${shiftConverted.user.firstName} ${shiftConverted.user.lastName}`}
                      size="md"
                      src={shiftConverted.user.avatar ?? ''}
                    />
                  </div>
                  <div className="doctor-info">
                    <Typography variant="t4_16medium">
                      <Tooltip
                        content={`${shiftConverted.user.firstName} ${shiftConverted.user.lastName}`}
                        side="right"
                      >
                        {`${shiftConverted.user.firstName} ${shiftConverted.user.lastName}`}
                      </Tooltip>
                    </Typography>
                    <Typography variant="b4_14regular">
                      {shiftConverted.user.professionName} |{' '}
                      {shiftConverted.user.professionDocName}:{' '}
                      {shiftConverted.user.registrationValue}
                    </Typography>
                  </div>
                  {shiftConverted?.unavailable &&
                    selectUser?.role === 'ADM' && (
                      <Typography
                        variant="b3_14medium"
                        style={{
                          height: 32,
                          borderRadius: '8px',
                          backgroundColor: '#ECEDEE',
                          padding: '8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#656A6E',
                        }}
                      >
                        Indisponível
                      </Typography>
                    )}
                </DoctorInfo>
              </TableCell>
              <TableCell
                colSpan={4}
                style={{
                  // backgroundColor: '#f8f8f8',
                  backgroundColor: '#FFFF',
                  padding: 0,
                }}
              >
                <Popover
                  fullWidth
                  content={
                    <ScaleProfessionalInfo
                      shift={shiftConverted}
                      date={shift?.shiftDay}
                    />
                  }
                >
                  <DynamicContainer
                    bgColor={
                      colorBySpecialtyMap[shiftConverted.user.professionName]
                        .color
                    }
                    textColor={
                      colorBySpecialtyMap[shiftConverted.user.professionName]
                        .textColor
                    }
                    {...marginByTime(
                      shiftConverted.date.start,
                      shiftConverted.date.end,
                      shiftConverted.date.timezone,
                    )}
                  >
                    <Typography variant="b3_14medium">
                      <Tooltip
                        content={`${addTimezone(shiftConverted.date.start, shiftConverted.date.timezone).slice(0, 5)} - ${addTimezone(shiftConverted.date.end, shiftConverted.date.timezone).slice(0, 5)}`}
                        side="left"
                      >
                        {addTimezone(
                          shiftConverted.date.start,
                          shiftConverted.date.timezone,
                        ).slice(0, 5)}{' '}
                        -{' '}
                        {addTimezone(
                          shiftConverted.date.end,
                          shiftConverted.date.timezone,
                        ).slice(0, 5)}
                      </Tooltip>
                    </Typography>
                    {shiftConverted.restrictions.map((restriction, index) => (
                      <p key={index}>
                        <Tooltip
                          content={`${restriction.module.description} - ${restriction.orgUnit.fullname} - ${restriction.specialty.name} - ${restriction.macroregion.name}`}
                          side="left"
                        >
                          {restriction.module.description} -{' '}
                          {restriction.orgUnit.fullname} -{' '}
                          {restriction.specialty.name} -{' '}
                          {restriction.macroregion.name}
                        </Tooltip>
                      </p>
                    ))}
                  </DynamicContainer>
                </Popover>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Container>
    </>
  );
};

export default ShiftsTable;
