import React, { useEffect, useState } from 'react';
import { Container, NavigationButton, RangeData, RangeDataDiv } from './styles';
import { ListShiftsAvailable } from '~/domain/usecases/shifts/remote';
import { makeRemoteListTeleShifts } from '~/main/factories/usecases/shifts/ListTeleShiftsFactory';
import { Icon } from '@wisecare-tech/design-system-web';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface DateRange {
  shiftDate: ListShiftsAvailable.Model['records'];
  getDate: (date: any) => void;
}

const AvailabilityStepper: React.FC<DateRange> = ({ shiftDate, getDate }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const visibleItems = 6;

  const handlePrev = () => {
    setCurrentIndex(prev => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex(prev =>
      Math.min(prev + 1, shiftDate.length - visibleItems),
    );
  };

  const getDayRange = (dateStr: string) => {
    const begin = `${dateStr}Z00:00:00`;
    const end = `${dateStr}Z23:59:59`;

    return {
      begin,
      end,
    };
  };

  const handleSelectedDate = (index: number, date: string) => {
    setSelectedIndex(index);
    getDate(getDayRange(date));
  };

  return (
    <Container>
      <NavigationButton onClick={handlePrev} disabled={currentIndex === 0}>
        <Icon className="iconButton" name="chevron_left" size="large" />
      </NavigationButton>
      {shiftDate
        .slice(currentIndex, currentIndex + visibleItems)
        .map((date, index) => (
          <RangeDataDiv key={index}>
            <RangeData
              selectedIndex={index + currentIndex === selectedIndex}
              onClick={() =>
                handleSelectedDate(index + currentIndex, date.date)
              }
            >
              {format(new Date(date.date + 'T00:00:00'), 'EEEE, dd/MM', {
                locale: ptBR,
              })}
            </RangeData>
          </RangeDataDiv>
        ))}
      <NavigationButton
        onClick={handleNext}
        disabled={currentIndex >= shiftDate.length - visibleItems}
      >
        <Icon className="iconButton" name="chevron_right" size="large" />
      </NavigationButton>
    </Container>
  );
};

export default AvailabilityStepper;
