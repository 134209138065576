import React from 'react';
import { Button } from '../UI';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { Icon } from '@wisecare-tech/design-system-web';

interface iVisualizeShiftDaysButtonProps {
  participant: any;
}

export const VisualizeShiftDaysButton: React.FC<
  iVisualizeShiftDaysButtonProps
> = ({ participant }) => {
  return (
    <Button
      variant="secundary"
      size="medium"
      height="48px"
      color="#000"
      onClick={() =>
        makeReduxActiveMessage().active({
          active: 'availability',
          actionOk: () => closeModal(),
          actionCancel: () => closeModal(),
          data: participant,
        })
      }
    >
      <Icon size="small" name="timer" />
      <div style={{ marginLeft: 8 }}>Visualizar escala</div>
    </Button>
  );
};
