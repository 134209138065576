import {
  Button,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
} from '@wisecare-tech/design-system-web';
import React, { useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { makeRemoteReviewDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ReviewDiagnosisReportFactory';
import { makeRemoteUpdateDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/UpdateDiagnosisReportFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { History } from '~/main/routes';
import { PageHeader } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { useRemoteReport } from '~/presentation/hooks/remoteReport';
import { useGetDiagnosisContext } from '~/presentation/hooks/remoteReport/loadData';
import { closeModal } from '~/utils/closeModal';
import { EditDiagnosisReport } from '~/validation/validators/editDiagnosisReport';
import { ReturnHistory } from './history';
import { FilesSection } from './sections/Files';
import { MedicalReportSection } from './sections/MedicalReport';
import { PatientSection } from './sections/Patient';
import { ResponsibleSection } from './sections/Responsible';
import {
  BodyContainer,
  ButtonsContainerSecondary,
  Container,
  Content,
  Footer,
  FooterContent,
  SectionsContainer,
  Subheader,
} from './styles';

interface StateParams {
  canEdit: boolean;
  canDownload: boolean;
  canReport: boolean;
  canReview: boolean;
}

export const RemoteReportView: React.FC = () => {
  const { disableField, startEdit, cancelEdit } = useRemoteReport();
  const { getValues, handleSubmit, formState, watch } =
    useFormContext<EditDiagnosisReport>();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [tab, setTab] = useState<string>('request');
  const [loading, setLoading] = useState<boolean>(false);

  const locationState = useLocation<StateParams>().state;
  const canDownload = locationState?.canDownload ?? false;
  const canEdit = locationState?.canEdit ?? false;
  const canReport = locationState?.canReport ?? false;
  const canReview = locationState?.canReview ?? false;

  const { diagnosisData } = useGetDiagnosisContext();

  const url = new URL(window.location.href);

  const handleUrl = (tab: string) => {
    url.searchParams.set('tab', tab);
    window.history.replaceState({}, '', url.toString());
  };

  const defaultTab = url.searchParams.get('tab') ?? 'request';

  const submitForm = async () => {
    const updateDiagnosisReport = makeRemoteUpdateDiagnosisReport();
    const values = getValues();

    const formattedFiles =
      values.files?.map(file => ({
        id: file?.id,
        examType: file?.examType,
      })) ?? [];

    try {
      const birthDate = values.consultantExternal?.birthdate ?? '';
      const formattedBirthDate = birthDate.split('/').reverse().join('-');

      await updateDiagnosisReport
        .update({
          query: {
            id: diagnosisData?.id ?? 0,
          },
          body: {
            healthUnit: values.healthUnit?.id,
            modality: values.modality?.id,
            specialty: values.specialty?.id,
            specialist: values.specialist?.id ?? null,
            priority: values.priority?.value,
            consultantInfo: values.consultantInfo ?? undefined,
            files: formattedFiles,
            status: 'SUBMITTED',
            description: values.description,
            ...(values?.consultantExternal?.id
              ? {
                  consultant: values.consultantExternal.id,
                }
              : {
                  consultantExternal: {
                    birthdate: formattedBirthDate,
                    docValue: values.consultantExternal?.docValue,
                    mothername: values.consultantExternal?.mothername,
                    phone: values.consultantExternal?.phone,
                    fullname: values.consultantExternal?.fullname,
                    sex: values.consultantExternal?.sex?.value,
                    docType: values.consultantExternal?.docType?.name,
                  },
                }),
          },
        })
        .then(() => {
          AlertMessage({
            type: 'success',
            message: 'Solicitação enviada com sucesso',
          });

          History.getHistory().push('/diagnosis');
        });
      cancelEdit();
    } catch (err) {
      console.log('error', err);
    }
  };

  const submitPendingForm = async () => {
    const reviewDiagnosisReport = makeRemoteReviewDiagnosisReport();
    const values = getValues();

    const formattedFiles =
      values.files?.map(file => ({
        id: file?.id,
        examType: file?.examType,
      })) ?? [];

    try {
      const birthDate = values.consultantExternal?.birthdate ?? '';
      const formattedBirthDate = birthDate.split('/').reverse().join('-');

      await reviewDiagnosisReport
        .review({
          id: diagnosisData?.id ?? 0,
          body: {
            description: values?.description ?? undefined,
            consultantInfo: values?.consultantInfo ?? undefined,
            files: formattedFiles,
            ...(values?.consultantExternal?.id
              ? {
                  consultant: values.consultantExternal.id,
                }
              : {
                  consultantExternal: {
                    fullname: values.consultantExternal?.fullname ?? '',
                    sex: values.consultantExternal?.sex?.value ?? '',
                    birthdate: formattedBirthDate ?? '',
                    docType: values.consultantExternal?.docType?.name ?? '',
                    docValue: values.consultantExternal?.docValue ?? '',
                    mothername: values.consultantExternal?.mothername ?? '',
                    phone: values.consultantExternal?.phone ?? '',
                  },
                }),
          },
        })
        .then(() => {
          AlertMessage({
            type: 'success',
            message: 'Solicitação enviada com sucesso',
          });

          History.getHistory().push('/diagnosis');
        });
      cancelEdit();
    } catch (err) {
      console.log('error', err);
    }
  };

  const sketchDiagnosisReport = async () => {
    const updateDiagnosisReport = makeRemoteUpdateDiagnosisReport();
    const values = getValues();

    const formattedFiles =
      values.files?.map(file => {
        console.log('file', file);
        return {
          ...file,
          id: file?.id,
          examType: file?.examType,
        };
      }) ?? [];

    try {
      const birthDate = values.consultantExternal?.birthdate ?? '';
      const formattedBirthDate = birthDate.split('/').reverse().join('-');

      await updateDiagnosisReport
        .update({
          query: {
            id: diagnosisData?.id ?? 0,
          },
          body: {
            healthUnit: values.healthUnit?.id,
            modality: values.modality?.id,
            specialty: values.specialty.id,
            specialist: values.specialist?.id,
            priority: values.priority?.value,
            consultantInfo: values.consultantInfo ?? undefined,
            status: 'EDITING',
            files: formattedFiles,
            description: values.description,
            ...(values?.consultantExternal?.id
              ? {
                  consultant: values.consultantExternal.id,
                }
              : {
                  consultantExternal: {
                    birthdate: formattedBirthDate,
                    docValue: values.consultantExternal?.docValue,
                    mothername: values.consultantExternal?.mothername,
                    phone: values.consultantExternal?.phone,
                    fullname: values.consultantExternal?.fullname,
                    sex: values.consultantExternal?.sex?.value,
                    docType: values.consultantExternal?.docType?.name,
                  },
                }),
          },
        })
        .then(() => {
          AlertMessage({
            type: 'success',
            message: 'Solicitação salva como rascunho',
          });

          History.getHistory().push('/diagnosis');
        });
      cancelEdit();
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (
      (event.nativeEvent as SubmitEvent).submitter === submitButtonRef.current
    ) {
      if (disableField) {
        startEdit();
        return;
      }

      const callback =
        diagnosisData?.status === 'PENDING' ? submitPendingForm : submitForm;

      handleSubmit(callback, err => {
        AlertMessage({
          type: 'danger',
          message: 'Falha na validação ao salvar solicitação',
        });
        console.log('invalid errors', err);
        console.log('invalid values', getValues());
      })();
    }
  };

  const atLeastOneFileWithoutExamType = useMemo(() => {
    const files = watch('files') ?? [];
    return files.some(file => !file?.examType);
  }, [watch('files')]);

  const hasErrors = useMemo(() => {
    if (formState.errors.healthUnit?.message) return true;
    if ((formState.errors.specialty as { message?: string })?.message)
      return true;
    return false;
  }, [formState]);

  const disableSubmit = useMemo(() => {
    if (!canEdit && diagnosisData?.status !== 'PENDING') return true;
    if (diagnosisData?.status === 'PENDING' && !canReview) return true;
    if (loading) return true;
    if (hasErrors) return true;
    if (tab === 'history') return true;
    if (!disableField && atLeastOneFileWithoutExamType) return true;
    if (
      diagnosisData &&
      !(
        diagnosisData?.status === 'EDITING' ||
        diagnosisData?.status === 'SUBMITTED' ||
        diagnosisData?.status === 'PENDING'
      )
    )
      return true;

    return false;
  }, [
    diagnosisData,
    canEdit,
    loading,
    hasErrors,
    canReview,
    tab,
    disableField,
    atLeastOneFileWithoutExamType,
  ]);

  console.log('>>> CanEdit: ', canEdit);
  console.log('>>> CanDownload: ', canDownload);
  console.log('>>> FormState errors: ', formState.errors);

  return (
    <Container onSubmit={handleFormSubmit}>
      <div
        style={{
          width: '100%',
          height: 'auto',
          backgroundColor: '#fff',
          position: 'sticky',
          zIndex: 10,
          top: 0,
        }}
      >
        <Menu />
        <PageHeader backDescription="Voltar para todos" title="Visualização" />
      </div>
      <BodyContainer>
        <Content>
          <Tabs
            defaultValue={defaultTab}
            value={tab}
            onValueChange={setTab}
            style={{ width: '100%', borderRadius: '0.5rem' }}
          >
            <TabsList>
              <TabsTrigger value="request" onClick={() => handleUrl('request')}>
                Solicitação
              </TabsTrigger>
              <TabsTrigger
                value="history"
                disabled={!disableField || diagnosisData?.pendencies === null}
                onClick={() => handleUrl('history')}
              >
                {`Histórico de devolução (${diagnosisData?.pendencies?.length ?? 0})`}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="request">
              <SectionsContainer>
                <MedicalReportSection />

                <ResponsibleSection />

                <PatientSection />

                <FilesSection
                  canDownload={canDownload}
                  handleLoading={setLoading}
                />
              </SectionsContainer>
            </TabsContent>
            <TabsContent value="history">
              <SectionsContainer>
                <ReturnHistory />
              </SectionsContainer>
            </TabsContent>
          </Tabs>
        </Content>
      </BodyContainer>
      <Footer>
        <FooterContent>
          {!disableField ? (
            <Button
              variant="outlinedDestructive"
              size="md"
              onClick={cancelEdit}
              type="button"
            >
              Cancelar
            </Button>
          ) : (
            <div />
          )}
          <ButtonsContainerSecondary>
            {!disableField && diagnosisData?.status !== 'PENDING' && (
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  makeReduxActiveMessage().active({
                    active: 'sketchReport',
                    actionOk: () => {
                      sketchDiagnosisReport();
                      closeModal();
                    },
                    actionCancel: () => {
                      closeModal();
                    },
                  });
                }}
                disabled={disableSubmit}
              >
                Salvar como rascunho
              </Button>
            )}
            <Button
              variant={disableField && canReport ? 'outlined' : 'primary'}
              type="submit"
              disabled={disableSubmit}
              ref={submitButtonRef}
            >
              {disableField ? 'Editar solicitação' : 'Salvar alterações'}
            </Button>

            {disableField && canReport && (
              <Button
                variant="primary"
                icon="signature"
                onClick={() => {
                  History.getHistory().push(
                    `/diagnosis/report/${diagnosisData?.id}`,
                  );
                }}
                disabled={
                  loading ||
                  tab === 'history' ||
                  (diagnosisData &&
                    !(
                      diagnosisData?.status === 'EDITING' ||
                      diagnosisData?.status === 'SUBMITTED' ||
                      diagnosisData?.status === 'RETURNED' ||
                      diagnosisData?.status === 'PENDING'
                    ))
                }
              >
                Laudar
              </Button>
            )}
          </ButtonsContainerSecondary>
        </FooterContent>
      </Footer>
    </Container>
  );
};
