import React from 'react';

import { Container } from './styles';
import DayColapse from '../DayColapse';

interface ownProps {
  disabledIndex: boolean[];
}

const ScheduleDay: React.FC<ownProps> = ({ disabledIndex }) => {
  const days = [
    { key: 'SU', id: 'SU', title: 'Domingo' },
    { key: 'MO', id: 'MO', title: 'Segunda' },
    { key: 'TU', id: 'TU', title: 'Terça' },
    { key: 'WE', id: 'WE', title: 'Quarta' },
    { key: 'TH', id: 'TH', title: 'Quinta' },
    { key: 'FR', id: 'FR', title: 'Sexta' },
    { key: 'SA', id: 'SA', title: 'Sábado' },
  ];

  return (
    <Container>
      {days.map((day, index) => (
        <DayColapse
          key={day.key}
          id={day.id as 'SU' | 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA'}
          title={day.title}
          disabled={disabledIndex[index]}
        />
      ))}
    </Container>
  );
};

export default ScheduleDay;
