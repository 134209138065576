import styled from 'styled-components';

interface ActiveDropdown {
  open: boolean;
}

export interface ChipsStatus {
  status: 'availableAll' | 'unavailable' | 'available';
}

export interface ScheduleWarning {
  color: string;
  border: string;
}

export interface ScheduleWarningText {
  color: string;
}

const chipsStatus: Record<string, { background: string; border: string }> = {
  availableAll: {
    background: '#f4fde9',
    border: '#458108',
  },
  available: {
    background: '#f8f8f8',
    border: '#b4b7b9',
  },
  unavailable: {
    background: '#f2f2f2',
    border: '#dedede',
  },
};

export const Container = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11;
`;

export const Content = styled.div`
  min-width: 882px;
  /* min-height: 362px; */
  position: fixed;
  padding: 20px 30px;
  border-radius: 5px;
  background: #ffffff;
`;

export const TitleText = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 21.09px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #222529;

  margin-bottom: 8px;
`;

export const SubTitleText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #656a6e;

  margin-bottom: 16px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: end;
  height: 100%;
  gap: 24px;
  /* margin-top: 230px; */
  margin-top: 16px;
`;

export const CheckBoxView = styled.div`
  display: flex;
  align-items: center;
`;

export const ScheduleView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 10px;
  margin-top: 16px;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 400px);
`;

export const ScheduleInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ScheduleItem = styled.div<ActiveDropdown>`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 16px;

  height: ${({ open }) => (open ? 'auto' : '72px')};
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dedede;
`;

export const ProfileInfoView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
`;

export const ProfesionalName = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const ProfesionalRole = styled.div`
  //styleName: b3_14regular;
  display: flex;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const DropdownView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 32px;
  height: 32px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #dedede;
`;

export const ChipView = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
  margin-top: 16px;
  /* max-height: 150px; Adjust based on your needs */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 8px;
`;

export const BoxLegend = styled.div<ChipsStatus>`
  display: flex;
  height: 18px;
  width: 18px;
  background-color: ${({ status }) => chipsStatus[status].background};
  border: ${({ status }) => `1px solid ${chipsStatus[status].border}`};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export const ScheduleWarning = styled.div<ScheduleWarning>`
  border: ${({ border }) => `1px solid ${border}`};
  border-radius: 4px;
  background-color: ${({ color }) => color};
  padding: 8px;

  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

export const ScheduleWarningText = styled.div<ScheduleWarningText>`
  //styleName: b3_14medium;
  font-size: 14px;
  font-weight: 500;
  color: ${({ color }) => color};
`;
