import styled from 'styled-components';

interface DynamicContainerProps {
  fullWidth?: boolean;
}

export const Container = styled.div<DynamicContainerProps>`
  width: ${props => props.fullWidth ? '100%' : 'fit-content'};
  height: fit-content;
`;
