import React, { forwardRef } from 'react';
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '~/presentation/base/styles/datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { ptBR } from 'date-fns/locale';
import { HeaderContainer, Text } from './styles/StyledDateInput';
import { IconNextMonth, IconPrevMonth } from '~/presentation/base/icons';

type DateInputProps = Omit<ReactDatePickerProps, 'onChange'> & {
  label?: string;
  withMask?: boolean;
};

const DatePickerField: React.FC<DateInputProps> = ({
  name,
  label,
  withMask = true,
  ...props
}) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const customHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) => (
    <HeaderContainer>
      <button
        type="button"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <IconPrevMonth />
      </button>
      <Text>
        {monthDate.toLocaleString('pt-Br', {
          month: 'long',
          year: 'numeric',
        })}
      </Text>
      <button
        type="button"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <IconNextMonth />
      </button>
    </HeaderContainer>
  );

  name &&
    console.log('>>>DatePickerField', watch(name), name, errors[name], errors);

  return (
    <Controller
      name={name as string}
      control={control}
      render={(field, fieldState) => {
        console.log('>>>Field', field, fieldState);
        return (
          <DatePicker
            {...props}
            selected={watch(name) || null}
            onChange={val => {
              console.log('>>>DatePickerField', val);
              name && setValue(name, val);
            }}
            locale={ptBR}
            renderCustomHeader={customHeader}
          />
        );
      }}
    />
  );
};

export default DatePickerField;
