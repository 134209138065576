import { ColumnDef } from '@tanstack/react-table';
import {
  Checkbox,
  IconButton,
  SortButton,
  Tooltip,
  Typography,
} from '@wisecare-tech/design-system-web';
import axios from 'axios';
import _ from 'lodash';
import { FieldError, UseFormMethods } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeApiUrl } from '~/main/factories/http';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import SearchSelect from '~/presentation/components/UI/searchSelect';
import { useGetDiagnosisContext } from '~/presentation/hooks/remoteReport/loadData';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { EditDiagnosisReport } from '~/validation/validators/editDiagnosisReport';
import { SendedByContainer, SendedByName, SendedBySector } from './styles';

interface File {
  id?: number;
  filename: string;
  examType?: string;
  size?: string;
  sentBy?: {
    id?: number;
    fullname?: string;
    avatar?: string;
    healthUnit?: string;
  };
  mimetype?: string;
  created?: string;
}

const SendedByCell: React.FC<{ name: string; sector: string }> = ({
  name,
  sector,
}) => {
  return (
    <SendedByContainer>
      <SendedByName variant="b4_14regular">{name}</SendedByName>
      <SendedBySector variant="b4_14regular">{sector}</SendedBySector>
    </SendedByContainer>
  );
};

export const columns: ({
  disableField,
  canDownload,
  form,
}: {
  disableField: boolean;
  canDownload?: boolean;
  form: UseFormMethods<EditDiagnosisReport>;
  // }) => ColumnDef<File>[] = ({
}) => ColumnDef<any>[] = ({
  disableField,
  canDownload,
  form,
}: {
  disableField: boolean;
  canDownload?: boolean;
  form: UseFormMethods<EditDiagnosisReport>;
}) => {
  const { records } = useSelector(
    (store: iStore) => store.diagnosisReport.examTypes,
  );

  const { user } = useSelector((store: iStore) => store.auth.info);
  const { token } = useSelector((store: iStore) => store.auth.access);

  const profInfo = getProfessionalInfo();
  const { diagnosisData } = useGetDiagnosisContext();

  const generateExamOptions = () => {
    return _.sortBy(
      records?.map(option => ({
        label: option.descr,
        value: option.id,
      })),
      option => option?.label?.replace(/\s/g, '').toLocaleLowerCase(),
    );
  };

  const examOptionSelected = (index: number) => {
    return generateExamOptions().find(
      option => option.value === form.getValues().files?.[index]?.examType,
    );
  };

  return [
    {
      id: 'checkbox',
      accessorKey: 'checkbox',
      header: ({ table }) => (
        <Checkbox
          disabled={disableField}
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && 'indeterminate')
          }
          onCheckedChange={(e: any) => {
            table.toggleAllRowsSelected(!!e);
          }}
          aria-label="Selecionar todos os arquivos"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          disabled={disableField}
          onCheckedChange={(e: any) => {
            row.toggleSelected(!!e);
          }}
          aria-label="Selecionar arquivo"
        />
      ),
    },
    {
      accessorKey: 'filename',
      header: ({ column }) => (
        <SortButton column={column}>Nome do arquivo</SortButton>
      ),
      cell: ({ row }) => (
        <Typography variant="b4_14regular">
          {row.getValue('filename')}
        </Typography>
      ),
    },
    {
      accessorKey: 'examType',
      header: ({ column }) => {
        return <SortButton column={column}>Tipo de exame</SortButton>;
      },
      cell: ({ row }) => {
        const error: { examType: FieldError } | undefined =
          (form.errors?.files as unknown as Array<{ examType: FieldError }>)?.[
            row.index
          ] ?? undefined;

        return disableField ? (
          <div>
            {generateExamOptions().find(
              option => option.value === row.getValue('examType'),
            )?.label ?? '-'}
          </div>
        ) : (
          <div
            style={{
              width: '160px',
            }}
          >
            <SearchSelect
              options={generateExamOptions()}
              value={examOptionSelected(row.index)}
              onChange={value => {
                const files = form.watch('files') ?? [];

                const updatedFiles = files?.map((file, index) => {
                  if (index === row.index) {
                    return { ...file, examType: Number(value?.value) };
                  }
                  return file;
                });
                form.setValue('files', updatedFiles);
              }}
              error={
                !!error?.examType ||
                (!disableField &&
                  !form.getValues().files?.[row.index]?.examType)
              }
              message={
                error?.examType?.message ||
                (!disableField &&
                  !form.getValues().files?.[row.index]?.examType)
                  ? 'Tipo de exame é obrigatório'
                  : undefined
              }
            />
          </div>
        );
      },
    },
    {
      accessorKey: 'sentBy',
      header: ({ column }) => (
        <SortButton column={column}>Enviado por</SortButton>
      ),
      cell: ({ row }) => {
        const sentBy = row.getValue('sentBy') as File['sentBy'];

        return (
          <SendedByCell
            name={sentBy?.fullname ?? `${user?.firstName} ${user?.lastName}`}
            sector={sentBy?.healthUnit ?? profInfo?.orgUnit?.name ?? ''}
          />
        );
      },
    },
    {
      accessorKey: 'size',
      header: ({ column }) => <SortButton column={column}>Tamanho</SortButton>,
      cell: ({ row }) => {
        const size = row.getValue('size');
        const sizeInMb = Number(size) / 1024 / 1024;
        return sizeInMb ? `${sizeInMb.toFixed(2)}mb` : '';
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Tooltip content="Baixar arquivo" side="top" arrow>
              <IconButton
                color="blue-7"
                variant="primary"
                size="sm"
                disabled={!canDownload}
                icon="download"
                onClick={() => {
                  console.log('row: ', row);
                  console.log('row id: ', row.id);

                  axios
                    .get(
                      makeApiUrl(
                        `/reports/${diagnosisData?.id}/files/${row.original.id}/DOWNLOAD`,
                      ),
                      {
                        responseType: 'blob',
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      },
                    )
                    .then(response => {
                      const file = new Blob([response.data], {
                        type: response.data.type,
                      });
                      const fileURL = URL.createObjectURL(file);
                      window.open(fileURL);
                    })
                    .catch(error => {
                      AlertMessage({
                        message: 'Erro ao abrir arquivo',
                        type: 'danger',
                      });
                    });
                }}
              />
            </Tooltip>
            <IconButton
              color="red-7"
              variant="destructive"
              size="sm"
              disabled={disableField}
              onClick={() => {
                const files = form.watch('files') ?? [];

                const deletedfiles = files?.filter(
                  (item, index) => index !== row.index,
                );

                form.setValue('files', deletedfiles);
              }}
              icon="delete"
            />
          </div>
        );
      },
    },
  ];
};
