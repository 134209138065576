import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ReviewDiagnosisReport as UsecaseRemoteReviewDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';

import { RemoteReviewDiagnosisReport } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */
export const makeRemoteReviewDiagnosisReport =
  (): UsecaseRemoteReviewDiagnosisReport =>
    new RemoteReviewDiagnosisReport(
      makeApiUrl('/reports/{report}/REVIEW'),
      makeHttpClient(),
    );
