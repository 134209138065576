import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.background};
  overflow: auto;
  position: relative;
`;

export const Body = styled.div`
  margin-top: 40px;
  width: 98%;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 300px 1fr;

  #left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #right {
    overflow-x: scroll;
  }

  #header {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
  }
`;

export const Chat = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  color: #7a7a7a;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const IconBalon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
`;

export const HistoryComponent = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;

export const Attendiment = styled.div`
  width: 25px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
`;

export const ButtonBig = styled.div`
  margin-left: 10px;
  padding: 10px;
  .btnAction {
    width: 200px;
  }
`;
export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 11;
`;

export const Drop = styled.div`
  display: flex;
  align-items: center;

  width: 200px;
  height: 40px;
  background-color: #1756e8;

  padding: 8px 25px;
  border-radius: 6px;

  cursor: pointer;
`;

export const OptionsBox = styled.div`
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.08);
  /* border: 1px #ECECEC solid; */
  width: 100%;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  padding: 16px;

  /* border-radius: 10px; */
  gap: 32px;

  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #ececec;
`;

export const Text = styled.div`
  color: white;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 21px;
  word-wrap: break-word;
`;

export const OptionText = styled.div`
  color: #656a6e;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
  word-wrap: break-word;
`;

export const Space = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  cursor: pointer;
`;
