import { Input, Textarea } from '@wisecare-tech/design-system-web';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { RegionalDoc } from '~/domain/usecases/regionaldoc/remote';
import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';
import { makeRemoteGetAllRegionalDocs } from '~/main/factories/usecases/regionaldoc/GetAllRegionalDocs';
import SearchSelect from '~/presentation/components/UI/searchSelect';
import { useRemoteReport } from '~/presentation/hooks/remoteReport';
import { useGetDiagnosisContext } from '~/presentation/hooks/remoteReport/loadData';
import { Form, FormRow, SectionContainer, SectionLabel } from '../styles';

const GENDER_MAP: Record<string, string> = {
  MALE: 'Masculino',
  FEMALE: 'Feminino',
  OTHER: 'Outro',
};

export const PatientSection: React.FC = () => {
  const { disableField } = useRemoteReport();
  const [docsType, setDocsType] = useState<RegionalDoc[]>([]);

  const { diagnosisData } = useGetDiagnosisContext();

  const [specialistIsNotExternal, setSpecialistIsNotExternal] = useState(false);

  const { control, setValue } = useFormContext();

  const { results: consultants } = useSelector(
    (store: iStore) => store.consultant,
  );

  const { orgId, role, orgUnits } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const loadDocsType = useCallback(async () => {
    const response = await makeRemoteGetAllRegionalDocs().getAll({
      country: 'BRA',
      disablePagination: true,
    });

    setDocsType(response.records);
  }, []);

  const createOption = (fullname: string, id?: number) => ({
    fullname,
    id: id ?? fullname.toLowerCase().replace(' ', ''),
  });

  const consultantOptions = useMemo(() => {
    return consultants.map(consultant => {
      return {
        ...createOption(
          `${consultant.user?.firstName} ${consultant.user?.lastName}`,
          consultant.consultant.id,
        ),
        ...(consultant.consultant.id && consultant),
      };
    });
  }, [consultants]);

  useEffect(() => {
    loadDocsType();
  }, [loadDocsType]);

  useEffect(() => {
    setSpecialistIsNotExternal(
      !!diagnosisData?.consultant && !diagnosisData?.consultantExternal,
    );
  }, [diagnosisData]);

  useEffect(() => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit:
          role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  }, [orgUnits, orgId, role]);

  return (
    <SectionContainer>
      <SectionLabel>Paciente</SectionLabel>
      <Form>
        <FormRow gap="1rem">
          <Controller
            name="consultantExternal"
            control={control}
            render={field => (
              <SearchSelect
                label="Nome completo"
                required
                isDisabled={disableField}
                placeholder="Nome completo do paciente"
                options={consultantOptions}
                getOptionLabel={option => option.fullname}
                getOptionValue={option => option.id}
                width="100%"
                {...field}
                isValidNewOption={(inputValue, selectValue) => {
                  return !selectValue.some(
                    option => option.fullname === inputValue,
                  );
                }}
                getNewOptionData={inputValue => ({
                  fullname: inputValue,
                })}
                formatCreateLabel={inputValue => `Criar "${inputValue}"`}
                formatOptionLabel={option => option.fullname}
                onChange={option => {
                  console.log('option', option);
                  if (option?.id) {
                    const customOption = {
                      ...createOption(
                        `${option.user?.firstName} ${option.user?.lastName}`,
                        option.id,
                      ),
                      sex: {
                        value: option?.user?.gender,
                        label: GENDER_MAP[option?.user?.gender] as string,
                      },
                      birthdate: option?.user?.birthdate,
                      docType: { name: option?.documents?.[0].name as string },
                      docValue: option?.documents?.[0]?.value,
                      mothername: option?.user?.motherName,
                      phone: option?.user?.phone,
                    };
                    field.onChange(customOption);
                    setSpecialistIsNotExternal(true);
                    setValue('consultantExternal.sex', customOption.sex);
                    setValue(
                      'consultantExternal.birthdate',
                      customOption.birthdate,
                    );
                    setValue('consultantExternal.docType', {
                      name: customOption.docType?.name,
                    });
                    setValue(
                      'consultantExternal.docValue',
                      customOption.docValue,
                    );
                    setValue(
                      'consultantExternal.mothername',
                      customOption.mothername,
                    );
                    setValue('consultantExternal.phone', customOption.phone);
                  } else {
                    field.onChange({
                      id: null,
                      fullname: option.fullname,
                    });
                    setSpecialistIsNotExternal(false);
                    setValue('consultantExternal.id', null);
                    setValue('consultantExternal.sex', undefined);
                    setValue('consultantExternal.birthdate', undefined);
                    setValue('consultantExternal.docType', undefined);
                    setValue('consultantExternal.docValue', '');
                    setValue('consultantExternal.mothername', '');
                    setValue('consultantExternal.phone', '');
                  }
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="consultantExternal.sex"
            render={field => {
              return (
                <SearchSelect
                  label="Sexo"
                  required
                  placeholder="Selecione uma opção"
                  isDisabled={disableField || specialistIsNotExternal}
                  options={[
                    {
                      label: 'Masculino',
                      value: 'MALE',
                    },
                    {
                      label: 'Feminino',
                      value: 'FEMALE',
                    },
                    {
                      label: 'Outro',
                      value: 'OTHER',
                    },
                  ]}
                  width="182px"
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.value}
                  {...field}
                />
              );
            }}
          />
        </FormRow>
        <FormRow gap="1rem" style={{ alignItems: 'flex-end' }}>
          <Controller
            name="consultantExternal.birthdate"
            control={control}
            render={field => (
              <Input
                label="Data de nascimento"
                required
                disabled={disableField || specialistIsNotExternal}
                width="270px"
                style={{ maxWidth: '270px' }}
                {...field}
              />
            )}
          />
          <FormRow gap="0.5rem" style={{ alignItems: 'flex-end' }}>
            <Controller
              name="consultantExternal.docType"
              control={control}
              render={field => (
                <SearchSelect
                  label="Documento de identificação"
                  required
                  placeholder="Selecione um tipo de documento"
                  isDisabled={disableField || specialistIsNotExternal}
                  options={docsType.map(doc => ({
                    name: doc.name,
                  }))}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.name}
                  {...field}
                />
              )}
            />
            <Controller
              name="consultantExternal.docValue"
              control={control}
              render={field => {
                return (
                  <Input
                    label=""
                    disabled={disableField || specialistIsNotExternal}
                    {...field}
                  />
                );
              }}
            />
          </FormRow>
        </FormRow>
        <FormRow gap="1rem" style={{ alignItems: 'flex-end' }}>
          <Controller
            name="consultantExternal.mothername"
            control={control}
            render={field => (
              <Input
                status="default"
                label="Nome da mãe"
                disabled={disableField || specialistIsNotExternal}
                className="tw-w-full"
                {...field}
              />
            )}
          />
          <Controller
            name="consultantExternal.phone"
            control={control}
            render={field => (
              <Input
                status="default"
                label="Contato"
                disabled={disableField || specialistIsNotExternal}
                {...field}
              />
            )}
          />
        </FormRow>
        <Controller
          name="consultantInfo"
          control={control}
          render={field => (
            <Textarea
              label="Informações do paciente"
              disabled={disableField}
              {...field}
            />
          )}
        />
      </Form>
    </SectionContainer>
  );
};
