import { ColumnDef } from '@tanstack/react-table';
import { SortButton, Typography } from '@wisecare-tech/design-system-web';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ListDetailedTeleShifts } from '~/domain/usecases/teleShifts/remote';

export const columnsTeleshifts: ColumnDef<
  ListDetailedTeleShifts.Model['records'][0]
>[] = [
  {
    accessorKey: 'date',
    header: ({ column }) => <SortButton column={column}>Dia e hora</SortButton>,
    cell: ({ row }) => {
      const date = parseISO(row.original.date);

      const formattedDate = format(date, 'EEEE, dd/MM/yyyy', {
        locale: ptBR,
      }).replace(/-feira/g, '');

      const timeRange = `${row.original.hourStart} às ${row.original.hourEnd}`;

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="b4_14regular"
            style={{ textTransform: 'capitalize' }}
          >
            {formattedDate}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {timeRange}
          </Typography>
        </div>
      );
    },
  },
  {
    accessorKey: 'module',
    header: ({ column }) => {
      return <SortButton column={column}>Módulo</SortButton>;
    },
    cell: ({ row }) => {
      return (
        <Typography variant="b4_14regular">
          {row.original.restrictions?.[0]?.module?.description ?? '-'}
        </Typography>
      );
    },
  },
  {
    accessorKey: 'orgUnit',
    header: ({ column }) => <SortButton column={column}>Setor</SortButton>,
    cell: ({ row }) => {
      return (
        <Typography variant="b4_14regular">
          {row.original.restrictions?.[0]?.orgUnit?.fullname ?? '-'}
        </Typography>
      );
    },
  },
  {
    accessorKey: 'specialty',
    header: ({ column }) => (
      <SortButton column={column}>Especialidade</SortButton>
    ),
    cell: ({ row }) => {
      return (
        <Typography variant="b4_14regular">
          {row.original.restrictions?.[0]?.specialty?.name ?? '-'}
        </Typography>
      );
    },
  },
  {
    accessorKey: 'macroregion',
    header: ({ column }) => (
      <SortButton column={column}>Macrorregião</SortButton>
    ),
    cell: ({ row }) => {
      return (
        <Typography variant="b4_14regular">
          {row.original.restrictions?.[0]?.macroregion?.description ?? '-'}
        </Typography>
      );
    },
  },
];
