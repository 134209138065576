import axios from 'axios';
import { makeApiUrl } from '~/main/factories/http';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

export const downloadFiles = (
  route: string,
  token: string | undefined,
  id: number,
  setLoading: Function,
): void => {
  setLoading(true);

  axios
    .get(makeApiUrl(`/${route}/${id}/download`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
    .then(response => {
      const file = new Blob([response.data], {
        type: response?.headers['content-type'],
      });

      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `${route}-${id}.pdf`;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      URL.revokeObjectURL(fileURL);
      AlertMessage({
        message: 'Arquivo baixado com sucesso!',
        type: 'success',
      });
      setLoading(false);
    })
    .catch(() => {
      AlertMessage({
        message: 'Erro ao baixar arquivo!',
        type: 'danger',
      });
    })
    .finally(() => {
      setLoading(false);
    });
};

export const downloadFilesWithoutId = (
  route: string,
  token: string | undefined,
  setLoading: Function,
): void => {
  setLoading(true);

  axios
    .get(makeApiUrl(`/${route}/download`), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
    .then(response => {
      const file = new Blob([response.data], {
        type: response?.headers['content-type'],
      });

      const fileURL = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `${route}.pdf`;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      URL.revokeObjectURL(fileURL);
      AlertMessage({
        message: 'Arquivo baixado com sucesso!',
        type: 'success',
      });
      setLoading(false);
    })
    .catch(() => {
      AlertMessage({
        message: 'Erro ao baixar arquivo!',
        type: 'danger',
      });
    })
    .finally(() => {
      setLoading(false);
    });
};
