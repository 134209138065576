import React, { useEffect, useState } from 'react';
import {
  SelectLabel,
  Container,
  Form,
  Footer,
} from './styles/StyledScaleFilter';
import { Select } from '../UI';
import { Button } from '@wisecare-tech/design-system-web';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';
import { ListMacroRegion } from '~/domain/usecases/macroRegion/remote';
import { ListSystemModule } from '~/domain/usecases/systemModule/remote';
import { listDaysOfTheWeek } from '~/utils/listDatesOfTheWeek';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { makeReduxGetAllProfession } from '~/main/factories/usecases/profession';
import { makeRemoteListMacroRegion } from '~/main/factories/usecases/macroRegion/ListMacroRegionFactory';
import { AlertMessage } from '../messages/AlertMessage';
import { makeRemoteListSystemModule } from '~/main/factories/usecases/systemModule/ListSystemModuleFactory';
import { makeReduxGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { makeReduxListShifts } from '~/main/factories/usecases/teleShifts';
import { DaysInitials } from '~/domain/interfaces/models/TeleShifts';
import { useTeleShifts } from '~/presentation/hooks/teleShifts';
import { format } from 'date-fns';
import { closeModal } from '~/utils/closeModal';

type FormData = {
  weekDay?: string;
  day?: string;
  unit?: string;
  macroregion?: string;
  profession?: string;
  specialty?: string;
  professional?: string;
};

type Data = {
  start: string;
  end: string;
  user?: number;
  orgUnit?: number;
  specialty?: number;
  profession?: number;
  macroregion?: number;
  day?: DaysInitials;
};

export const ScaleFilter: React.FC = () => {
  const { resetShiftsFilter } = useTeleShifts();
  const { results } = useSelector((store: iStore) => store.orgUnit);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const { info } = useSelector((store: iStore) => store.auth);
  const { results: resultsProfession } = useSelector(
    (store: iStore) => store.professions,
  );
  const { results: resultsSpecialty } = useSelector(
    (store: iStore) => store.specialty,
  );
  const { results: resultsProfessionals } = useSelector(
    (store: iStore) => store.professional,
  );

  const [macroRegion, setMacroRegion] =
    useState<ListMacroRegion.Model['records']>();

  const [systemModule, setSystemModule] =
    useState<ListSystemModule.Model['records']>();

  const [message, setMessage] = useState<string>('Selecione o dia');

  const daysOfWeek = listDaysOfTheWeek();

  const {
    shiftList,
    setIsFilterActive,
    filterTeleShifts,
    setFilterTeleShifts,
    filterTeleShiftsCallback,
    isFilterActive,
  } = useTeleShifts();

  const [filterData, setFilterData] = useState<FormData>({
    ...filterTeleShifts,
  });

  const handleApplyFilters = () => {
    if (filterData) {
      filterTeleShiftsCallback(filterData);
    }
    setIsFilterActive(true);
    document.getElementById('close_filter')?.click();
  };

  useEffect(() => {
    makeReduxGetAllOrgUnitList().list({
      filter: {
        enabled: true,
      },
    });

    makeReduxGetAllProfession().getAll({
      filter: {
        enabled: true,
        hasProfessional: true,
      },
    });

    makeRemoteListMacroRegion()
      .list({})
      .then(response => {
        setMacroRegion(response.records);
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao carregar as macrorregiões',
          type: 'danger',
        });
      });

    makeRemoteListSystemModule()
      .list({})
      .then(response => {
        setSystemModule(response?.records);
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao carregar os módulos',
          type: 'danger',
        });
      });
  }, []);

  useEffect(() => {
    if (!filterTeleShifts?.profession) return;
    if (!filterTeleShifts?.specialty) return;

    makeReduxGetAllSpecialty().getAll({
      filter: {
        hasProfessional: true,
        enabled: true,
        profession: Number(filterTeleShifts?.profession),
      },
    });

    makeReduxGetAllProfessionals().getAll({
      filter: {
        enabled: true,
        specialty: Number(filterTeleShifts?.specialty),
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });

    setFilterData({
      ...filterTeleShifts,
      specialty: undefined,
      professional: undefined,
    });
  }, [filterTeleShifts?.profession]);
  
  return (
    <Container>
      <Form>
        <div>
          <SelectLabel required>Dia da semana</SelectLabel>

          <Select
            height="40px"
            error={!filterData?.day}
            defaultValue={filterData?.day}
            value={filterData?.weekDay ?? ''}
            message={!filterData?.day ? message : ''}
            onChange={e => {
              const findDate = daysOfWeek?.find(
                item => item?.dayOfWeek === e.target.value,
              )?.date;

              if (!findDate) {
                setMessage('Selecione o dia');
              }

              setFilterData({
                ...filterData,
                day: findDate ?? '',
                weekDay: e.target.value as DaysInitials,
              });
            }}
          >
            <option value="">Selecione</option>
            {daysOfWeek?.map(item => (
              <option key={item?.dayOfWeek} value={item?.dayOfWeek}>
                {item?.name}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <SelectLabel>Setor</SelectLabel>

          <Select
            height="40px"
            defaultValue={filterData?.unit}
            value={filterData?.unit ?? ''}
            onChange={e => {
              setFilterData({
                ...filterData,
                unit: e.target.value,
              });
            }}
          >
            <option value="">Selecione</option>
            {results?.map(item => (
              <option key={item.orgUnit?.id} value={String(item?.orgUnit?.id)}>
                {item.orgUnit?.name}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <SelectLabel>Macrorregião</SelectLabel>
          <Select
            height="40px"
            defaultValue={filterData?.macroregion}
            value={filterData?.macroregion ?? ''}
            onChange={e => {
              setFilterData({
                ...filterData,
                macroregion: e.target.value,
              });
            }}
          >
            <option value="">Selecione</option>
            {macroRegion?.map(item => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <SelectLabel>Profissão</SelectLabel>

          <Select
            height="40px"
            defaultValue={filterData?.profession ?? ''}
            value={filterData?.profession ?? ''}
            onChange={e => {
              setFilterData({
                ...filterData,
                profession: e.target.value,
              });
            }}
          >
            <option value="">Selecione</option>
            {resultsProfession?.map(item => (
              <option key={item?.profession?.id} value={item?.profession?.id}>
                {item?.profession?.name}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <SelectLabel>Especialidade</SelectLabel>

          <Select
            height="40px"
            defaultValue={filterData?.specialty}
            onChange={e => {
              setFilterData({
                ...filterData,
                specialty: e.target.value,
              });
            }}
          >
            <option value="">Selecione</option>
            {filterData?.profession && (
              <>
                {resultsSpecialty?.map(item => (
                  <option key={item?.specialty?.id} value={item?.specialty?.id}>
                    {item?.specialty?.name}
                  </option>
                ))}
              </>
            )}
          </Select>
        </div>
        <div>
          <SelectLabel>Profissional</SelectLabel>

          <Select
            height="40px"
            defaultValue={filterData?.professional}
            onChange={e => {
              setFilterData({
                ...filterData,
                professional: e.target.value,
              });
            }}
          >
            <option value="">Selecione</option>
            {filterData?.specialty && (
              <>
                {resultsProfessionals?.map(item => {
                  if (
                    item?.specialties?.find(
                      specialty =>
                        specialty?.id === Number(filterData?.specialty),
                    )
                  ) {
                    return (
                      <option
                        key={item?.professional?.id}
                        value={item?.user?.id}
                      >
                        {`${item?.user?.firstName} ${item?.user?.lastName}`}
                      </option>
                    );
                  }
                })}
              </>
            )}
          </Select>
        </div>
      </Form>
      <Footer>
        <Button
          variant="secondary"
          onClick={() => {
            resetShiftsFilter();
          }}
        >
          Restaurar padrões
        </Button>
        <Button
          variant="primary"
          onClick={handleApplyFilters}
          disabled={!filterData?.day}
        >
          Aplicar filtros
        </Button>
      </Footer>
    </Container>
  );
};
