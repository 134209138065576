import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 530px;
  border-radius: 16px;
  border: 1px solid #dedede;
  background-color: #fff;
`;

export const Header = styled.header`
  padding: 16px 24px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #dedede;
`;

interface iBackgroundIcon {
  variant: 'UNAVAILABILITY' | 'AVAILABILITY';
}

export const BackgroundIcon = styled.div<iBackgroundIcon>`
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  background-color: ${({ variant }) =>
    variant === 'UNAVAILABILITY' ? '#FFEFEA' : '##e6f0fb'};
  border-radius: 100%;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Main = styled.main`
  padding: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #dedede;
  padding: 16px;
`;

export const ConsultInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Footer = styled.footer`
  border-top: 1px solid #dedede;
  padding: 16px 24px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
`;
