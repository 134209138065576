import { Icon, Typography } from '@wisecare-tech/design-system-web';
import React from 'react';
import {
  GroupBase,
  Options,
  OptionsOrGroups,
  StylesConfig,
} from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { Control, DropdownIndicator, Option } from './fragments';
import {
  Container,
  IconContainer,
  Label,
  Message,
  SelectStyles,
} from './styles';

export type OptionType = {
  value: number | string | undefined;
  label: string;
};

interface SearchSelectProps extends CreatableProps<any, false, GroupBase<any>> {
  name?: string;
  label?: string;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  labelStyle?: React.CSSProperties;
  fontSize?: string;
  className?: string;
  register?: () => any;
  controlShouldRenderValue?: boolean;
  placeholder?: string;
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  // options: OptionType[];
  value?: any | undefined;
  onChange: (value: any) => void;
  styles?: StylesConfig<any, false, GroupBase<any>>;
  isValidNewOption?: (
    inputValue: string,
    selectValue: Options<any>,
    selectOptions: OptionsOrGroups<any, GroupBase<any>>,
  ) => boolean;
  noOptionsMessage?: () => string;
  isDisabled?: boolean;
}

const SearchSelect: React.FC<SearchSelectProps> = ({
  className = 'select',
  controlShouldRenderValue = true,
  placeholder = 'Selecione uma opção',
  formatCreateLabel = label => `Create "${label}"`,
  options,
  onChange,
  styles = undefined,
  isValidNewOption = () => false,
  noOptionsMessage = () => 'Nenhum resultado encontrado',
  isDisabled = false,
  name,
  label,
  message,
  register,
  error = false,
  width = '100%',
  height = '40px',
  fontSize = '',
  labelStyle = {},
  required,
  defaultValue,
  ...props
}) => {
  if (register) register();

  return (
    <Container width={width}>
      {label && (
        <Label style={labelStyle} required={required}>
          {label}
        </Label>
      )}
      <CreatableSelect
        className={className}
        key={props.value?.value}
        name={name}
        components={{
          Control,
          DropdownIndicator,
          IndicatorSeparator: () => null,
          Option,
        }}
        controlShouldRenderValue={controlShouldRenderValue}
        placeholder={placeholder}
        formatCreateLabel={formatCreateLabel}
        options={options}
        onChange={onChange}
        styles={styles ?? SelectStyles()}
        isValidNewOption={isValidNewOption}
        noOptionsMessage={noOptionsMessage}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        isDisabled={isDisabled}
        classNamePrefix="react-select"
        {...props}
      />
      <IconContainer display={!!message}>
        <Icon name="error" color="red-7" size="medium" />
        <Typography variant="b2_14semibold" className="tw-text-red-7">
          {message}
        </Typography>
      </IconContainer>
    </Container>
  );
};

export default SearchSelect;
