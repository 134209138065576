import React from 'react';
import { PageHeader } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import { TeleShiftsProvider } from '~/presentation/hooks/teleShifts';
import TeleShifts from '~/presentation/pages/TeleShifts';
import { Container } from './styles';

export const MakeTeleShiftsFactory: React.FC = () => {
  // const { onCallId } = useLocation<{ onCallId: number }>().state;

  return (
    <TeleShiftsProvider>
      <Container>
        <Menu />
        <PageHeader title="Escala" />
        <TeleShifts />
      </Container>
    </TeleShiftsProvider>
  );
};
