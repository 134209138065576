import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.background};
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  margin-top: 130px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 40px;
  margin-right: 10px;
`;

export const Left = styled.div`
  margin-right: 20px;

  p {
    color: #656a6e;
    margin-left: 3px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 7px;
  }
`;

export const Right = styled.div`
  /* grid-column-start: 4;
  grid-column-end: 6; */
`;

export const Box = styled.div`
  background: transparent;
  width: 80%;
  max-width: 300px;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid #c0c0c0;
  border-style: dotted;
  border-radius: 4px;
  object-fit: contain;

  gap: 10px;
  font-size: 15px;
  text-align: center;
  text-overflow: ellipsis;
  color: #c9c9c9;
`;

export const Content = styled.div`
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const Footer = styled.div`
  width: 47%;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 11;
`;
