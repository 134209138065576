import { Avatar, Chips, Icon } from '@wisecare-tech/design-system-web';
import React, { useEffect, useState } from 'react';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { PostAvailabilityDetailed } from '~/domain/usecases/availability/remote';
import { ListShiftsAvailable } from '~/domain/usecases/shifts/remote';
import { makeRemoteAvailabilityDetailed } from '~/main/factories/usecases/availability/PostAvailabilityDetailedFactory';
import { makeRemoteListShiftsAvailable } from '~/main/factories/usecases/shifts';
import { formatDateRange, formatTimeRange } from '~/utils/generateTimeSlots';
import AvailabilityStepper from '../../AvailabilityStepper';
import { ownProps } from '../interface';
import {
  BoxLegend,
  CheckBoxView,
  ChipView,
  Container,
  Content,
  DropdownView,
  Footer,
  ProfesionalName,
  ProfesionalRole,
  ProfileInfoView,
  ScheduleItem,
  ScheduleView,
  ScheduleWarning,
  ScheduleWarningText,
  SubTitleText,
  TitleText,
} from './styles/StyledAvailability';

const ModalAvailability: React.FC<ownProps> = ({ message }) => {
  const [availableSchedule, setAvailableSchedule] =
    useState<PostAvailabilityDetailed.Model>([] as any);
  const [dateRange, setDateRange] = useState<
    ListShiftsAvailable.Model['records']
  >([]);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
    {},
  );
  const [currentDate, setCurrentDate] = useState({
    begin: '',
    end: '',
  });

  const msgName = MessageOptions.availability;

  const { active, actionCancel, data } = message;

  console.log('##Data: ', data);

  const toggleDropdown = (id: string) => {
    setOpenDropdowns(prev => ({
      ...prev,
      [id]: !prev[id], // Toggle only the clicked dropdown
    }));
  };

  useEffect(() => {
    if (active !== msgName) return;

    setAvailableSchedule([] as any);

    try {
      const data_ids = data?.map((item: any) => item.userId);

      makeRemoteListShiftsAvailable()
        .post({
          filters: {
            users: data_ids,
          },
        })
        .then(response => {
          const data = response.records.length > 0 ? response.records : [];

          setDateRange(data);
        })
        .catch(error => {
          console.log(error);
        });
    } catch {
      console.log('Error on useEffect from ModalAvailability');
    }
  }, [data, active]);

  useEffect(() => {
    if (dateRange.length === 0) return;

    try {
      const data_ids = data?.map((item: any) => item.id);
      const timestamp = formatDateRange(dateRange[0].date);

      const time_formatted = {
        begin: timestamp.begin,
        end: timestamp.end.replace('.999Z', '.000Z'),
      };

      makeRemoteAvailabilityDetailed()
        .post({
          timestamp: currentDate.begin !== '' ? currentDate : time_formatted,
          professionals: data_ids,
        })
        .then(response => {
          setAvailableSchedule(response);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (err) {
      console.log('Error on useEffect from ModalAvailability');
    }
  }, [dateRange, currentDate]);

  return (
    <>
      {msgName === active ? (
        <Container>
          <Content>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div>
                    <TitleText>Escala dos profissionais</TitleText>
                    <SubTitleText>
                      Os horários disponíveis na agenda dos profissionais são:
                    </SubTitleText>
                  </div>
                  <div onClick={actionCancel} style={{ cursor: 'pointer' }}>
                    <Icon name="close" />
                  </div>
                </div>
                <div
                  style={{
                    height: 1,
                    borderBottom: '1px solid #dedede',
                    marginBottom: 16,
                  }}
                />
                {dateRange.length > 0 && (
                  <AvailabilityStepper
                    shiftDate={dateRange}
                    getDate={setCurrentDate}
                  />
                )}
              </>
              {/* {availableSchedule?.professionals?.length === data?.length && (
                <ScheduleWarning color="#fff4f4" border="#dd0404">
                  <Icon name="error" size="small" color="red-6" />
                  <ScheduleWarningText color="#dd0404">
                    Não há um horário compatível para estes profissionais.
                  </ScheduleWarningText>
                </ScheduleWarning>
              )} */}
              {dateRange?.length === 0 &&
                data?.map((item: any) => (
                  <ScheduleWarning
                    key={item.id}
                    color="#FFEFEA"
                    border="#9A3C1C"
                  >
                    <Icon name="error" size="small" color="orange-7" />
                    <ScheduleWarningText color="#9A3C1C">
                      {item.name} não possui escala cadastrada no sistema.
                    </ScheduleWarningText>
                  </ScheduleWarning>
                ))}

              {availableSchedule?.professionals?.length > 0 &&
                availableSchedule?.professionals?.map((item: any) => {
                  if (!item.hasShift) {
                    return (
                      <ScheduleWarning
                        key={item.id}
                        color="#FFEFEA"
                        border="#9A3C1C"
                      >
                        <Icon name="error" size="small" color="orange-7" />
                        <ScheduleWarningText color="#9A3C1C">
                          {item.fullname} não possui escala cadastrada no
                          sistema.
                        </ScheduleWarningText>
                      </ScheduleWarning>
                    );
                  }
                })}
              <ScheduleView>
                {availableSchedule?.professionals?.map(professional => {
                  if (professional.hasShift) {
                    return (
                      <>
                        <ScheduleItem
                          key={professional.id}
                          open={openDropdowns[professional.id]}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              <Avatar size="md" name={professional.avatar} />
                              <ProfileInfoView>
                                <ProfesionalName>
                                  {professional.fullname}
                                </ProfesionalName>
                                <ProfesionalRole>
                                  {professional.profession}
                                  <div style={{ padding: '0 4px' }}>|</div>
                                  {professional.specialty}
                                </ProfesionalRole>
                              </ProfileInfoView>
                            </div>
                            <div>
                              <DropdownView
                                onClick={() =>
                                  toggleDropdown(String(professional.id))
                                }
                              >
                                <Icon name="keyboard_arrow_down" />
                              </DropdownView>
                            </div>
                          </div>
                          <div>
                            {openDropdowns[professional.id] && (
                              <ChipView>
                                {professional.availability.map(
                                  (item, index) => {
                                    const dates = formatTimeRange(
                                      item.begin,
                                      item.end,
                                    );

                                    return (
                                      <>
                                        {index === 0 && (
                                          <Chips
                                            key={index}
                                            text={dates.begin}
                                            disabled
                                            variant={
                                              item.match
                                                ? 'green'
                                                : item.available
                                                  ? 'white'
                                                  : 'red'
                                            }
                                          />
                                        )}
                                        <Chips
                                          key={index}
                                          text={dates.end}
                                          disabled
                                          variant={
                                            item.available
                                              ? 'green'
                                              : item.match
                                                ? 'gray'
                                                : 'white'
                                          }
                                        />
                                      </>
                                    );
                                  },
                                )}
                              </ChipView>
                            )}
                          </div>
                        </ScheduleItem>
                      </>
                    );
                  }
                })}
              </ScheduleView>
              {dateRange?.length > 0 && (
                <Footer>
                  <CheckBoxView>
                    <BoxLegend status="availableAll" /> disponíveis para todos
                    os profissionais
                  </CheckBoxView>
                  <CheckBoxView>
                    <BoxLegend status="available" /> horário disponível
                  </CheckBoxView>
                  <CheckBoxView>
                    <BoxLegend status="unavailable" />
                    horário indisponível
                  </CheckBoxView>
                </Footer>
              )}
            </div>
          </Content>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default ModalAvailability;
