import { format } from 'date-fns';
import { iTeleShifts } from '~/domain/interfaces/models/TeleShifts';
import { ShiftsTypes } from '~/domain/interfaces/redux/teleShifts/types';
import { ShiftsActions } from './actions';

export const initialState: iTeleShifts = {
  loading: false,
  date: format(new Date(), 'yyyy-MM-dd'),
  shifts: [],
};

const reducer = (state = initialState, action: ShiftsActions): iTeleShifts => {
  switch (action.type) {
    case ShiftsTypes.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        shifts: [...action.payload.records],
      };

    case ShiftsTypes.SET_DATE:
      return { ...state, date: action.payload };

    case ShiftsTypes.GETALL:
      return { ...state, loading: true };

    case ShiftsTypes.GETALL_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
