import styled, { css } from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 548px;
  border-radius: 16px;
  border: 1px solid #dedede;
  background-color: #fff;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid #dedede;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

export const InlineSelects = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Footer = styled.footer`
  border-top: 1px solid #dedede;
  padding: 16px 24px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
`;

interface iSelectLabel {
  required?: boolean;
}

export const SelectLabel = styled.span<iSelectLabel>`
  color: #222529;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;

  ${({ required }) =>
    required &&
    css`
      ::after {
        content: '*';
        color: #dd0404;
      }
    `}
`;
