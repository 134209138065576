import React, { useEffect, useState } from 'react';
import NewProfessionalShift from '~/presentation/pages/NewProfessionalShift';

import { useParams } from 'react-router';
import { GetShiftsById } from '~/domain/usecases/shifts/remote';
import { makeRemoteGetShiftsById } from '../../usecases/shifts';

const MakeEditProfessionalShift: React.FC = () => {
  const [shift, setShift] = useState<GetShiftsById.Model | undefined>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    makeRemoteGetShiftsById()
      .getById({ shiftId: Number(id) })
      .then(data => {
        setShift(data);
      });
  }, [id]);

  return shift ? <NewProfessionalShift shift={shift} /> : <></>;
};

export default MakeEditProfessionalShift;
