import {
  IconButton,
  Textarea,
  Typography,
} from '@wisecare-tech/design-system-web';
import axios from 'axios';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { PendencyFile } from '~/domain/usecases/diagnosisReport/remote';
import { makeApiUrl } from '~/main/factories/http';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { DataContainer, FileCardContainer, FileCardHeader } from './styles';

export const FileCard = ({
  reportId,
  file,
}: {
  reportId: number;
  file: PendencyFile;
}) => {
  const { token } = useSelector((store: iStore) => store.auth.access);

  const handleDownload = useCallback(() => {
    axios
      .get(makeApiUrl(`/reports/${reportId}/files/${file.id}/DOWNLOAD`), {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        const file = new Blob([response.data], {
          type: response.data.type,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao visualizar arquivo',
          type: 'danger',
        });
      });
  }, [reportId, file]);

  return (
    <FileCardContainer>
      <FileCardHeader>
        <DataContainer spacingY="0">
          <Typography variant="b3_14medium">{file.filename ?? '-'}</Typography>
          <Typography variant="b4_14regular" className="tw-text-black-4">
            {file.examTypeName ?? '-'}
          </Typography>
        </DataContainer>
        <IconButton
          variant="primary"
          icon="visibility"
          size="sm"
          onClick={handleDownload}
        />
      </FileCardHeader>
      <Textarea disabled value={file.justification ?? '-'} />
    </FileCardContainer>
  );
};
