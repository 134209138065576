import { takeLatest } from 'redux-saga/effects';
import { ShiftsTypes } from '~/domain/interfaces/redux/teleShifts/types';

import { onListShifts, onListShiftsFailed, onListShiftsSuccess } from './List';


const tasks = [
  takeLatest(ShiftsTypes.GETALL, onListShifts),
  takeLatest(ShiftsTypes.GETALL_SUCCESS, onListShiftsSuccess),
  takeLatest(ShiftsTypes.GETALL_FAILED, onListShiftsFailed),
];

export default tasks;
