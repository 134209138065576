import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import {
  Input as InputDs,
  Button,
  Typography,
  RadioGroupItem,
  RadioGroup,
  Checkbox,
} from '@wisecare-tech/design-system-web';
import {
  Container,
  ButtonColapse,
  Content,
  WorkTime,
  InfoBlockContainer,
  InfoBlock,
  selectStyles,
  InfoBlockRow,
  InfoAction,
  Select,
  SelectContainer,
  SelectWrapper,
  Delete,
  InfoBlockHeader,
} from './styles';
import { IconArrow, IconArrowSelect } from '~/presentation/base/icons';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import SearchSelect from '~/presentation/components/UI/searchSelect';
import {
  Control,
  DropdownIndicator,
  Option,
} from '~/presentation/components/filter/styles/StyledFilterAppointmentHistory';
import { findIndex, set } from 'lodash';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { ListMacroRegion } from '~/domain/usecases/macroRegion/remote';
import { ListSystemModule } from '~/domain/usecases/systemModule/remote';
import { makeRemoteListSystemModule } from '~/main/factories/usecases/systemModule/ListSystemModuleFactory';
import { makeRemoteListMacroRegion } from '~/main/factories/usecases/macroRegion/ListMacroRegionFactory';
import DatePickerField from '~/presentation/components/UI/NewDateInput';
import { Toggle } from 'material-ui';
import { Switch } from '~/presentation/components/UI';

interface ownProps {
  id: 'SU' | 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA';
  title: string;
  children?: React.ReactNode;
}

const RenderRestrictions = ({
  control,
  register,
  mainIndex,
  name,
  modules,
  macroregions,
}: {
  control: any;
  register: any;
  mainIndex: number;
  name: `scheduleDays[${number}].restrictions`;
  modules: ListSystemModule.Model['records'];
  macroregions: ListMacroRegion.Model['records'];
}) => {
  const { results } = useSelector((store: iStore) => store.professional);
  const userId = 2;
  const professionalData = results.find(result => result.user.id === userId);

  const {
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext();

  const {
    fields: subFields,
    append: appendSub,
    remove: removeSub,
    insert: insertSub,
  } = useFieldArray({
    control,
    name,
  });

  const errorsSub = errors?.scheduleDays?.[mainIndex]?.restrictions;

  console.log('>>> subvalues', mainIndex, subFields);

  return (
    <InfoBlockContainer>
      <InfoBlock>
        {subFields.map((subField, subIndex) => (
          <InfoBlock key={subField.id} withBorder>
            <InfoBlockHeader>
              <strong className="title">Informações </strong>
              <Delete onClick={() => removeSub(subIndex)} />
            </InfoBlockHeader>
            <InfoBlockRow>
              <Controller
                name={`${name}[${subIndex}].module`}
                control={control}
                defaultValue={subField.module}
                render={({ onChange, onBlur, value, ref }) => (
                  <SelectWrapper>
                    <label>Módulo</label>
                    <SelectContainer>
                      <Select
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        value={value}
                      >
                        <option value="">Selecione...</option>
                        {modules.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.descr}
                          </option>
                        ))}
                      </Select>
                    </SelectContainer>
                    <span>
                      {errors?.scheduleDays?.message && !value
                        ? errors?.scheduleDays?.message
                        : ''}
                    </span>
                  </SelectWrapper>
                )}
              />

              <Controller
                name={`${name}[${subIndex}].orgUnit`}
                control={control}
                defaultValue={subField.orgUnit}
                render={({ onChange, onBlur, value, ref }) => (
                  <SelectWrapper>
                    <label>Setor</label>
                    <SelectContainer>
                      <Select
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        value={value}
                      >
                        <option value="">Selecione...</option>
                        {[
                          {
                            value: professionalData?.orgUnit.id,
                            label: professionalData?.orgUnit.name,
                          },
                        ].map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                    </SelectContainer>
                    <span>
                      {errors?.scheduleDays?.message && !value
                        ? errors?.scheduleDays?.message
                        : ''}
                    </span>
                  </SelectWrapper>
                )}
              />
            </InfoBlockRow>
            <InfoBlockRow>
              <Controller
                name={`${name}[${subIndex}].specialty`}
                control={control}
                defaultValue={subField.specialty}
                render={({ onChange, onBlur, value, ref }) => (
                  <SelectWrapper>
                    <label>Especialidade</label>
                    <SelectContainer>
                      <Select
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        value={value}
                      >
                        <option value="">Selecione...</option>
                        {professionalData?.specialties.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                    </SelectContainer>
                    <span>
                      {errors?.scheduleDays?.message && !value
                        ? errors?.scheduleDays?.message
                        : ''}
                    </span>
                  </SelectWrapper>
                )}
              />

              <Controller
                name={`${name}[${subIndex}].macroregion`}
                control={control}
                defaultValue={subField.macroregion}
                render={({ onChange, onBlur, value, ref }) => (
                  <SelectWrapper>
                    <label>Macrorregião</label>
                    <SelectContainer>
                      <Select
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={ref}
                        value={value}
                      >
                        <option value="">Selecione...</option>
                        {macroregions?.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                    </SelectContainer>
                    <span>
                      {errors?.scheduleDays?.message && !value
                        ? errors?.scheduleDays?.message
                        : ''}
                    </span>
                  </SelectWrapper>
                )}
              />
            </InfoBlockRow>
          </InfoBlock>
        ))}
        <InfoAction>
          <Button
            variant="text"
            type="button"
            onClick={() =>
              appendSub({
                module: '',
                orgUnit: '',
                specialty: '',
                macroregion: '',
              })
            }
          >
            <strong>+</strong> Novo Bloco
          </Button>
        </InfoAction>
      </InfoBlock>
    </InfoBlockContainer>
  );
};

export default RenderRestrictions;
