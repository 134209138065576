import { addMinutes, parse } from 'date-fns';

export const generateTimeSlots = (): string[] => {
  const timeSlots: string[] = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      timeSlots.push(`${formattedHour}:${formattedMinute}`);
    }
  }
  return timeSlots;
};

export function formatDateRange(date: string): { begin: string; end: string } {
  const parsedDate = new Date(date);

  const begin = new Date(parsedDate);
  begin.setUTCHours(0, 0, 0, 0);

  const end = new Date(parsedDate);
  end.setUTCHours(23, 59, 59, 999);

  return {
    begin: String(begin.toISOString()),
    end: String(end.toISOString()),
  };
}

export function generateTimeSlotsAvailability(
  startISO: string,
  endISO: string,
  split: number,
): string[] {
  const timeSlots: string[] = [];

  // Parse the ISO strings and retrieve hours
  const startHour = new Date(startISO).getHours();
  const endHour = new Date(endISO).getHours();

  for (let hour = startHour; hour <= endHour; hour++) {
    for (let minute = 0; minute < 60; minute += split) {
      const formattedTime = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`;
      timeSlots.push(formattedTime);
    }
  }

  return timeSlots;
}

export const formatTimeRange = (begin: string, end: string) => {
  const formatTime = (isoString: string) => {
    const date = new Date(isoString);
    return date.toISOString().split('T')[1].substring(0, 5); // Extracts HH:MM
  };

  return {
    begin: formatTime(begin),
    end: formatTime(end),
  };
};

export const generateTimeSlotsInterval = (
  startTime: string,
  endTime: string,
  interval: number,
): Date[] => {
  const slots = [];
  let current = parse(startTime, 'HH:mm', new Date());
  const end = parse(endTime, 'HH:mm', new Date());

  while (current <= end) {
    slots.push(current);
    current = addMinutes(current, interval);
  }

  return slots;
};
