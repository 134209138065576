import { ListDetailedTeleShifts } from '~/domain/usecases/teleShifts/remote';

import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

export class RemoteListDetailedShifts implements ListDetailedTeleShifts {
  private readonly url: string;

  private readonly httpClient: HttpClient<ListDetailedTeleShifts.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<ListDetailedTeleShifts.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  list = async (
    params: ListDetailedTeleShifts.Params,
  ): Promise<ListDetailedTeleShifts.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
