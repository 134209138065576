import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 466px;
  max-width: 466px;
  border-radius: 16px;
  background-color: white;
  border: 1px solid #dedede;
  height: fit-content;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  max-height: 560px;
  overflow-y: auto;
`;

interface iSelectLabel {
  required?: boolean;
}
export const SelectLabel = styled.span<iSelectLabel>`
  color: #222529;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;

  ${({ required }) =>
    required &&
    css`
      ::after {
        content: '*';
        color: #dd0404;
      }
    `}
`;

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  border-top: 1px solid #dedede;
  padding: 16px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;
