import * as z from 'zod';

export const updateShiftValidator = z.object({
  weekDay: z.string().nonempty('O dia da semana é obrigatório'),
  day: z.string().nonempty('O dia é obrigatório'),
  begin: z.string().nonempty('O período inicial é obrigatório'),
  end: z.string().nonempty('O período final é obrigatório'),
  unit: z.string().nonempty('A unidade é obrigatória'),
  macroregion: z.string().nonempty('A macrorregião é obrigatória'),
  profession: z.string().nonempty('A profissão é obrigatória'),
  specialty: z.string().nonempty('A especialidade é obrigatória'),
  professional: z.string().nonempty('O profissional é obrigatório'),
  systemModule: z.string().nonempty('O módulo do sistema é obrigatório'),
});

export const filterTeleShiftValidator = z.object({
  weekDay: z.string().optional(),
  day: z.string().nonempty('O dia é obrigatório'),
  begin: z.string().optional(),
  end: z.string().optional(),
  unit: z.string().optional(),
  macroregion: z.string().optional(),
  profession: z.string().optional(),
  specialty: z.string().optional(),
  professional: z.string().optional(),
  systemModule: z.string().optional(),
});
