import React, { useEffect } from 'react';
import { addDays, addWeeks, format, isBefore, parse, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { ProfessionalShiftsList } from './professionalShiftsList';
import {
  Container,
  ListContainer,
  ListHeader,
  ListWrapper,
  Texts,
} from '../styles/StyledWeeklyShiftsListing';
import { Typography } from '@wisecare-tech/design-system-web';
import { makeRemoteListDetailedShifts } from '~/main/factories/usecases/teleShifts/ListDetailedShiftsFactory';
import { ListDetailedTeleShifts } from '~/domain/usecases/teleShifts/remote';

interface WeeklyShiftsPageProps {
  professionalId: string;
  initialDateRange: {
    start: Date;
    end: Date;
  };
  weeks: number;
  records: ListDetailedTeleShifts.Model['records'];
  setRecords: Function;
}

type GroupedRecords = {
  records: ListDetailedTeleShifts.Model['records'];
  week: number;
};

const WeeklyShiftsListing: React.FC<WeeklyShiftsPageProps> = ({
  professionalId,
  initialDateRange,
  weeks,
  records,
  setRecords,
}) => {
  function formatDateToMonthDay(dateString: string): string {
    const date = parseISO(dateString);
    const month = format(date, 'MMMM', { locale: ptBR });
    const day = format(date, 'dd', { locale: ptBR });
    return `${day} de ${month}`;
  }

  function groupByWeek(
    records: ListDetailedTeleShifts.Model['records'],
  ): GroupedRecords[] {
    const grouped = records.reduce(
      (acc, record) => {
        if (!acc[record.week]) {
          acc[record.week] = [];
        }
        acc[record.week].push(record);
        return acc;
      },
      {} as { [key: number]: ListDetailedTeleShifts.Model['records'] },
    );

    return Object.entries(grouped).map(([week, records]) => ({
      week: parseInt(week),
      records,
    }));
  }

  const formattedWeeks = groupByWeek(records);

  return (
    <>
      {formattedWeeks?.map((item, index) => (
        <Container key={index}>
          <ListWrapper>
            <ListContainer>
              <ListHeader>
                <Texts>
                  <Typography variant="b2_14semibold">
                    {`Semana ${formattedWeeks[item?.week]?.week + 1} - ${formatDateToMonthDay(formattedWeeks[item?.week]?.records[0].date)} até ${formatDateToMonthDay(formattedWeeks[item?.week]?.records[formattedWeeks[item?.week]?.records.length - 1].date)}`}
                  </Typography>
                </Texts>
              </ListHeader>
              <ProfessionalShiftsList
                professionalId={professionalId}
                dateRange={{
                  start: new Date(formattedWeeks[item?.week]?.records[0].date),
                  end: new Date(item.records[item.records.length - 1].date),
                }}
                records={{
                  week: formattedWeeks[item?.week]?.week,
                  records: formattedWeeks[item?.week]?.records,
                }}
              />
            </ListContainer>
          </ListWrapper>
        </Container>
      ))}
    </>
  );
};

export default WeeklyShiftsListing;
