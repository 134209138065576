import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { RemoveUnavailabilityTeleshifts as UsecaseRemoteRemoveUnavailabilityTeleshifts } from '~/domain/usecases/teleShifts/remote';

import { RemoteRemoveUnavailabilityTeleshifts } from '~/data/repository/teleShifts';

/**
 * send request via API.
 */
export const makeRemoteRemoveUnavailabilityTeleshifts =
  (): UsecaseRemoteRemoveUnavailabilityTeleshifts =>
    new RemoteRemoveUnavailabilityTeleshifts(
      makeApiUrl('/shifts/'),
      makeHttpClient(),
    );
