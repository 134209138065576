import { all } from 'redux-saga/effects';

import AuditRecordTasks from './AuditRecord';
import NotificationTasks from './Notifications';
import AdministratorTasks from './administrator';
import AppointmentTasks from './appointment';
import AppointmentPrioritiesTasks from './appointmentPriorities';
import AppointmentTypeTasks from './appointmentType';
import AuthTasks from './auth';
import BillingTasks from './billing';
import Chat from './chat';
import ClinicalDocsTasks from './clinicalDocs';
import ConsultantTasks from './consultant';
import DashboardTasks from './dashboard';
import DutyTasks from './duty';
import OrgUnit from './orgUnit';
import OrganizerTasks from './organizer';
import Permissions from './permissions';
import ProfessionTasks from './profession';
import ProfessionalTasks from './professional';
import SelectedOrgTasks from './selectedOrg';
import ShiftsTasks from './shifts';
import TeleShiftsTasks from './teleShifts';
import SpecialtyTasks from './specialty';
import SupportDoc from './supportDoc';
import UsersTasks from './users';
import WaitingRoomTasks from './waitingRoom';
import HealthUnitsTasks from './healthUnits';
import OnCallTasks from './onCall';
import OnCallRequestTasks from './onCallRequest';
import OnCallResquesterTasks from './onCallRequester';
import OnCallSpecialistTasks from './onCallSpecialist';
import DiagnosisReport from './diagnosisReport';

export default function* rootSaga(): any {
  return yield all([
    ...AppointmentTasks,
    ...AppointmentTypeTasks,
    ...AppointmentPrioritiesTasks,
    ...AuthTasks,
    ...UsersTasks,
    ...SpecialtyTasks,
    ...ProfessionalTasks,
    ...ConsultantTasks,
    ...AdministratorTasks,
    ...SupportDoc,
    ...Permissions,
    ...ClinicalDocsTasks,
    ...ProfessionTasks,
    ...WaitingRoomTasks,
    ...Chat,
    ...NotificationTasks,
    ...SelectedOrgTasks,
    ...OrganizerTasks,
    ...DashboardTasks,
    ...OrgUnit,
    ...BillingTasks,
    ...AuditRecordTasks,
    ...DutyTasks,
    ...ShiftsTasks,
    ...TeleShiftsTasks,
    ...HealthUnitsTasks,
    ...OnCallTasks,
    ...OnCallRequestTasks,
    ...OnCallResquesterTasks,
    ...OnCallSpecialistTasks,
    ...DiagnosisReport,
  ]);
}
