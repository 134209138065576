import React from 'react';
import {
  Container,
  Header,
  Titles,
  Main,
  Content,
  Information,
  Footer,
} from './styles/StyledScaleProfessionalInfo';
import {
  Button,
  IconButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import { ShiftConverted } from '~/domain/interfaces/models/TeleShifts';
import { format, parseISO } from 'date-fns';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { makeRemoteSetUnavailabilityTeleshifts } from '~/main/factories/usecases/teleShifts/SetUnavailabilityTeleshiftsFactory';
import { AlertMessage } from '../messages/AlertMessage';
import { makeRemoteRemoveUnavailabilityTeleshifts } from '~/main/factories/usecases/teleShifts/RemoveUnavailabilityTeleshiftsFactory';
import { makeReduxListShifts } from '~/main/factories/usecases/shifts';
import { useTeleShifts } from '~/presentation/hooks/teleShifts';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

interface ScaleProfessionalInfoProps {
  shift: ShiftConverted;
  date: string;
}

export const ScaleProfessionalInfo: React.FC<ScaleProfessionalInfoProps> = ({
  shift,
  date,
}) => {
  const addTimezone = (time: string, timezone: number) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes + timezone);
    date.setSeconds(seconds);
    return format(date, 'HH:mm:ss');
  };

  const { shiftList } = useTeleShifts();

  const { selectUser } = useSelector((state: iStore) => state.auth);

  return (
    <Container>
      <Header>
        <Titles>
          <Typography variant="t1_18semibold">{`${shift.user.firstName} ${shift.user.lastName}`}</Typography>
          <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
            {format(parseISO(date), 'dd/MM/yyyy')}{' '}
            {addTimezone(shift.date.start, shift.date.timezone).slice(0, 5)} -{' '}
            {addTimezone(shift.date.end, shift.date.timezone).slice(0, 5)}
          </Typography>
        </Titles>
        <IconButton icon="close" variant="primary" />
      </Header>
      <Main>
        {shift.restrictions.map((restriction, index) => (
          <Content key={index}>
            <Information>
              <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
                Módulo:
              </Typography>
              <Typography variant="b4_14regular">
                {restriction.module.description}
              </Typography>
            </Information>
            <Information>
              <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
                Setor:
              </Typography>
              <Typography variant="b4_14regular">
                {restriction.orgUnit.name}
              </Typography>
            </Information>
            <Information>
              <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
                Especialidade:
              </Typography>
              <Typography variant="b4_14regular">
                {restriction.specialty.name}
              </Typography>
            </Information>
            <Information>
              <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
                Macrorregião:
              </Typography>
              <Typography variant="b4_14regular">
                {restriction.macroregion.name}
              </Typography>
            </Information>
          </Content>
        ))}
      </Main>
      {selectUser?.role === 'ADM' && (
        <Footer>
          {!shift?.unavailable ? (
            <Button
              variant="textDestructive"
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'availabilityProfessionalScale',
                  actionCancel: () => closeModal(),
                  data: {
                    variant: 'UNAVAILABILITY',
                    professionalName: `${shift.user.firstName} ${shift.user.lastName}`,
                    profession: `${shift?.user?.professionName} - ${shift?.user?.professionDocName} ${shift?.user?.registrationValue}`,
                    date: format(parseISO(date), 'dd/MM/yyyy'),
                    hourStart: `${addTimezone(shift?.date?.start, shift?.date?.timezone).slice(0, 5)} às ${addTimezone(shift?.date?.end, shift?.date?.timezone).slice(0, 5)}`,
                  },
                  actionOk: () => {
                    makeRemoteSetUnavailabilityTeleshifts()
                      .unavailability({
                        shift: shift?.id,
                        body: {
                          date: format(parseISO(date), 'yyyy-MM-dd'),
                        },
                      })
                      .then(() => {
                        AlertMessage({
                          message: 'Indisponibilidade informada com sucesso.',
                          type: 'success',
                        });

                        shiftList();

                        closeModal();
                      })
                      .catch(() => {
                        AlertMessage({
                          message:
                            'Não foi possível informar a indisponibilidade.',
                          type: 'danger',
                        });
                      });
                  },
                });
              }}
            >
              Informar indisponibilidade
            </Button>
          ) : (
            <Button
              variant="text"
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'availabilityProfessionalScale',
                  actionCancel: () => closeModal(),
                  data: {
                    professionalName: `${shift.user.firstName} ${shift.user.lastName}`,
                    profession: `${shift?.user?.professionName} - ${shift?.user?.professionDocName} ${shift?.user?.registrationValue}`,
                    date: format(parseISO(date), 'dd/MM/yyyy'),
                    hourStart: `${addTimezone(shift?.date?.start, shift?.date?.timezone).slice(0, 5)} às ${addTimezone(shift?.date?.end, shift?.date?.timezone).slice(0, 5)}`,
                  },
                  actionOk: () => {
                    makeRemoteRemoveUnavailabilityTeleshifts()
                      .delete({
                        shift: shift?.id,
                        body: {
                          date: format(parseISO(date), 'yyyy-MM-dd'),
                        },
                      })
                      .then(() => {
                        AlertMessage({
                          message: 'Disponibilidade informada com sucesso.',
                          type: 'success',
                        });
                        shiftList();

                        closeModal();
                      })
                      .catch(() => {
                        AlertMessage({
                          message:
                            'Não foi possível informar a disponibilidade.',
                          type: 'danger',
                        });
                      });
                  },
                });
              }}
            >
              Informar disponibilidade
            </Button>
          )}
        </Footer>
      )}
    </Container>
  );
};
