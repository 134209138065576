import { ListShiftsAvailable } from "~/domain/usecases/shifts/remote";

import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

export class RemoteListShiftsAvailable implements ListShiftsAvailable {
  private readonly url: string;

  private readonly httpClient: HttpClient<ListShiftsAvailable.Model>

  constructor(url: string, httpClient: HttpClient<ListShiftsAvailable.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  post = async (
    params: ListShiftsAvailable.Params,
  ): Promise<ListShiftsAvailable.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  }
}