export function applyNumericMask(mask: string, value: string): string {
  const digits = value.replace(/\D/g, '');
  let maskedValue = '';
  let digitIndex = 0;

  for (const char of mask) {
    if (char === '9') {
      if (digitIndex < digits.length) {
        maskedValue += digits[digitIndex];
        digitIndex++;
      } else {
        break;
      }
    } else {
      maskedValue += char;
    }
  }

  return maskedValue;
}
