export enum ShiftsTypes {
  CREATE = '@teleShifts/CREATE',
  CREATE_SUCCESS = '@teleShifts/CREATE_SUCCESS',
  CREATE_FAILED = '@teleShifts/CREATE_FAILED',
  GET_BY_ID = '@teleShifts/GET_BY_ID',
  GET_BY_ID_SUCCESS = '@teleShifts/GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILED = '@teleShifts/GET_BY_ID_FAILED',
  GETALL = '@teleShifts/GETALL',
  GETALL_SUCCESS = '@teleShifts/GETALL_SUCCESS',
  GETALL_FAILED = '@teleShifts/GETALL_FAILED',
  UPDATE = '@teleShifts/UPDATE',
  UPDATE_SUCCESS = '@teleShifts/UPDATE_SUCCESS',
  UPDATE_FAILED = '@teleShifts/UPDATE_FAILED',
  DELETE = '@teleShifts/DELETE',
  DELETE_SUCCESS = '@teleShifts/DELETE_SUCCESS',
  DELETE_FAILED = '@teleShifts/DELETE_FAILED',
  
  SET_DATE = '@teleShifts/SET_DATE',
}
