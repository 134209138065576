/* eslint-disable react/react-in-jsx-scope */
import { format, isSameDay, parseISO } from 'date-fns';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import {
  ShiftByDay,
  ShiftConverted,
  ShiftDetailed,
} from '~/domain/interfaces/models/TeleShifts';
import { DaysInitials } from '~/domain/usecases/teleShifts/remote';
import { makeReduxListShifts } from '~/main/factories/usecases/teleShifts';
import Translator from '~/presentation/components/i18n/Translator';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

import { getLocale } from '~/utils/getLocale';

type Labels = {
  label: string;
  subtitle: string;
};

type WeekInterval = {
  start: Date;
  end: Date;
};

type FormFilterData = {
  weekDay?: string;
  day?: string;
  unit?: string;
  macroregion?: string;
  profession?: string;
  specialty?: string;
  professional?: string;
};

type ShiftsContextData = {
  labels: Labels[];
  dayActive: number;
  weekLabel: string;
  start: Date;
  end: Date;
  isTeleshiftLoading: boolean;
  filterTeleShifts: FormFilterData;
  isFilterActive: boolean;
  isOnlyToday: boolean;
  setStartWeekInterval: (start: Date) => void;
  setEndWeekInterval: (end: Date) => void;
  resetShiftsFilter: () => void;
  setThisWeekAsCurrent: () => void;
  setFilterTeleShifts: React.Dispatch<React.SetStateAction<FormFilterData>>;
  filterTeleShiftsCallback: (filterTeleShifts: FormFilterData) => void;
  shiftList: () => void;
  setIsFilterActive: React.Dispatch<React.SetStateAction<boolean>>;
  convertShiftsToShiftByDay: (shifts: ShiftDetailed[]) => ShiftByDay[];
  dayByKey: (key: DaysInitials) => string;
  setDayActive: React.Dispatch<React.SetStateAction<number>>;
  goToToday: () => void;
  goToNextWeek: () => void;
  goToPreviousWeek: () => void;
};

const daysOfWeek: Record<number, string> = {
  0: 'Domingo',
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado',
};

const TeleShiftsContext = createContext<ShiftsContextData>(
  {} as ShiftsContextData,
);

export const TeleShiftsProvider: React.FC = ({ children }) => {
  const [labels, setLabels] = useState<Labels[]>([]);
  const [dayActive, setDayActive] = useState<number>(0);
  const [isTeleshiftLoading, setIsTeleshiftLoading] = useState<boolean>(false);
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

  const [filterTeleShifts, setFilterTeleShifts] = useState<FormFilterData>({
    day: '',
    macroregion: undefined,
    profession: undefined,
    professional: undefined,
    specialty: undefined,
    unit: undefined,
    weekDay: undefined,
  });

  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  const { selectUser, info } = useSelector((store: iStore) => store.auth);
  // const { results: org } = useSelector((store: iStore) => store.selectedOrg);

  const [weekInterval, setWeekInterval] = useState<WeekInterval>(() => {
    const start = new Date();
    const actualDay = start.getDay();
    const firstDayOfWeek = 1; // Segunda-feira

    const diffInDay = actualDay - firstDayOfWeek;
    start.setDate(start.getDate() - diffInDay);
    const end = new Date();
    end.setDate(start.getDate() + 6);

    return {
      start,
      end,
    };
  });

  const start = weekInterval.start;
  const end = weekInterval.end;

  const setStartWeekInterval = useCallback((start: Date) => {
    setWeekInterval(prevState => ({
      start,
      end: prevState.end,
    }));
  }, []);

  const setEndWeekInterval = useCallback((end: Date) => {
    setWeekInterval(prevState => ({
      start: prevState.start,
      end,
    }));
  }, []);

  const weekLabel = useMemo(() => {
    const localeConfig: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
    };
    const isTheSameMonth =
      weekInterval.start.getMonth() === weekInterval.end.getMonth();

    const start = isTheSameMonth
      ? weekInterval.start.toLocaleDateString(getLocale(), localeConfig)
      : weekInterval.start.toLocaleString(getLocale(), localeConfig);

    const end = weekInterval.end.toLocaleString(getLocale(), localeConfig);

    if (start === end) {
      // TODO: TELESHIFTS Check what is the best label to show when the week is a "day"
      // TODO: TELESHIFTS BUG: NAvigation between weeks is not working properly
      // const startLabel = new Date();
      // const actualDay = startLabel.getDay();
      // const firstDayOfWeek = 1; // Segunda-feira
      // const diffInDay = actualDay - firstDayOfWeek;
      // startLabel.setDate(startLabel.getDate() - diffInDay);
      // const endLabel = new Date();
      // endLabel.setDate(startLabel.getDate() + 6);
      // return `${startLabel.toLocaleString(getLocale(), localeConfig)} ${Translator('até')} ${endLabel.toLocaleString(getLocale(), localeConfig)}`;

      return start;
    }

    return `${start} ${Translator('até')} ${end}`;
  }, [weekInterval]);

  const setThisWeekAsCurrent = useCallback(() => {
    const start = new Date();
    const actualDay = start.getDay();
    const firstDayOfWeek = 1; // Segunda-feira

    const diffInDay = actualDay - firstDayOfWeek;
    start.setDate(start.getDate() - diffInDay);
    const end = new Date();
    end.setDate(start.getDate() + 6);

    setWeekInterval({
      start,
      end,
    });
  }, []);

  const isOnlyToday = useMemo(() => {
    return (
      isSameDay(weekInterval.start, new Date()) &&
      isSameDay(weekInterval.end, new Date())
    );
  }, [weekInterval.start, weekInterval.end, isFilterActive]);

  const createLabels = useCallback(() => {
    const actualDate = new Date(weekInterval.start);
    const actualDay = actualDate.getDay();
    const firstDayOfWeek = 1; // Segunda-feira

    const diffInDay = actualDay - firstDayOfWeek;
    actualDate.setDate(actualDate.getDate() - diffInDay);

    setLabels(
      Array.from({ length: 7 }, (_, i) => {
        const date = new Date(actualDate);
        date.setDate(date.getDate() + i);
        return {
          label: daysOfWeek[date.getDay()],
          subtitle: date.toLocaleDateString(getLocale()),
        };
      }),
    );
  }, [weekInterval]);

  const goToPreviousWeek = () => {
    setWeekInterval(prevState => {
      const startOfPreviousWeek = new Date(prevState.start);
      if (startOfPreviousWeek.getDay() === 1) {
        startOfPreviousWeek.setDate(startOfPreviousWeek.getDate() - 7);
      } else {
        const diffInDay = 1 - startOfPreviousWeek.getDay();
        startOfPreviousWeek.setDate(
          startOfPreviousWeek.getDate() + diffInDay - 7,
        );
      }

      const endOfPreviousWeek = new Date(startOfPreviousWeek);
      endOfPreviousWeek.setDate(endOfPreviousWeek.getDate() + 6);

      return {
        start: startOfPreviousWeek,
        end: endOfPreviousWeek,
      };
    });
  };

  const goToNextWeek = () => {
    setWeekInterval(prevState => {
      const startOfNextWeek = new Date(prevState.end);
      if (startOfNextWeek.getDay() === 0) {
        startOfNextWeek.setDate(startOfNextWeek.getDate() + 1);
      } else {
        const diffInDay = 1 - startOfNextWeek.getDay();
        startOfNextWeek.setDate(startOfNextWeek.getDate() + diffInDay);
      }

      const endOfNextWeek = new Date(startOfNextWeek);
      endOfNextWeek.setDate(endOfNextWeek.getDate() + 6);

      return {
        start: startOfNextWeek,
        end: endOfNextWeek,
      };
    });
  };

  const goToToday = () => {
    setWeekInterval(prevState => {
      const startOfToday = new Date();
      startOfToday.setDate(startOfToday.getDate());
      return {
        start: startOfToday,
        end: startOfToday,
      };
    });
  };

  const dayByKey = (key: DaysInitials) => {
    switch (key) {
      case 'MO':
        return 'Segunda';
      case 'TU':
        return 'Terça';
      case 'WE':
        return 'Quarta';
      case 'TH':
        return 'Quinta';
      case 'FR':
        return 'Sexta';
      case 'SA':
        return 'Sábado';
      case 'SU':
        return 'Domingo';
      default:
        return '-';
    }
  };

  const getTeleShifts = useCallback(async () => {
    const getTeleshifts = makeReduxListShifts();
    try {
      setIsTeleshiftLoading(true);
      const handleDayStartEnd = () => {
        if (filterTeleShifts?.day === '' && !isFilterActive) {
          return {
            start: format(weekInterval.start, 'yyyy-MM-dd'),
            end: format(weekInterval.end, 'yyyy-MM-dd'),
          };
        }
        const day = (filterTeleShifts?.day ?? '').split('/');
        const formattedDay = `${day[2]}-${day[1]}-${day[0]}`;

        return {
          start: formattedDay,
          end: formattedDay,
        };
      };

      const verifyRole = (role: string) => {
        if (role === 'ADM' || role === 'ORG') {
          return undefined;
        }
        return info?.user?.id;
      };

      getTeleshifts.list({
        filters: {
          start: handleDayStartEnd().start,
          end: handleDayStartEnd().end,
          user: verifyRole(role),

          macroregion:
            filterTeleShifts.macroregion && isFilterActive
              ? Number(filterTeleShifts?.macroregion)
              : undefined,
          profession:
            filterTeleShifts.profession && isFilterActive
              ? Number(filterTeleShifts?.profession)
              : undefined,
          specialty:
            filterTeleShifts.specialty && isFilterActive
              ? Number(filterTeleShifts.specialty)
              : undefined,
          orgUnit:
            filterTeleShifts.unit && isFilterActive
              ? Number(filterTeleShifts.unit)
              : undefined,
        },
      });
    } catch {
      AlertMessage({
        message: 'Não foi possível carregar os dados das escalas.',
        type: 'danger',
      });
    } finally {
      setIsTeleshiftLoading(false);
    }
  }, [dayActive, weekInterval.start, isFilterActive, filterTeleShifts]);

  const filterTeleShiftsCallback = useCallback(
    (filterTeleShifts: FormFilterData) => {
      setFilterTeleShifts(filterTeleShifts);
      const day = (filterTeleShifts?.day ?? '').split('/');
      const formattedDay = `${day[2]}-${day[1]}-${day[0]}`;

      const finalDateParsed = parseISO(formattedDay);
      setWeekInterval({
        start: finalDateParsed,
        end: finalDateParsed,
      });
    },
    [isFilterActive],
  );

  const convertShiftsToShiftByDay = useCallback(
    (shifts: ShiftDetailed[]): ShiftByDay[] => {
      const shiftByDayMap: { [key in string]?: ShiftConverted[] } = {};

      shifts.forEach(shift => {
        const date = shift.date;
        if (!shiftByDayMap[date]) {
          shiftByDayMap[date] = [];
        }

        shiftByDayMap[date]!.push({
          id: shift.shiftId,
          date: {
            start: shift.hourStart,
            end: shift.hourEnd,
            timezone: shift.timezone,
            weekDay: shift.weekDay,
          },
          user: shift.user,
          unavailable: shift.unavailable,
          restrictions: shift.restrictions,
        });
      });
      const ret = Object.keys(shiftByDayMap).map(date => ({
        shiftDay: date as DaysInitials,
        weekDay: shiftByDayMap[date]![0].date.weekDay,
        shifts: shiftByDayMap[date]!,
      }));

      return ret;
    },
    [],
  );

  const resetShiftsFilter = useCallback(() => {
    setIsFilterActive(false);
    setThisWeekAsCurrent();
    setFilterTeleShifts({
      day: '',
      macroregion: undefined,
      profession: undefined,
      professional: undefined,
      specialty: undefined,
      unit: undefined,
      weekDay: undefined,
    });
    document.getElementById('close_filter')?.click();
  }, []);

  useEffect(() => {
    getTeleShifts();
  }, [getTeleShifts]);

  useEffect(() => {
    createLabels();
  }, [createLabels, weekInterval]);

  useEffect(() => {
    setDayActive(0);
  }, [weekInterval]);

  const contextValue = useMemo(
    () => ({
      labels,
      dayActive,
      end,
      start,
      weekLabel,
      isTeleshiftLoading,
      filterTeleShifts,
      isFilterActive,
      setStartWeekInterval,
      setEndWeekInterval,
      setThisWeekAsCurrent,
      setIsFilterActive,
      setFilterTeleShifts,
      resetShiftsFilter,
      filterTeleShiftsCallback,
      convertShiftsToShiftByDay,
      shiftList: getTeleShifts,
      goToToday,
      setDayActive,
      goToNextWeek,
      goToPreviousWeek,
      dayByKey,
      isOnlyToday,
    }),
    [
      labels,
      dayActive,
      end,
      start,
      weekLabel,
      isTeleshiftLoading,
      filterTeleShifts,
      isFilterActive,
    ],
  );

  return (
    <TeleShiftsContext.Provider value={contextValue}>
      {children}
    </TeleShiftsContext.Provider>
  );
};

export const useTeleShifts = (): ShiftsContextData => {
  const context = useContext(TeleShiftsContext);

  if (!context) {
    throw new Error('useTeleShifts must be used within an TeleShiftsProvider');
  }

  return context;
};
