import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListMacroRegion as UsecaseRemoteListMacroRegion } from '~/domain/usecases/macroRegion/remote';
// import { ListMacroRegion as UsecaseReduxListMacroRegion } from '~/domain/usecases/macroRegion/redux';

import { RemoteListMacroRegion } from '~/data/repository/macroRegion';
// import { ReduxListMacroRegion } from '~/data/store/reducer/macroRegion/usecases';

/**
 * send request via API.
 */
export const makeRemoteListMacroRegion = (): UsecaseRemoteListMacroRegion =>
  new RemoteListMacroRegion(makeApiUrl('/macroregions/list'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxListMacroRegion =
  (): UsecaseReduxListMacroRegion =>
    new ReduxListMacroRegion(); */
