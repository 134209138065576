import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { CreateShifts } from '~/domain/usecases/shifts/remote';

import {
  BadRequestError,
  Conflict,
  Forbidden,
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

export class RemoteCreateShifts implements CreateShifts {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateShifts.Model>;

  constructor(url: string, httpClient: HttpClient<CreateShifts.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  create = async (params: CreateShifts.Params): Promise<CreateShifts.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: params.body,
    });

    const errrosMsg = {
      SHIFT_PROFESSIONAL_ALREADY_IN:
        'Profissional já está em uma escala nesse período', // 409
      SHIFT_NOT_CREATED: 'Não foi possível criar a escala', // 422
      ON_CALL_NOT_FOUND: 'Plantão não encontrado', // 404
      PROFESSIONAL_NOT_FOUND: 'Profissional não encontrado', // 404
    };

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        AlertMessage({
          message: 'Escala criada com sucesso',
          type: 'success',
        });
        return httpResponse.body;
      case HttpStatusCode.conflict:
        AlertMessage({
          title: 'Não foi possível criar a escala',
          message: '',
          type: 'warning',
        });
        throw new Conflict();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        AlertMessage({
          title: 'Não foi possível criar a escala',
          message: 'Não foi possível criar a escala',
          type: 'danger',
        });
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
