import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: #fff;
  padding-top: 10px;
  margin-bottom: 16px;
  overflow: auto;
`;

export const ListWrapper = styled.div`
  width: 100%;
  flex: 1;
  border: 1px solid #dedede;
  border-radius: 8px;
  overflow-y: auto;
  max-height: calc(100vh - 180px); 
`;

export const ListContainer = styled.div`
  width: 100%;
`;
export const ListHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

export const ContainerEmpty = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const ContainerEmptyIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
