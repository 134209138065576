import styled, { css } from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div`
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 8px;
  background: #ffffff;

  .title {
    width: 100%;
    height: 64px;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

interface ButtonColapseProps {
  isColapsed: boolean;
}

export const ButtonColapse = styled.button<ButtonColapseProps>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b4b7b9;
  border-radius: 6px;

  svg {
    transform: ${props =>
      props.isColapsed ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  label {
    color: #222529;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;

    ::after {
      content: '*';
      color: #dd0404;
    }
  }
`;

export const SelectContainer = styled.div`
  border: 1px solid #bfc4ca;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 0 8px;
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  background: transparent;
  color: #444a51;
`;

interface iContentProps {
  colapsed: boolean;
}

export const Content = styled.div<iContentProps>`
  width: 100%;
  height: ${props => (props.colapsed ? '0' : '100%')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.colapsed ? '0' : '16px 24px;')};
  border-top: 1px solid #dedede;

  .title {
    height: fit-content;
    display: flex;
    align-items: start;
    flex-direction: column;
    font-weight: 700;
    padding: 0;

    .subTitle {
      font-weight: 400;
    }
  }

  .schedule {
    display: flex;
    align-items: baseline;
  }
`;

export const WorkTime = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`;

export const InfoBlockContainer = styled.div`
  width: 100%;
`;

interface InfoBlockProps {
  withBorder?: boolean;
}

export const InfoBlock = styled.div<InfoBlockProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-end;
  ${props =>
    props.withBorder &&
    css`
      border-radius: 4px;
      padding: 16px;
      border: 1px solid #dedede;
    `};

  .title {
    width: 100%;
  }
`;

export const InfoBlockRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const InfoAction = styled.div`
  display: flex;
  width: 137px;
`;

export const selectStyles = () => ({
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    border: '1px solid #bfc4ca',
    boxShadow: 'none',
    borderRadius: '4px',
    background: '#fff',
    paddingLeft: '8px',

    '&:hover': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:focus': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:disabled': {
      backgroundColor: '#fafafb',

      '&:hover': {
        borderColor: '#bfc4ca',
      },
    },
  }),
  dropdownIndicator: (props: any) => ({
    ...props,
    color: 'inherit',
  }),
  input: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  placeholder: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  menu: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuList: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  option: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
  menuPortal: (props: any) => ({
    ...props,
    zIndex: 50,
  }),
});
