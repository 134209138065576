import { ListShiftsAvailable as UsecaseRemoteListShiftsAvailable } from '~/domain/usecases/shifts/remote';

import { makeApiUrl, makeHttpClient } from '../../http';

import { RemoteListShiftsAvailable } from '~/data/repository/shifts';

export const makeRemoteListShiftsAvailable =
  (): UsecaseRemoteListShiftsAvailable =>
    new RemoteListShiftsAvailable(
      makeApiUrl('/shifts/list/days/available'),
      makeHttpClient(),
    );
