import styled, { css } from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .custom-table-style {
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      /* background-color: #CDE0F7; */
      /* background-color: inherit; */
      /* outline: 1px solid #62666c; */
    }
  }
`;

interface DoctorInfoProps {
  isTruncated: boolean;
}

export const DoctorInfo = styled.div<DoctorInfoProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  width: 300px;
  max-width: 300px;

  .doctor-info {
    display: flex;
    flex-direction: column;
    max-width: ${props => (props.isTruncated ? '200px' : '100%')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .doctor-info text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
/* background-color: ${defaultTheme.colors.gray[200]}; */

interface DynamicContainerProps {
  bgColor: string;
  textColor: string;
  start: number;
  end: number;
}

export const DynamicContainer = styled.div<DynamicContainerProps>`
  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};
  margin-left: ${props => props.start}%;
  margin-right: ${props => props.end}%;
  padding: 0.3rem 0.5rem;
  height: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  p,
  text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .tooltip-overflow-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
`;
