import { DeleteShift } from '~/domain/usecases/teleShifts/remote';

import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

export class RemoteDeleteShift implements DeleteShift {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteShift.Model>;

  constructor(url: string, httpClient: HttpClient<DeleteShift.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  delete = async (params: DeleteShift.Params): Promise<DeleteShift.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{shift}', params.shift.toString()),
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
