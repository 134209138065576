import { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Textarea, Typography } from '@wisecare-tech/design-system-web';
import { useFormContext } from 'react-hook-form';
import {
  Container,
  Header,
  Body,
  InlineForms,
  TextAreaForm,
  selectStyles,
  Required,
  SelectLabel,
} from './styles/StyledRequest';
import {
  Control,
  DropdownIndicator,
  Option,
} from '../filter/styles/StyledFilterAppointmentHistory';
import _ from 'lodash';
import { FormData } from './CreateInterconsultRequest';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { GetCitiesByUf } from '~/domain/usecases/externalServices/remote';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import SearchSelect from '../UI/searchSelect';
import { RegionalDoc } from '~/domain/usecases/regionaldoc/remote';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import { makeRemoteGetAllRegionalDocs } from '~/main/factories/usecases/regionaldoc/GetAllRegionalDocs';
import { format } from 'date-fns';
import InputMask from 'react-input-mask';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';

interface Option {
  readonly label: string;
  readonly value: string;
}

const Request = () => {
  const [cities, setCities] = useState<GetCitiesByUf.Model>([]);
  const [regionalDocs, setRegionalDocs] = useState<RegionalDoc[]>([]);
  const [userId, setUserId] = useState<number | undefined>(undefined);

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { results: consultants } = useSelector(
    (store: iStore) => store.consultant,
  );

  const { results: orgUnitsList } = useSelector(
    (store: iStore) => store.orgUnit,
  );
  const { results: professionals } = useSelector(
    (store: iStore) => store.professional,
  );
  const { orgId, orgUnits, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormData>();

  const generateCitySelectOptions = () => {
    return _.sortBy(
      cities?.flatMap(value => {
        return [
          {
            label: value.nome,
            value: value.id,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const cityMultiselectSelected = useMemo(() => {
    return generateCitySelectOptions().find(
      option => option?.value === Number(watch('county')),
    );
  }, [generateCitySelectOptions, watch('county')]);

  const generateHealthUnitSelectOptions = useCallback(() => {
    if (!watch('county')) return [];

    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            label: `${value.cnes} - ${value.name} [${value.city.name}]`,
            value: value.id,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  const healthUnitMultiselectSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(option => {
      return option?.value === Number(watch('healthUnit'));
    });
  }, [generateHealthUnitSelectOptions, watch('healthUnit')]);

  const generateStateSelectOptions = () => {
    return [
      {
        label: 'Paraíba',
        value: 'PB',
      },
    ];
  };

  const stateMultiselectSelected = useMemo(() => {
    return generateStateSelectOptions().find(
      option => option?.value === watch('state'),
    );
  }, [generateStateSelectOptions]);

  const generateOrgUnitsSelectOptions = () => {
    return [
      {
        value: -1,
        label: 'Todos',
      },
      ..._.sortBy(
        orgUnitsList?.flatMap(value => {
          return [
            {
              value: value.orgUnit.id,
              label: value.orgUnit.name,
            },
          ];
        }),
        item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
      ),
    ];
  };

  const orgUnitMultiSelectSelected = useMemo(() => {
    if (!watch('orgUnit')) return [];

    return generateOrgUnitsSelectOptions().find(
      item => item?.value === Number(watch('orgUnit')),
    );
  }, [generateOrgUnitsSelectOptions]);

  const generateProfessionalSelectOptions = useCallback(() => {
    return _.sortBy(
      professionals?.flatMap(value => {
        if (watch('orgUnit') === -1) {
          return [
            {
              value: value.professional.id.toString(),
              label: `${value.user.firstName} ${value.user.lastName}`,
            },
          ];
        }

        if (watch('orgUnit')) {
          if (value?.orgUnit.id === watch('orgUnit')) {
            return [
              {
                value: value.professional.id.toString(),
                label: `${value.user.firstName} ${value.user.lastName}`,
              },
            ];
          }
        }

        return [];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [professionals, watch('orgUnit')]);

  const professionalMultiselectSelected = useMemo(() => {
    return generateProfessionalSelectOptions().find(
      option => option.value === watch('professional'),
    );
  }, [generateProfessionalSelectOptions, watch]);

  const generateSexSelectOptions = () => {
    return [
      {
        value: 'MALE',
        label: 'Masculino',
      },
      {
        value: 'FEMALE',
        label: 'Feminino',
      },
    ];
  };

  const sexSelected = () => {
    if (!watch('sex')) return [];

    return generateSexSelectOptions().find(
      option => option.value === watch('sex'),
    );
  };

  const generateDocTypeSelectOptions = () => {
    return _.sortBy(
      regionalDocs?.flatMap(value => {
        return [
          {
            value: value?.id,
            label: value?.name,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const docTypeSelected = () => {
    return generateDocTypeSelectOptions().find(
      option => option.label === watch('regType'),
    );
  };

  useEffect(() => {
    makeRemoteGetCitiesByUf()
      .getCitiesByUf({ uf: 'PB' })
      .then(res => {
        setCities(res);
      });

    makeReduxGetAllUsers().getAll({
      pageSize: 9999,
    });

    makeRemoteGetAllRegionalDocs()
      .getAll({
        disablePagination: true,
        country: 'BRA',
      })
      .then(res => {
        setRegionalDocs(res.records);
      })
      .catch(err => {
        console.log('err: ', err);
      });

    makeReduxGetAllOrgUnitList().list({
      pageSize: 9999,
      filter: { org: orgId, enabled: true },
    });
  }, [orgId]);

  useEffect(() => {
    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
      city: watch('county'),
    });
  }, [watch('county')]);

  useEffect(() => {
    if (userId) {
      register('isDisabled');
      setValue('isDisabled', true);
      const user = consultants?.find(value => value?.consultant?.id === userId);

      if (user) {
        const { motherName, firstName, lastName, birthdate, phone, gender } =
          user?.user;

        const data = new Date(birthdate);

        const dateFormatted = format(data, 'dd/MM/yyyy');

        setValue('fullName', `${firstName} ${lastName}`);
        setValue('sex', gender);
        setValue('birthDate', dateFormatted);
        setValue('motherName', motherName);
        setValue('phone', phone);
        setValue('regType', user?.documents?.[0]?.acronym);
        setValue('regValue', user?.documents?.[0]?.value);
      }
    }
  }, [userId, consultants]);

  useEffect(() => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    const orgUnitSelected = Number(watch('orgUnit'));

    makeReduxGetAllProfessionals().getAll({
      filter: {
        org: orgId,
        unit:
          orgUnitSelected && orgUnitSelected !== -1
            ? [orgUnitSelected]
            : role === 'ORG' || role === 'PRO'
              ? orgUnitsFormatted
              : undefined,
      },
    });

    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit:
          role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  }, [watch('orgUnit')]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        gap: '16px',
        marginBottom: '98px',
      }}
    >
      <Container>
        <Header>
          <Typography variant="t3_16semibold">Dados do paciente</Typography>
        </Header>
        <Body>
          <div>
            <Input
              {...(register('fullName') as any)}
              height="40px"
              onChange={e => {
                setValue('fullName', e.target.value);
              }}
              label="Nome do paciente"
              disabled={watch('isDisabled')}
              required
              defaultValue={watch('fullName')}
              value={watch('fullName')}
              error={Boolean(errors?.fullName)}
              helperText={errors?.fullName?.message}
              status={errors?.fullName ? 'error' : 'default'}
            />
          </div>

          <InlineForms>
            <SearchSelect
              className="select"
              {...(register('sex') as any)}
              isDisabled={watch('isDisabled')}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              options={generateSexSelectOptions()}
              value={sexSelected()}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('sex', e.value);
              }}
              controlShouldRenderValue
              defaultValue={watch('sex')}
              error={errors?.sex}
              message={errors?.sex?.message}
              label="Sexo"
              required
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />

            <TextAreaForm>
              <SelectLabel required>Data de nascimento</SelectLabel>
              <InputMask
                mask="99/99/9999"
                {...(register('birthDate') as any)}
                onChange={e => {
                  setValue('birthDate', e.target.value);
                }}
                placeholder="DD/MM/AAAA"
                value={watch('birthDate')}
                disabled={watch('isDisabled')}
              >
                <Input
                  height="40px"
                  required
                  value={watch('birthDate')}
                  helperText={errors?.birthDate?.message}
                  status={errors?.birthDate ? 'error' : 'default'}
                />
              </InputMask>
            </TextAreaForm>

            <SearchSelect
              className="select"
              {...(register('regType') as any)}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              isDisabled={watch('isDisabled')}
              options={generateDocTypeSelectOptions()}
              value={docTypeSelected()}
              onChange={(e: { value: string; label: string }) => {
                if (!e) return;
                setValue('regType', String(e?.label));
                setValue('regValue', '');
              }}
              controlShouldRenderValue
              defaultValue={watch('regType')}
              error={errors?.regType}
              required
              message={errors?.regType?.message}
              label="Documento de identificação"
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />

            <TextAreaForm>
              <div style={{ marginBottom: '14px' }} />
              <Input
                {...(register('regValue') as any)}
                height="40px"
                onChange={e => {
                  setValue('regValue', e.target.value.replace(/\D/g, ''));
                }}
                disabled={watch('isDisabled')}
                required
                defaultValue={watch('regValue')}
                value={watch('regValue')}
                error={Boolean(errors?.regValue)}
                helperText={errors?.regValue?.message}
                status={errors?.regValue ? 'error' : 'default'}
              />
            </TextAreaForm>
          </InlineForms>
        </Body>
      </Container>
      <Container>
        <Header>
          <Typography variant="t3_16semibold">Dados do atendimento</Typography>
        </Header>
        <Body>
          <InlineForms>
            <SearchSelect
              {...(register('state') as any)}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              className="select"
              controlShouldRenderValue
              options={generateStateSelectOptions()}
              value={stateMultiselectSelected}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('state', e?.value);
                setValue('county', '');
                setValue('healthUnit', '');
              }}
              isDisabled
              placeholder="Selecione"
              formatCreateLabel={(label: string) => ({
                label: `Buscar por ${label}`,
              })}
              styles={selectStyles()}
              isValidNewOption={() => false}
              required
              label="Estado"
              error={errors?.state}
              message={errors?.state?.message}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              width="20%"
            />

            <SearchSelect
              className="select"
              {...(register('county') as any)}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              options={generateCitySelectOptions()}
              value={cityMultiselectSelected}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('county', String(e?.value));
                setValue('healthUnit', '');
              }}
              controlShouldRenderValue
              defaultValue={watch('county')}
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              label="Município"
              required
              error={errors?.county}
              message={errors?.county?.message}
              isValidNewOption={() => false}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              width="30%"
            />

            <SearchSelect
              {...(register('healthUnit') as any)}
              className="select"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              defaultValue={watch('healthUnit')}
              options={generateHealthUnitSelectOptions()}
              placeholder="Selecione"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              onChange={(e: { value: string }) => {
                console.log('##e', e);
                setValue('healthUnit', String(e?.value));
              }}
              value={healthUnitMultiselectSelected}
              isValidNewOption={() => false}
              label="Unidade de Saúde"
              error={errors?.healthUnit}
              message={errors?.healthUnit?.message}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              width="50%"
            />
          </InlineForms>

          <InlineForms>
            <SearchSelect
              {...(register('orgUnit') as any)}
              className="select"
              id="orgUnit"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              controlShouldRenderValue
              options={generateOrgUnitsSelectOptions()}
              value={orgUnitMultiSelectSelected}
              onChange={(e: { value: number }) => {
                if (!e) return;
                setValue('orgUnit', e?.value);
              }}
              placeholder="Selecione"
              label="Setor"
              width="25%"
              error={errors?.orgUnit}
              message={errors?.orgUnit?.message}
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />

            <SearchSelect
              className="select"
              id="profissional"
              {...(register('profissional') as any)}
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              controlShouldRenderValue
              options={generateProfessionalSelectOptions()}
              value={professionalMultiselectSelected}
              onChange={(e: { value: string }) => {
                if (!e) return;
                setValue('profissional', String(e?.value));
              }}
              width="75%"
              label="Nome do profissional"
              error={errors?.profissional}
              message={errors?.profissional?.message}
              placeholder="Selecione o nome do profissional"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles()}
              isValidNewOption={() => false}
              required
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />
          </InlineForms>
        </Body>
      </Container>
    </div>
  );
};

export default Request;
