import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { AssignDiagnosisReport as UsecaseRemoteAssignDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';

import { RemoteAssignDiagnosisReport } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */
export const makeRemoteAssignDiagnosisReport =
  (): UsecaseRemoteAssignDiagnosisReport =>
    new RemoteAssignDiagnosisReport(
      makeApiUrl('/reports/{report}/ASSIGN'),
      makeHttpClient(),
    );
