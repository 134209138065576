import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import * as dateFns from 'date-fns';
import {
  Container,
  Body,
  Footer,
  ShiftDataContainer,
  SchedulePlanContainer,
  ScheduleDaysContainer,
  Form,
  Required,
} from './styles';

import { Menu } from '~/presentation/components/menu';
import { HeaderSimples } from '~/presentation/components/header';
import InputMask from 'react-input-mask';
import { Input as InputDs, Typography } from '@wisecare-tech/design-system-web';
import { Button, Textarea } from '@wisecare-tech/design-system-web';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { schemaNewProfessionalShift } from '~/validation/validators/shifts';
import { zodResolver } from '@hookform/resolvers/zod';
import DayColapse from './DayColapse';
import ScheduleDay from './ScheduleDay';
import {
  makeRemoteCreateShifts,
  makeRemoteUpdateShifts,
} from '~/main/factories/usecases/shifts';
import { Infer } from 'zod/lib/src/types/base';
import DatePickerField from '~/presentation/components/UI/NewDateInput';
import { CreateShifts } from '~/domain/usecases/shifts/remote/CreateShifts';
import { useLocation } from 'react-router';
import { History } from '~/main/routes';
import { GetShiftsById } from '~/domain/usecases/shifts/remote';

interface userState {
  userId: number;
}

interface ownProps {
  shift?: GetShiftsById.Model;
}

type ScheduleDay = CreateShifts.Params['body']['days']['MO'];
type DaysOfWeek = 'SU' | 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA';

const NewProfessionalShift: React.FC<ownProps> = ({ shift }) => {
  const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
  const [isLoaded, setIsLoaded] = useState(false);
  const [requiredIndex, setRequiredIndex] = useState<boolean[]>(
    Array(7).fill(false),
  );
  const [disabledIndex, setDisabledIndex] = useState<boolean[]>(
    Array(7).fill(true),
  );

  const routerState = useLocation().state as userState | undefined;

  const schema = schemaNewProfessionalShift(requiredIndex);

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      scheduleDays: days.map(day => ({ day, restrictions: [] })),
      checkedDays: days.map(day => ({ day, checked: false })),
    },
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = methods;

  const { fields, append } = useFieldArray({
    control,
    name: 'scheduleDays',
  });

  const watchedAllFields = watch();

  const getDaysBetweenDates = (start: Date, end: Date) => {
    return new Set(
      dateFns.eachDayOfInterval({ start, end }).map(date => date.getDay()),
    );
  };

  useEffect(() => {
    console.log('>>> watchedAllFields', watchedAllFields, errors);
  }, [watchedAllFields]);

  useEffect(() => {
    const startDate = watch('startDate');
    const endDate = watch('endDate');
    let disabledDays: boolean[] = Array(7).fill(true);

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const days = getDaysBetweenDates(start, end);

      days.forEach(day => {
        disabledDays[day] = false;
      });

      setDisabledIndex(disabledDays);
    }
  }, [watch('startDate'), watch('endDate')]);

  useEffect(() => {
    if (shift && !isLoaded) {
      let checkedDays_ = days.map(day => ({ day, checked: false }));
      let scheduleDays_: z.infer<
        ReturnType<typeof schemaNewProfessionalShift>
      >['scheduleDays'] = days.map(day => ({
        day,
        restrictions: [],
      }));
      Object.entries(shift.days).forEach(([key, value]) => {
        if (value !== null) {
          const [hoursStart, minutesStart, secondsStart] = value.start
            .split(':')
            .map(Number);
          const [hoursEnd, minutesEnd, secondsEnd] = value.end
            .split(':')
            .map(Number);
          const index = days.indexOf(key);
          checkedDays_[index].checked = true;
          scheduleDays_ = scheduleDays_?.map((item, idx) => {
            if (idx === index)
              return {
                ...item,
                start: dateFns.set(new Date(), {
                  hours: hoursStart,
                  minutes: minutesStart,
                  seconds: secondsStart,
                }),
                end: dateFns.set(new Date(), {
                  hours: hoursEnd,
                  minutes: minutesEnd,
                  seconds: secondsEnd,
                }),
                restrictions: value.restrictions?.map(item => {
                  return {
                    module: `${item.module.id}`,
                    orgUnit: `${item.orgUnit.id}`,
                    specialty: `${item.specialty.id}`,
                    macroregion: `${item.macroregion.id}`,
                  };
                }),
              };
            return item;
          });
        }
      });
      console.log('>>> edit checkedDays_', checkedDays_);
      console.log('>>> edit scheduleDays_', scheduleDays_);
      setValue('scheduleDays', scheduleDays_ || [], {
        shouldDirty: true,
        shouldValidate: true,
      });
      reset({
        startDate: new Date(shift.start),
        endDate: new Date(shift.end),
        checkedDays: checkedDays_,
        scheduleDays: scheduleDays_,
      });

      setIsLoaded(true);

      // setValue('checkedDays', checkedDays_);
    } else if (!shift && !isLoaded) {
      setIsLoaded(true);
    }

    console.log('>>> watchedAllFields', watchedAllFields, errors);
  }, [shift, reset, setValue, isLoaded]);

  useEffect(() => {
    console.log('>>> watch', watch(), fields); // Verifica se os valores estão sendo atualizados
  }, [watch]);

  useEffect(() => {
    const checkedDays = watch('checkedDays');
    const updatedIndex = checkedDays.map(day => day.checked ?? false);

    if (checkedDays.some((day, index) => day.checked !== requiredIndex[index]))
      setRequiredIndex(updatedIndex);

    console.log('>>> updated checkedDays', checkedDays, requiredIndex);
  }, [watch('checkedDays')]);

  return isLoaded ? (
    <Container>
      <Menu />
      <HeaderSimples title="Cadastrar nova escala" />
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(data => {
            console.log('>>> data', data);

            const days = data?.scheduleDays?.map((day, idx) =>
              day && requiredIndex[idx] ? { ...day, checked: true } : undefined,
            );

            if (days?.some(day => day !== undefined)) {
              const formatDay = (day: (typeof days)[0]): ScheduleDay => {
                return day?.restrictions && day.start && day.end
                  ? ({
                      start: dateFns.format(day.start, 'HH:mm:ss'),
                      end: dateFns.format(day.end, 'HH:mm:ss'),
                      restrictions: day.restrictions.map(item => ({
                        module: Number(item.module),
                        orgUnit: Number(item.orgUnit),
                        specialty: Number(item.specialty),
                        macroregion: Number(item.macroregion),
                      })),
                    } as ScheduleDay)
                  : undefined;
              };
              console.log('>>> formatDay', days.map(formatDay));
              console.log('>>> routerState', routerState);

              if (routerState?.userId && !shift)
                makeRemoteCreateShifts()
                  .create({
                    body: {
                      user: Number(routerState?.userId),
                      start: dateFns.format(data.startDate, 'yyyy-MM-dd'),
                      end: dateFns.format(data.endDate, 'yyyy-MM-dd'),
                      timezone: 180,
                      days: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].reduce(
                        (acc, day, idx) => {
                          acc[day as DaysOfWeek] = requiredIndex[idx]
                            ? formatDay(days[idx])
                            : undefined;
                          return acc;
                        },
                        {} as Record<DaysOfWeek, ScheduleDay>,
                      ),
                    },
                  })
                  .then(() => {
                    History.back();
                  })
                  .catch(() => {
                    History.back();
                    alert('Erro no Cadastro da escala');
                  });

              if (routerState?.userId && shift)
                makeRemoteUpdateShifts().update({
                  shiftId: shift.id,
                  body: {
                    start: dateFns.format(data.startDate, 'yyyy-MM-dd'),
                    end: dateFns.format(data.endDate, 'yyyy-MM-dd'),
                    timezone: 180,
                    days: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].reduce(
                      (acc, day, idx) => {
                        acc[day as DaysOfWeek] = requiredIndex[idx]
                          ? formatDay(days[idx])
                          : undefined;
                        return acc;
                      },
                      {} as Record<DaysOfWeek, ScheduleDay>,
                    ),
                  },
                });
            }
          })}
        >
          <Body>
            <ShiftDataContainer>
              <SchedulePlanContainer>
                <div className="title">
                  <Typography variant="t4_16medium">
                    Período de vigência<Required>*</Required>
                  </Typography>
                  <Typography variant="b4_14regular">
                    Selecione o período de vigência da escala
                  </Typography>
                </div>
                <div className="schedule">
                  <DatePickerField
                    name={`startDate`}
                    placeholderText="DD/MM/AAAA"
                    minDate={new Date()}
                    maxDate={new Date(watch(`endDate`))}
                    dateFormat={'dd/MM/yyyy'}
                    withMask={true}
                    customInput={
                      <InputMask mask="99/99/9999">
                        <InputDs
                          required
                          autoComplete="off"
                          helperText={errors?.startDate?.message}
                          status={errors?.startDate ? 'error' : 'default'}
                        />
                      </InputMask>
                    }
                  />
                  <Typography
                    variant="b4_14regular"
                    style={{ height: '40px', alignContent: 'center' }}
                  >
                    até
                  </Typography>
                  <DatePickerField
                    name={`endDate`}
                    placeholderText="DD/MM/AAAA"
                    dateFormat={'dd/MM/yyyy'}
                    withMask={true}
                    minDate={new Date(watch(`startDate`))}
                    customInput={
                      <InputMask mask="99/99/9999">
                        <InputDs
                          required
                          autoComplete="off"
                          helperText={errors?.endDate?.message}
                          status={errors?.endDate ? 'error' : 'default'}
                        />
                      </InputMask>
                    }
                  />
                </div>
              </SchedulePlanContainer>
              <ScheduleDaysContainer>
                <Typography variant="t4_16medium">
                  Dias da semana<Required>*</Required>
                </Typography>
                <ScheduleDay disabledIndex={disabledIndex} />
              </ScheduleDaysContainer>
            </ShiftDataContainer>
          </Body>
          <Footer>
            <div>
              <Button
                size="md"
                variant="outlinedDestructive"
                onClick={History.getHistory().goBack}
              >
                Cancelar
              </Button>
            </div>
            <div>
              <Button
                size="md"
                variant="primary"
                type="submit"
                disabled={
                  isSubmitting || requiredIndex.every(item => item === false)
                }
              >
                Salvar
              </Button>
            </div>
          </Footer>
        </Form>
      </FormProvider>
    </Container>
  ) : (
    <></>
  );
};

export default NewProfessionalShift;
