import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateTeleshifts as UsecaseRemoteCreateTeleshifts } from '~/domain/usecases/teleShifts/remote';

import { RemoteCreateTeleshifts } from '~/data/repository/teleShifts';

/**
 * send request via API.
 */
export const makeRemoteCreateTeleshifts = (): UsecaseRemoteCreateTeleshifts =>
  new RemoteCreateTeleshifts(makeApiUrl('/shifts/'), makeHttpClient());
