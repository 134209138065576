export function filterLaterTimes(
  startTime: string,
  timeList: string[],
): string[] {
  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const startTotalMinutes = startHours * 60 + startMinutes;

  return timeList.filter(time => {
    const [hours, minutes] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes > startTotalMinutes;
  });
}
