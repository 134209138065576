import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { PostAvailabilityDetailed } from '~/domain/usecases/availability/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { UnexpectedError, Forbidden, BadGateway } from '~/domain/errors';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import '~/infra/global/variables';

export class RemoteAvailabilityDetailed implements PostAvailabilityDetailed {
  private readonly url: string;

  private readonly httpClient: HttpClient<PostAvailabilityDetailed.Model>;

  constructor(
    url: string,
    httClient: HttpClient<PostAvailabilityDetailed.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  post = async (
    params: PostAvailabilityDetailed.Params,
  ): Promise<PostAvailabilityDetailed.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
