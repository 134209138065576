import { ListShifts as UsecaseReduxListShifts } from '~/domain/usecases/teleShifts/redux/ListShifts';
import { ListShifts as UsecaseRemoteListShifts } from '~/domain/usecases/teleShifts/remote';

import { makeApiUrl, makeHttpClient } from '../../http';

import { RemoteListShifts } from '~/data/repository/teleShifts';
import { ReduxListShifts } from '~/data/store/reducer/teleShifts/usecases';

export const makeRemoteListShifts = (): UsecaseRemoteListShifts =>
  new RemoteListShifts(
    // TODO: Match the real endpoint
    // makeApiUrl('/shifts/list'),
    makeApiUrl('/shifts/list/detailed'),
    makeHttpClient(),
  );

export const makeReduxListShifts = (): UsecaseReduxListShifts =>
  new ReduxListShifts();
