import React, { useEffect, useRef, useMemo } from 'react';
import Quill, { QuillOptions } from 'quill';
import { Container } from './styles';
import 'quill/dist/quill.snow.css';

interface OwnProps {
  value: string;
  onChange: (content: string) => void;
}

const Editor = ({ value, onChange }: OwnProps) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);
  const isUpdating = useRef(false);

  useEffect(() => {
    if (!editorRef.current || quillRef.current) return;

    quillRef.current = new Quill(editorRef.current, {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['bold', 'italic', 'underline'],
          ['link'],
          [{ align: [] }],
          ['clean'],
        ],
      },
      formats: [
        'header',
        'font',
        'list',
        'bullet',
        'bold',
        'italic',
        'underline',
        'link',
        'align',
      ],
    });

    quillRef.current.on('text-change', () => {
      if (!editorRef.current) return;
      isUpdating.current = true;

      const html = editorRef.current.children[0]?.innerHTML || '';
      onChange(html);
      setTimeout(() => (isUpdating.current = false), 0);
    });

    return () => {
      quillRef.current?.off('text-change');
    };
  }, [onChange]);

  useEffect(() => {
    if (isUpdating.current || !quillRef.current) return;

    const currentHtml = editorRef.current?.children?.[0].innerHTML;
    if (value !== currentHtml) {
      quillRef.current.root.innerHTML = value;
    }
  }, [value]);

  return <Container ref={editorRef} />;
};

export default Editor;
