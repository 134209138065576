import React, { ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { iConsultant, iStore } from '~/domain/interfaces/models';
import { iListConsultant, iListProfessional } from '../interface';
import { ownProps, externalProps } from '../GeneralData';
import { applyNumericMask } from '~/utils/applyNumericMask';

/**
 * Connect component.
 * @param Component componente to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P & externalProps> {
  const Render: React.FC<P & externalProps> = ({ ...rest }) => {
    const { consultant, professional, specialty } = useSelector(
      (state: iStore) => state,
    );

    Component.defaultProps = {
      consultant: _.orderBy(
        mapConsultant(consultant),
        item => item.firstName,
        'asc',
      ),
      specialty: specialty.results,
      filterProfessional: (id: number | undefined) =>
        _.orderBy(
          filterProfessional(id, professional.results),
          item => item.user.firstName,
          'asc',
        ),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps & externalProps)} />;
  };

  return Render;
}

/**
 * Map list o consultant to component.
 * @param consultant list of consultant.
 */
export const mapConsultant = (consultant: iConsultant): iListConsultant[] => {
  const arr: iListConsultant[] = [];

  consultant.results.forEach(item => {
    const mainDocument = item.documents?.[0];
    const mask = mainDocument?.mask;
    const document = mainDocument?.value;

    const maskedDocument = mask ? applyNumericMask(mask, document) : document;
    const formattedDocument = `${maskedDocument} ${mainDocument.acronym && `(${mainDocument.acronym})`}`;

    return arr.push({
      id: Number(item.consultant.id),
      userId: item.user.id,
      firstName: item.user.firstName,
      lastName: item.user.lastName,
      email: item.user.email,
      phone: item.user.phone,
      document: formattedDocument,
    });
  });

  return arr;
};

/**
 * filter professional based specialty ID.
 * @param id specialty ID.
 * @param professionals list of professional.
 */
const filterProfessional = (
  id: number | undefined,
  professionals: iListProfessional[],
): iListProfessional[] => {
  const arr: iListProfessional[] = professionals.filter(prof => {
    let hasSpecialty = false;

    prof.specialties.forEach(specialty => {
      if (Number(specialty.id) === Number(id)) hasSpecialty = true;
    });

    return hasSpecialty;
  });

  return arr;
};
