import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { addDays } from 'date-fns/esm';
import { iMessage, iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IconBtnClose } from '~/presentation/base/icons';

import { makeReduxCreateShifts } from '~/main/factories/usecases/shifts';
import { closeModal } from '~/utils/closeModal';
import { createShiftValidator } from '~/validation/validators/shifts';
import { Button, Select } from '../UI';
import InputKeyboardDate from '../inputKeyboardDate';
import { V4hSpin } from '../spin';
import {
  Box,
  ButtonClose,
  ButtonView,
  Container,
  Content,
  Divider,
  InputContent,
  NormalText,
  SelectLabel,
  Text,
  TopContent,
} from './styles';
import _ from 'lodash';
import SearchSelect from '../UI/searchSelect';

type Props = {
  message: iMessage;
};

type days =
  | 'Domingo'
  | 'Segunda-feira'
  | 'Terça-feira'
  | 'Quarta-feira'
  | 'Quinta-feira'
  | 'Sexta-feira'
  | 'Sábado';

const daysOfWeek: Record<
  number,
  { label: days; id: 'SU' | 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' }
> = {
  0: { label: 'Domingo', id: 'SU' },
  1: { label: 'Segunda-feira', id: 'MO' },
  2: { label: 'Terça-feira', id: 'TU' },
  3: { label: 'Quarta-feira', id: 'WE' },
  4: { label: 'Quinta-feira', id: 'TH' },
  5: { label: 'Sexta-feira', id: 'FR' },
  6: { label: 'Sábado', id: 'SA' },
};

type Validity = {
  begin: Date;
  end: Date;
};

const CreateNewLayOver: React.FC<Props> = ({ message }) => {
  const [validity, setValidity] = useState<Validity>({
    begin: new Date(),
    end: addDays(new Date(), 1),
  });

  const { user } = useSelector((store: iStore) => store.auth.info);
  const { professionals } = useSelector((store: iStore) => store.auth.info);
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);
  const { results } = useSelector((store: iStore) => store.professional);
  const { loading } = useSelector((store: iStore) => store.shifts);

  const { active: actualModalActive, data: onCallId, actionOk } = message;
  const modalName = MessageOptions.createShifts;
  const isActive = actualModalActive === modalName;

  const imAProfessional = useMemo(() => {
    if (user?.mainRole !== 'PRO') return undefined;

    const selectedProfessional = professionals?.find(p => p.org.id === orgId);

    return selectedProfessional?.id;
  }, [orgId, professionals, user?.mainRole]);

  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    errors,
    watch,
    reset,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      begin: '00:00',
      end: '00:30',
      day: 'SU',
    },
    resolver: zodResolver(createShiftValidator),
    reValidateMode: 'onBlur',
    mode: 'all',
    shouldUnregister: true,
  });

  const begin = watch('begin');

  const dateIsLessThan = (start: string, end: string) => {
    const [startHour, startMinute] = (start ?? '').split(':');
    const [endHour, endMinute] = (end ?? '').split(':');

    const startDate = new Date();
    startDate.setHours(Number(startHour));
    startDate.setMinutes(Number(startMinute));

    const endDate = new Date();
    endDate.setHours(Number(endHour));
    endDate.setMinutes(Number(endMinute));

    return startDate.getTime() > endDate.getTime();
  };

  const createLabelsHours = useMemo(() => {
    return Array.from({ length: 48 }).map((__, index) => {
      const hour = index % 2 === 0 ? index / 2 : (index - 1) / 2;
      const minute = index % 2 === 0 ? '00' : '30';
      const label = `${String(hour).padStart(2, '0')}:${minute}`;

      const disabled = dateIsLessThan(begin, label);

      return {
        label,
        disabled,
      };
    });
  }, [begin]);

  const handleCloseModal = () => {
    setValidity({
      begin: new Date(),
      end: new Date(),
    });
    reset();
    closeModal();
  };

  const handleSubmitForm = () => {
    /* const shifts = makeReduxCreateShifts();
    shifts.create({
      onCallId,
      body: {
        ...getValues(),
        professional: imAProfessional ?? Number(getValues().professional),
        validityBegin: format(validity.begin, 'yyyy-MM-dd'),
        validityEnd: format(validity.end, 'yyyy-MM-dd'),
        begin: getValues().begin.padEnd(8, ':00'),
        end: getValues().end.padEnd(8, ':00'),
        recurrence: null,
      },
    });
    actionOk?.(); */
  };

  useEffect(() => {
    if (imAProfessional) {
      reset({
        professional: imAProfessional,
        day: daysOfWeek[0].id,
        begin: '00:00',
        end: '00:30',
      });
    }
  }, [imAProfessional, reset]);

  const generateProfessionaltSelectOptions = useCallback(() => {
    if (imAProfessional) {
      return [
        {
          value: Number(imAProfessional),
          label: `${user?.firstName} ${user?.lastName}`,
        },
      ];
    }
    return _.sortBy(
      results?.flatMap(value => {
        return [
          {
            value: value.professional.id,
            label: `${value.user.firstName} ${value.user.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [results]);

  const professionalMultiselectSelected = useMemo(() => {
    return generateProfessionaltSelectOptions().find(
      option => option.value === watch('professional'),
    );
  }, [generateProfessionaltSelectOptions, watch]);

  return isActive ? (
    <Container>
      <Content>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box>
            <TopContent>
              <Text>Adicionar escala</Text>
              <ButtonClose onClick={handleCloseModal}>
                <IconBtnClose />
              </ButtonClose>
            </TopContent>
            <SelectLabel>Profissional</SelectLabel>
            <SearchSelect
              key={imAProfessional}
              name="professional"
              height="40px"
              register={() =>
                register('professional', {
                  valueAsNumber: true,
                })
              }
              placeholder="Selecione um profissional"
              onChange={e => {
                setValue('professional', Number(e?.value));
              }}
              error={!!errors.professional}
              message={errors.professional?.message}
              options={generateProfessionaltSelectOptions()}
              value={professionalMultiselectSelected}
              isDisabled={!!imAProfessional}
            />
          </Box>
          <Divider />
          <Box>
            <div style={{ marginBottom: 20 }}>
              <SelectLabel>Dia da semana</SelectLabel>
              <Controller
                name="day"
                control={control}
                defaultValue={getValues()?.day ?? daysOfWeek[0].id}
                render={props => (
                  <Select
                    height="40px"
                    error={!!errors.day}
                    message={errors.day?.message}
                    defaultValue={daysOfWeek[0].id}
                    register={() => register('day')}
                    {...props}
                  >
                    {Object.entries(daysOfWeek).map(([key, day]) => (
                      <option key={key} value={day.id}>
                        {day.label}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </div>
            <SelectLabel>Período de vigência</SelectLabel>
            <InputContent>
              <InputKeyboardDate
                id="validityBegin"
                name="validityBegin"
                height="40px"
                state={validity.begin}
                setState={(date: Date) =>
                  setValidity({ ...validity, begin: date })
                }
                minDate={new Date()}
                endAdornment
              />
              <NormalText>até</NormalText>
              <InputKeyboardDate
                id="validityEnd"
                name="validityEnd"
                height="40px"
                state={
                  validity.end < validity.begin ? validity.begin : validity.end
                }
                setState={(date: Date) =>
                  setValidity({ ...validity, end: date })
                }
                minDate={new Date(validity.begin)}
                endAdornment
              />
            </InputContent>
          </Box>
          <Divider />
          <Box>
            <InputContent>
              <div>
                <SelectLabel>Inicia em</SelectLabel>
                <Select
                  height="40px"
                  width="168px"
                  name="begin"
                  error={!!errors.begin}
                  message={errors.begin?.message}
                  register={() => register('begin')}
                  value={watch('begin')}
                  onChange={e =>
                    setValue('begin', e.target.value, {
                      shouldValidate: true,
                    })
                  }
                >
                  {createLabelsHours.map(({ label }) => (
                    <option key={label}>{label}</option>
                  ))}
                </Select>
              </div>
              <div>
                <SelectLabel>Termina em</SelectLabel>
                <Select
                  height="40px"
                  width="168px"
                  name="end"
                  error={!!errors?.end}
                  message={errors?.end?.message}
                  register={() => register('end')}
                  value={watch('end')}
                  onChange={e =>
                    setValue('end', e.target.value, {
                      shouldValidate: true,
                    })
                  }
                  disabled={!watch('begin')}
                >
                  {createLabelsHours.map(({ label, disabled }) => (
                    <option key={label} disabled={disabled}>
                      {label}
                    </option>
                  ))}
                </Select>
              </div>
            </InputContent>
            <ButtonView>
              <Button
                type="submit"
                disabled={loading || (!isValid && !user?.id)}
                resourcers={['ONCALL']}
                actions={['CREATE_SHIFT_ORG', 'CREATE_SHIFT_ANY']}
              >
                {loading ? (
                  <V4hSpin fullScreen={false} size="1.2rem" />
                ) : (
                  'Adicionar escala'
                )}
              </Button>
              <Button variant="secundary" onClick={handleCloseModal}>
                Cancelar
              </Button>
            </ButtonView>
          </Box>
        </form>
      </Content>
    </Container>
  ) : null;
};

export default CreateNewLayOver;
