import React, { useEffect, useMemo, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import {
  Body,
  Container,
  Footer,
  Header,
  HeaderButtonsSession,
  HeaderDataStepSession,
} from './styles/StyledScaleProfessionalConfig';
import { getLocale } from '~/utils/getLocale';
import translations from '~/infra/i18n/locales';
import { Button } from '@wisecare-tech/design-system-web';
import { translator } from '../i18n';
import DateRangeNavigator from '../dateNavigator';
import { useParams } from 'react-router';
import { ListDetailedTeleShifts } from '~/domain/usecases/teleShifts/remote';
import WeeklyShiftsListing from './lists/weeklyShiftsListing';
import { ListTeleShifts } from '~/domain/usecases/shifts/remote';
import { downloadFiles, downloadFilesWithoutId } from '~/utils/downloadFiles';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteDeleteShiftsById } from '~/main/factories/usecases/shifts';
import { AlertMessage } from '../messages/AlertMessage';
import { makeRemoteListDetailedShifts } from '~/main/factories/usecases/teleShifts/ListDetailedShiftsFactory';
import { generateDateRanges } from '~/utils/generateDateRange';
import { closeModal } from '~/utils/closeModal';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeRemoteDeleteShift } from '~/main/factories/usecases/teleShifts/DeleteShiftFactory';
import { History } from '~/main/routes';

interface iParamsTypes {
  userId: string;
}

type GroupedRecords = {
  records: ListDetailedTeleShifts.Model['records'];
};

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const ScaleProfessionalConfig: React.FC = () => {
  const [shift, setShift] = useState<ListTeleShifts.Model['records'][0]>();
  const [records, setRecords] =
    React.useState<ListDetailedTeleShifts.Model['records']>();
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const { userId: professionalId } = useParams<iParamsTypes>();
  const { token } = useSelector((store: iStore) => store.auth.access);

  const dateRange = useMemo(
    () => ({
      start: shift?.start ? new Date(shift.start) : new Date(),
      end: shift?.end ? new Date(shift.end) : new Date(),
    }),
    [shift],
  );

  useEffect(() => {
    if (professionalId)
      makeRemoteListDetailedShifts()
        .list({
          filters: {
            user: Number(professionalId),
            start: dateRange.start.toISOString().split('T')[0],
            end: dateRange.end.toISOString().split('T')[0],
          },
        })
        .then(response => {
          setRecords(response.records);
        })
        .catch(error => {
          console.log(error);
        });
  }, [dateRange, professionalId]);

  return (
    <Container>
      <Header>
        <HeaderDataStepSession>
          <DateRangeNavigator
            shiftDate={dateRange}
            setShiftDate={data => setShift(data)}
            professionalId={Number(professionalId)}
            refetch={refetch}
          />
        </HeaderDataStepSession>
        <HeaderButtonsSession>
          <Button
            id="action_editDetailedScale"
            variant="secondary"
            icon="edit"
            onClick={() => {
              if (shift?.id) History.push(`/shifts/edit/${shift.id}`);
            }}
          >
            {translator('Editar')}
          </Button>
          <Button
            id="action_registerDetailedScale"
            variant="primary"
            icon="add"
            onClick={() =>
              History.push('/shifts/new', { userId: professionalId })
            }
          >
            {translator('Cadastrar escala')}
          </Button>
        </HeaderButtonsSession>
      </Header>
      <Body>
        <WeeklyShiftsListing
          professionalId={professionalId}
          initialDateRange={dateRange}
          records={records ?? []}
          setRecords={setRecords}
          // calcular semanas entre start e end
          weeks={1}
        />
      </Body>
      <Footer>
        <div className="buttonsFooterDiv">
          {shift?.id && (
            <Button
              id="action_deleteDetailedScale"
              variant="destructive"
              icon="delete"
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'twoButtonModal',
                  actionCancel: () => {
                    closeModal();
                  },
                  actionOk: () => {
                    makeRemoteDeleteShift()
                      .delete({
                        shift: shift.id,
                      })
                      .then(res => {
                        setShift(undefined);
                        setRefetch(prev => !prev);
                        closeModal();
                        AlertMessage({
                          type: 'success',
                          message: intl.formatMessage({
                            id: 'Escala excluída com sucesso!',
                          }),
                        });
                      })
                      .catch(err => {
                        AlertMessage({
                          type: 'danger',
                          message: intl.formatMessage({
                            id: 'Não foi possível excluir a escala!',
                          }),
                        });
                      });
                  },
                  data: {
                    variant: 'CANCEL',
                    message:
                      'Você está excluindo a escala deste profissional. Após a exclusão, não será mais possível agendar atendimentos, solicitar laudos ou plantões. Deseja prosseguir?',
                    title: 'Excluir escala',
                    textSecondaryButton: 'Voltar',
                    textPrimaryButton: 'Sim, quero excluir',
                  },
                });
              }}
            >
              {`${translator('Excluir escala')}`}
            </Button>
          )}
          {shift?.id && (
            <Button
              id="action_downloadDetailedScale"
              icon="download"
              variant="secondary"
              onClick={() =>
                downloadFiles('shifts', token, shift.id, setLoading)
              }
            >
              {`${translator('Baixar PDF')}`}
            </Button>
          )}
        </div>
      </Footer>
    </Container>
  );
};

export default ScaleProfessionalConfig;
