import {
  eachDayOfInterval,
  format,
  isAfter,
  isSameDay,
  startOfToday,
  endOfWeek,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface iDates {
  name: string;
  date: string;
  dayOfWeek: string;
}

function convertDayToWeek(day: string): string {
  const dayOfWeekMap: { [key: string]: string } = {
    Domingo: 'SU',
    'Segunda-feira': 'MO',
    'Terça-feira': 'TU',
    'Quarta-feira': 'WE',
    'Quinta-feira': 'TH',
    'Sexta-feira': 'FR',
    Sábado: 'SA',
  };

  return dayOfWeekMap[day] || '';
}

function capitalizeFirstLetter(text: string): string {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export const listDaysOfTheWeek = (): iDates[] => {
  const now = startOfToday();

  const lastDayOfWeek = endOfWeek(now);

  const dates = eachDayOfInterval({ start: now, end: lastDayOfWeek })
    .map(date => {
      if (isSameDay(date, now) || isAfter(date, now)) {
        return `${capitalizeFirstLetter(format(date, 'EEEE, dd/MM/yyyy', { locale: ptBR }))}`;
      }
      return '';
    })
    .filter(date => date !== '');

  const datesFormatted: iDates[] = dates?.map(date => {
    const [dayOfWeek, dateOfStart] = date.split(',');

    return {
      name: date,
      date: dateOfStart.trim(),
      dayOfWeek: convertDayToWeek(dayOfWeek),
    };
  });

  return datesFormatted;
};
