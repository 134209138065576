import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { UpdateShifts } from '~/domain/usecases/shifts/remote';

import {
  BadRequestError,
  Forbidden,
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

export class RemoteUpdateShifts implements UpdateShifts {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateShifts.Model>;

  constructor(url: string, httpClient: HttpClient<UpdateShifts.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  update = async (params: UpdateShifts.Params): Promise<UpdateShifts.Model> => {
    const url = this.url.replace('{shiftId}', params.shiftId.toString());

    const httpResponse = await this.httpClient.request({
      url: `${url}/${params.shiftId}`,
      method: 'patch',
      body: params.body,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        AlertMessage({
          message: 'Escala atualizada com sucesso!',
          type: 'success',
        });
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
