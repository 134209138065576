import React from 'react';

import { Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router';
import { Popover } from '~/presentation/components/UI/popover';

import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import {
  IconFlag,
  IconMore,
  IconPenGray,
  IconPlan,
  IconSubject,
  IconEye,
} from '~/presentation/base/icons';
import { calculateAgeAndMonths } from '~/utils/calculateAgeAndMonths';
import { closeModal } from '~/utils/closeModal';
import { priorityMap } from '~/utils/mapPriority';
import DetailsCardButtons from './DetailsCardButtons';
import {
  ActionButton,
  Body,
  ConsultInfoContainer,
  ConsultInfoItem,
  ConsultInfoItemGroup,
  Container,
  ContainerPopover,
  ContainerSubject,
  EmptyCard,
  Header,
  Options,
  Priority,
  TextBold,
  TextNormal,
  Title,
  TitleSubject,
} from './styles/Styled';
import {
  makeReduxListOnCallRequest,
  makeRemoteListOnCallRequest,
} from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { AlertMessage } from '../messages/AlertMessage';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import {
  Button,
  Icon,
  IconButton,
  Toaster,
} from '@wisecare-tech/design-system-web';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

interface iRequest {
  name?: string;
  sex?: string;
  age?: string;
  priority?: 'EMERGENCY' | 'NORMAL' | 'LOW';
  specialty?: string;
  subject?: string;
  docType?: string;
  docValue?: string;
  requestId?: number;
  status?: string;
  healthUnitId?: number;
}

interface DutyRequestDetailsCardProps {
  props?: string;
  info: iOnCallRequest['records'][0] | null;
  selectedRequester?: boolean;
  variant: 'SPECIALIST' | 'REQUESTER';
  setSelectedRequest: (requester: iOnCallRequest['records'][0] | null) => void;
}

const DutyRequestDetailsCard: React.FC<DutyRequestDetailsCardProps> = ({
  info,
  selectedRequester,
  variant,
  setSelectedRequest,
}) => {
  const { pathname } = useLocation();
  const last = pathname.split('/').pop();
  const id = last === 'me' ? undefined : Number(last);

  const professional = getProfessionalInfo();
  const isMyRequest =
    info?.requester.id === Number(professional?.id) ||
    info?.specialist.id === Number(professional?.id);

  const { orgUnits } = useSelector((store: iStore) => store.auth.selectUser);

  const orgUnitsFormatted =
    (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

  const updateConsultation = () => {
    makeReduxActiveMessage().active({
      active: 'requestConsultation',
      actionCancel: () => {
        closeModal();
      },
      userId: info?.id,
      data: {
        isEditing: true,
        onSuccess: () => {
          makeReduxListOnCallRequest().list({
            body: {
              dataControl: {
                limit: 9999,
              },
              filters: {
                requester: info?.requester.id,
                status: 'WAITING',
                healthUnit: info?.healthUnit.id,
                onCall: id,
                orgUnit: orgUnitsFormatted,
              },
            },
          });

          makeRemoteListOnCallRequest()
            .list({
              body: {
                filters: {
                  id: Number(info?.id),
                  onCall: id,
                },
              },
            })
            .then(res => {
              setSelectedRequest(res.records[0]);
            })
            .catch(() => {
              AlertMessage({
                message: 'Erro ao buscar solicitação!',
                type: 'danger',
              });
            });
        },
      },
    });
  };

  const contentPopover = (
    <ContainerPopover
      onClick={() => {
        makeReduxActiveMessage().active({
          active: 'requestConsultation',
          actionCancel: () => {
            closeModal();
          },
          userId: info?.id,
          data: {
            isEditing: true,
            onSuccess: () => {
              makeReduxListOnCallRequest().list({
                body: {
                  dataControl: {
                    limit: 9999,
                  },
                  filters: {
                    requester: info?.requester.id,
                    status: 'WAITING',
                    healthUnit: info?.healthUnit.id,
                    onCall: id,
                    orgUnit: orgUnitsFormatted,
                  },
                },
              });

              makeRemoteListOnCallRequest()
                .list({
                  body: {
                    filters: {
                      id: Number(info?.id),
                      onCall: id,
                    },
                  },
                })
                .then(res => {
                  setSelectedRequest(res.records[0]);
                })
                .catch(() => {
                  AlertMessage({
                    message: 'Erro ao buscar solicitação!',
                    type: 'danger',
                  });
                });
            },
          },
        });
      }}
    >
      <IconPenGray width={18} height={18} />
      <Title>
        {variant === 'REQUESTER'
          ? 'Editar dados da solicitação'
          : 'Atualizar solicitação'}
      </Title>
    </ContainerPopover>
  );

  const contentPopoverProfessional = (
    <ContainerPopover
      onClick={() => {
        makeReduxActiveMessage().active({
          active: 'updatedRequestWaiting',
          actionCancel: () => {
            closeModal();
          },
          actionOk: () => {
            closeModal();
          },
          data: {
            requestId: info?.id,
            onSuccess: () => {
              makeReduxListOnCallRequest().list({
                body: {
                  dataControl: {
                    limit: 9999,
                  },
                  filters: {
                    requester: info?.requester.id,
                    status: 'PROGRESS',
                    healthUnit: info?.healthUnit.id,
                    onCall: id,
                    orgUnit: orgUnitsFormatted,
                  },
                },
              });

              makeRemoteListOnCallRequest()
                .list({
                  body: {
                    filters: {
                      id: Number(info?.id),
                      onCall: id,
                    },
                  },
                })
                .then(res => {
                  setSelectedRequest(res.records[0]);
                })
                .catch(() => {
                  AlertMessage({
                    message: 'Erro ao buscar solicitação!',
                    type: 'danger',
                  });
                });
            },
          },
        });
      }}
    >
      <IconPenGray width={18} height={18} />
      <Title>Atualizar solicitação</Title>
    </ContainerPopover>
  );

  console.log('#info', info);

  return (
    <Container>
      <Header>Detalhes da solicitação</Header>
      <Body>
        {selectedRequester ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              width: '100%',
            }}
          >
            <div style={{ width: '100%' }}>
              <ConsultInfoContainer withBorder>
                <ConsultInfoItem>
                  <TextBold>Nome:</TextBold>
                  <TextNormal>
                    {info?.consultant?.fullname ?? 'Não informado'}
                  </TextNormal>
                </ConsultInfoItem>
                <ConsultInfoItem>
                  <TextBold>Sexo:</TextBold>

                  <TextNormal>
                    {info?.consultant.sex === 'F' ? 'Feminino' : 'Masculino'}
                  </TextNormal>
                </ConsultInfoItem>
                <ConsultInfoItem>
                  <TextBold>Nascimento:</TextBold>
                  {info?.consultant.birthdate ? (
                    <TextNormal style={{ textTransform: 'none' }}>
                      {calculateAgeAndMonths(info?.consultant.birthdate)}
                    </TextNormal>
                  ) : (
                    <TextNormal>Não informado</TextNormal>
                  )}
                </ConsultInfoItem>
                <ConsultInfoItem>
                  <TextBold>
                    {!info?.consultant.docType
                      ? 'Documento: '
                      : `${info?.consultant.docType}:`}
                  </TextBold>
                  <TextNormal>
                    {info?.consultant.docValue ?? 'Não informado'}
                  </TextNormal>
                </ConsultInfoItem>
              </ConsultInfoContainer>

              {(info?.status === 'WAITING' ||
                info?.status === 'PROGRESS' ||
                info?.status === 'REPORTING' ||
                info?.status === 'MONITORING') && (
                <ConsultInfoContainer>
                  <ConsultInfoItemGroup>
                    <ConsultInfoItem>
                      <TextBold>Prioridade:</TextBold>
                      {info?.priority ? (
                        <Priority priority={info?.priority}>
                          <IconFlag />
                          {priorityMap(info?.priority)}
                        </Priority>
                      ) : (
                        <TextNormal>Não informado</TextNormal>
                      )}
                    </ConsultInfoItem>
                    {variant === 'REQUESTER' && (
                      <ConsultInfoItem style={{ alignItems: 'baseline' }}>
                        <TextBold>Especialidade:</TextBold>
                        <TextNormal style={{ textTransform: 'none' }}>
                          {info?.onCall?.name ?? 'Não Informado'}
                        </TextNormal>
                      </ConsultInfoItem>
                    )}
                  </ConsultInfoItemGroup>
                </ConsultInfoContainer>
              )}

              {/* {(info?.status === 'WAITING' || info?.status === 'REPORTING') &&
                variant === 'SPECIALIST' && (
                  <ConsultInfoContainer>
                    <ConsultInfoItemGroup>
                      <ConsultInfoItem>
                        <TextBold>Prioridade:</TextBold>
                        {info?.priority ? (
                          <Priority priority={info?.priority}>
                            <IconFlag />
                            {priorityMap(info?.priority)}
                          </Priority>
                        ) : (
                          <TextNormal>Não informado</TextNormal>
                        )}
                      </ConsultInfoItem>
                    </ConsultInfoItemGroup>
                  </ConsultInfoContainer>
                )} */}

              {/* {info?.status === 'PROGRESS' && variant === 'SPECIALIST' && (
                <ConsultInfoContainer style={{ padding: 16 }}>
                  <ConsultInfoItemGroup>
                    <ConsultInfoItem style={{ alignItems: 'center' }}>
                      <TextBold>Prioridade:</TextBold>
                      {info?.priority ? (
                        <Priority priority={info?.priority}>
                          <IconFlag />
                          {priorityMap(info?.priority)}
                        </Priority>
                      ) : (
                        <TextNormal>Não informado</TextNormal>
                      )}
                    </ConsultInfoItem>
                  </ConsultInfoItemGroup>
                </ConsultInfoContainer>
              )} */}

              {info?.status === 'CLOSED' && (
                <ConsultInfoContainer style={{ padding: 16 }}>
                  <ConsultInfoItemGroup>
                    <ConsultInfoItem style={{ alignItems: 'baseline' }}>
                      <TextBold>Desfecho:</TextBold>
                      <TextNormal style={{ textTransform: 'none' }}>
                        {`${info?.outcome?.name} (${info?.outcome?.descr})` ||
                          'Não Informado'}
                      </TextNormal>
                    </ConsultInfoItem>
                    <ConsultInfoItem style={{ alignItems: 'baseline' }}>
                      <TextBold>Especialidade:</TextBold>
                      <TextNormal style={{ textTransform: 'none' }}>
                        {`${info?.onCall?.name}` || 'Não Informado'}
                      </TextNormal>
                    </ConsultInfoItem>
                  </ConsultInfoItemGroup>
                </ConsultInfoContainer>
              )}

              <ConsultInfoContainer
                style={{
                  borderTop: '1px solid #dedede',
                }}
              >
                <ConsultInfoItem
                  style={{
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <ContainerSubject>
                    <ConsultInfoItem>
                      <TextBold>Documentos:</TextBold>
                      <TextNormal>
                        {info?.files && `${info?.files.length} anexos`}
                      </TextNormal>
                    </ConsultInfoItem>
                  </ContainerSubject>

                  <ActionButton size="small">
                    <Tooltip title="Detalhes" placement="top" arrow>
                      <IconButton
                        icon="visibility"
                        size="md"
                        variant="primary"
                        disabled={!info?.files?.length}
                        onClick={() => {
                          makeReduxActiveMessage().active({
                            actionCancel: () => {
                              closeModal();
                            },
                            actionOk: () => {
                              closeModal();
                            },
                            active: 'supportDocs',
                            data: {
                              id: info?.id,
                            },
                          });
                        }}
                      />
                    </Tooltip>
                  </ActionButton>
                </ConsultInfoItem>
              </ConsultInfoContainer>

              <ConsultInfoContainer
                style={{
                  borderTop: '1px solid #dedede',
                }}
              >
                <ConsultInfoItem>
                  <ContainerSubject>
                    <TitleSubject>
                      <IconSubject />
                      <span>Subjetivo e objetivo</span>
                    </TitleSubject>
                    <TextNormal>
                      {info?.appointments[0]?.clinicalCondition ??
                        info?.description ??
                        'Não informado'}
                    </TextNormal>
                  </ContainerSubject>
                </ConsultInfoItem>
              </ConsultInfoContainer>

              {(info?.status === 'CLOSED' || info?.status === 'MONITORING') && (
                <ConsultInfoContainer
                  style={{
                    borderTop: '1px solid #dedede',
                  }}
                >
                  <ConsultInfoItem>
                    <ContainerSubject>
                      <TitleSubject>
                        <IconPlan />
                        <span>Plano de cuidado</span>
                      </TitleSubject>
                      <TextNormal style={{ textTransform: 'none' }}>
                        {info?.appointments[0]?.forwarding ?? 'Não informado'}
                      </TextNormal>
                    </ContainerSubject>
                  </ConsultInfoItem>
                </ConsultInfoContainer>
              )}
            </div>

            <>
              <ConsultInfoContainer
                style={{
                  borderTop: '1px solid #dedede',
                  // padding: '16px 32px',
                  margin: 0,
                }}
              >
                <DetailsCardButtons
                  info={info}
                  variant={variant}
                  handleReset={() => setSelectedRequest(null)}
                  setSelectedRequest={setSelectedRequest}
                />
              </ConsultInfoContainer>
            </>
          </div>
        ) : (
          <EmptyCard>Selecione um atendimento ao lado.</EmptyCard>
        )}
      </Body>
    </Container>
  );
};
export default DutyRequestDetailsCard;
