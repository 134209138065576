import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { ListShifts } from '~/domain/usecases/teleShifts/remote';

import {
  BadRequestError,
  Forbidden,
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';

export class RemoteListShifts implements ListShifts {
  private readonly url: string;

  private readonly httpClient: HttpClient<ListShifts.Model>;

  constructor(url: string, httpClient: HttpClient<ListShifts.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  list = async (params: ListShifts.Params): Promise<ListShifts.Model> => {

    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
