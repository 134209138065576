import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListSystemModule as UsecaseRemoteListSystemModule } from '~/domain/usecases/systemModule/remote';
// import { ListSystemModule as UsecaseReduxListSystemModule } from '~/domain/usecases/systemModule/redux';

import { RemoteListSystemModule } from '~/data/repository/systemModule';
// import { ReduxListSystemModule } from '~/data/store/reducer/systemModule/usecases';

/**
 * send request via API.
 */
export const makeRemoteListSystemModule =
  (): UsecaseRemoteListSystemModule =>
    new RemoteListSystemModule(
      makeApiUrl('/systemModules/list'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxListSystemModule =
  (): UsecaseReduxListSystemModule =>
    new ReduxListSystemModule(); */
