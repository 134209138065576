import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { PendencyDiagnosisReport as UsecaseRemotePendencyDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
// import { PenDenCyDiagnosisReport as UsecaseReduxPenDenCyDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

import { RemotePendencyDiagnosisReport } from '~/data/repository/diagnosisReport';
// import { ReduxPenDenCyDiagnosisReport } from '~/data/store/reducer/diagnosisReport/usecases';

/**
 * send request via API.
 */
export const makeRemotePendencyDiagnosisReport =
  (): UsecaseRemotePendencyDiagnosisReport =>
    new RemotePendencyDiagnosisReport(
      makeApiUrl('/reports/{report}/PENDENCY'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxPenDenCyDiagnosisReport =
  (): UsecaseReduxPenDenCyDiagnosisReport =>
    new ReduxPenDenCyDiagnosisReport(); */
