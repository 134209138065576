import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { Login } from '~/domain/usecases/auth/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { UnexpectedError, BadRequestError, NotFound } from '~/domain/errors';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export class RemoteAuthLogin implements Login {
  private readonly url: string;

  private readonly httpClient: HttpClient<Login.Model>;

  constructor(url: string, httpClient: HttpClient<Login.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  login = async (params: Login.Params): Promise<Login.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        AlertMessage({
          message:
            httpResponse.errorCode === 'AUTH_USER_NOT_ACTIVE'
              ? 'Ative sua conta! Acesse o e-mail enviado e siga as instruções para concluir o cadastro.'
              : 'Não foi possível fazer login. Verifique suas credenciais e tente novamente.',
          type: 'danger',
        });

        throw new BadRequestError();
      case HttpStatusCode.notFound:
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        AlertMessage({
          message:
            httpResponse.errorCode === 'AUTH_USER_NOT_ACTIVE'
              ? 'Ative sua conta! Acesse o e-mail enviado e siga as instruções para concluir o cadastro.'
              : 'Não foi possível fazer login. Verifique suas credenciais e tente novamente.',
          type: 'danger',
        });

        throw new NotFound();
      default:
        AlertMessage({
          message:
            httpResponse.errorCode === 'AUTH_USER_NOT_ACTIVE'
              ? 'Ative sua conta! Acesse o e-mail enviado e siga as instruções para concluir o cadastro.'
              : 'Não foi possível fazer login. Verifique suas credenciais e tente novamente.',
          type: 'danger',
        });

        throw new UnexpectedError();
    }
  };
}
