import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UnavailabilityTeleshifts as UsecaseRemoteSetUnavailabilityTeleshifts } from '~/domain/usecases/teleShifts/remote';

import { RemoteUnavailabilityTeleshifts } from '~/data/repository/teleShifts';

/**
 * send request via API.
 */

export const makeRemoteSetUnavailabilityTeleshifts =
  (): UsecaseRemoteSetUnavailabilityTeleshifts =>
    new RemoteUnavailabilityTeleshifts(
      makeApiUrl('/shifts/'),
      makeHttpClient(),
    );
