import React, { useCallback, useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useLocation } from 'react-router';
import { schemaPatientData } from '~/validation/validators/document/CreateClinicalDocValidator';
import { Input, Select } from '../UI';
import { translator } from '../i18n';
import { Navigator } from '../medicalCertificate/Navigator';
import { iCreateMedicalCertificate } from '../medicalCertificate/interface';
import { iListConsultant } from '../simplePrescription/interface';
import { Container, Form, Row } from './styles';
import SearchSelect from '../UI/searchSelect';
import _ from 'lodash';

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

export interface ownProps {
  next: (data: iCreateMedicalCertificate) => void;
  back: (data: iCreateMedicalCertificate) => void;
  state?: iCreateMedicalCertificate;
  consultant: iListConsultant[];
  apac?: boolean;
  disabled?: boolean;
}

const PatientData: React.FC<ownProps> = ({
  next,
  back,
  state,
  consultant,
  apac = false,
  disabled,
}) => {
  const stateParams = useLocation<iStateParams>().state;

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaPatientData),
    defaultValues: {
      ...state,
      birthDate: state?.patient?.birthDate ?? '',
      situation:
        state?.situation !== undefined ? state?.situation : 'REGISTERED',
      patient: {
        fullname: state?.patient?.fullname ?? '',
        id: state?.patient?.id ?? -1,
        gender: state?.patient?.gender !== '' ? state?.patient?.gender : 'MALE',
        age: state?.patient?.age ?? -1,
        birthDate: state?.patient?.birthDate ?? '',
        email: state?.patient?.email ?? '',
        medicalRecord: state?.patient?.medicalRecord ?? '',
        motherName: state?.patient?.motherName ?? '',
        phone: state?.patient?.phone ?? '',
      },
    },
  });

  const onBack = () => back({ ...state });

  const onSubmit = handleSubmit(data => {
    if (data.birthDate) {
      const age = calculateAge(data.birthDate);
      next({ ...data, patient: { ...data.patient, age: Number(age) } });
    } else {
      next({ ...data });
    }
  });

  function calculateAge(birthdate: string): number | null {
    const today = new Date();
    const birthdateParts = birthdate.split('/');

    // Ensure the birthdate is in the correct format
    if (birthdateParts.length !== 3) {
      return null;
    }

    // Extract day, month, and year from the birthdate
    const birthDay = parseInt(birthdateParts[0], 10);
    const birthMonth = parseInt(birthdateParts[1], 10);
    const birthYear = parseInt(birthdateParts[2], 10);

    // Calculate the age
    let age = today.getFullYear() - birthYear;

    // Check if the birthday hasn't occurred yet this year
    const todayMonth = today.getMonth() + 1;
    const todayDay = today.getDate();

    if (
      todayMonth < birthMonth ||
      (todayMonth === birthMonth && todayDay < birthDay)
    ) {
      age -= 1;
    }

    return age;
  }

  const onChangeDate = (value: string) => {
    const date = value.trim();

    setValue('birthDate', date);
  };

  const generateConsultantSelectOptions = useCallback(() => {
    return _.sortBy(
      consultant?.flatMap(value => {
        return [
          {
            value: value.id,
            label: `${value.firstName} ${value.lastName} ${value.document && `- ${value.document}`}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [consultant]);

  const consultantMultiselectSelected = useMemo(() => {
    return generateConsultantSelectOptions().find(
      option => option.value === watch('consultant'),
    );
  }, [generateConsultantSelectOptions, watch]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Row>
          <Select
            id="select_professional"
            name="situation"
            label="Situação cadastral"
            required
            defaultValue={state?.situation}
            error={Boolean(errors.situation)}
            register={() => register('situation')}
            message={
              errors.situation?.message
                ? translator(errors.situation?.message)
                : ''
            }
            onChange={e => {
              setValue(
                'situation',
                e.target.value as 'REGISTERED' | 'NOT-REGISTERED',
              );

              if (e.target.value === 'REGISTERED') {
                setValue('patient.id', e.target.value);
              } else {
                setValue('patient.id', undefined);
              }
            }}
            disabled={disabled}
          >
            <option value="REGISTERED">Paciente cadastrado</option>
            <option value="NOT-REGISTERED">Paciente não cadastrado</option>
          </Select>
          {watch('situation') === 'NOT-REGISTERED' ? (
            <Input
              id="input_instituition"
              label="Nome do paciente"
              name="patient.fullname"
              defaultValue={watch('patient.fullname')}
              register={() => register('patient.fullname')}
              error={Boolean(errors?.patient?.fullname)}
              message={
                errors?.patient?.fullname?.message
                  ? translator(errors?.patient?.fullname?.message)
                  : ''
              }
              autoFocus
              onChange={e => {
                setValue('patient.fullname', e.target.value);
              }}
              value={disabled ? watch('patient.fullname') : undefined}
              disabled={disabled}
            />
          ) : (
            <SearchSelect
              id="select_patient"
              name="patient.id"
              label={`${translator('Paciente')}*`}
              placeholder={translator('Selecione um paciente')}
              onChange={e => {
                setValue('patient.id', Number(e?.value));
              }}
              error={Boolean(errors?.patient?.id)}
              message={
                errors?.patient?.id?.message
                  ? translator(errors?.patient?.id?.message)
                  : ''
              }
              isDisabled={
                disabled ||
                (stateParams &&
                  stateParams?.consultantId !== undefined &&
                  stateParams?.consultantId !== -1)
              }
              register={() => register('patient.id')}
              options={generateConsultantSelectOptions()}
              value={consultantMultiselectSelected}
            />
          )}
        </Row>
        <Row>
          {watch('situation') === 'NOT-REGISTERED' && (
            <>
              <ReactInputMask
                mask="99/99/9999"
                name="birthDate"
                className="birthdate"
                defaultValue={state?.birthDate}
                ref={() => register('birthDate')}
                onChange={e => {
                  onChangeDate(e.target.value.trim());
                }}
                disabled={disabled}
              >
                <Input
                  gridArea="birthDate"
                  id="registerBirthDate"
                  placeholder={translator('DD/MM/AAAA')}
                  label={translator('Data de Nascimento')}
                  error={Boolean(errors.birthDate)}
                  message={
                    errors?.birthDate?.message
                      ? translator(errors?.birthDate?.message)
                      : ''
                  }
                  required
                />
              </ReactInputMask>

              <Select
                id="select_professional"
                name="patient.gender"
                register={() => register('patient.gender')}
                label="Sexo"
                required
                defaultValue={state?.patient?.gender}
                onChange={e => {
                  setValue('patient.gender', e.target.value);
                }}
                disabled={disabled}
              >
                <option value="MALE">Masculino</option>
                <option value="FEMALE">Feminino</option>
              </Select>

              {apac && (
                <Input
                  id="input_medicalrecord"
                  label="Número do prontuário"
                  name="patient.medicalRecord"
                  defaultValue={watch('patient.medicalRecord')}
                  register={() => register('patient.medicalRecord')}
                  error={Boolean(errors?.patient?.medicalRecord)}
                  message={
                    errors?.patient?.medicalRecord?.message
                      ? translator(errors?.patient?.medicalRecord?.message)
                      : ''
                  }
                  onChange={e => {
                    setValue('patient.medicalRecord', e.target.value);
                  }}
                />
              )}
            </>
          )}
        </Row>
        {apac && (
          <Row>
            {watch('situation') === 'NOT-REGISTERED' && (
              <>
                <Input
                  id="input_phone"
                  label="Telefone"
                  name="patient.phone"
                  defaultValue={watch('patient.phone')}
                  register={() => register('patient.phone')}
                  error={Boolean(errors?.patient?.phone)}
                  message={
                    errors?.patient?.phone?.message
                      ? translator(errors?.patient?.phone?.message)
                      : ''
                  }
                  onChange={e => {
                    setValue('patient.phone', e.target.value);
                  }}
                />

                <Input
                  id="input_email"
                  label="E-mail"
                  name="patient.email"
                  defaultValue={watch('patient.email')}
                  register={() => register('patient.email')}
                  error={Boolean(errors?.patient?.email)}
                  message={
                    errors?.patient?.email?.message
                      ? translator(errors?.patient?.email?.message)
                      : ''
                  }
                  onChange={e => {
                    setValue('patient.email', e.target.value);
                  }}
                />

                <Input
                  id="input_motherName"
                  label="Nome da mãe ou responsável"
                  name="patient.motherName"
                  defaultValue={watch('patient.motherName')}
                  register={() => register('patient.motherName')}
                  error={Boolean(errors?.patient?.motherName)}
                  message={
                    errors?.patient?.motherName?.message
                      ? translator(errors?.patient?.motherName?.message)
                      : ''
                  }
                  onChange={e => {
                    setValue('patient.motherName', e.target.value);
                  }}
                />
              </>
            )}
          </Row>
        )}
        <Navigator back={onBack} />
      </Form>
    </Container>
  );
};

export default PatientData;
