/* eslint-disable import/no-unresolved */
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Button,
  Chips,
  InputSearch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tabs,
  TabsList,
  TabsTrigger,
  Typography,
} from '@wisecare-tech/design-system-web';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover as PopoverTiny } from 'react-tiny-popover';
import { iStore } from '~/domain/interfaces/models';
import { Status } from '~/domain/usecases/diagnosisReport/remote';
import { makeReduxListDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListDiagnosisReportFactory';
import { History } from '~/main/routes';
import { IconFilter } from '~/presentation/base/icons';
import FilterLaudo from '~/presentation/components/filter/FilterLaudo';
import { Menu } from '~/presentation/components/menu';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { columns_delayed } from './columns_delayed';
import { columns_draft } from './columns_draft';
import { columns_finish } from './columns_finish';
import { columns_laudando } from './columns_laudando';
import { columns } from './columns_original';
import { columns_pending } from './columns_pending';
import { columns_returned } from './columns_returned';
import { Body, Container, FilterBox, FilterIconBox, TabsBox } from './styles';

export interface LaudoFilterProps {
  startDate?: string;
  endDate?: string;
  state?: string;
  healthUnit?: number;
  consultantName?: string;
  profession?: number;
  specialty?: number;
  professional?: string;
  active?: boolean;
  textMatch?: string;
  patient?: number;
  orgUnit?: number;
}

const Laudo: React.FC = () => {
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [filterActive, setFilterActive] = useState(0);
  const [filterStatus, setFilterStatus] = useState<Status>(
    role === 'CON' ? 'PUBLISHED' : 'SUBMITTED',
  );
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const [filterData, setFilterData] = useState<LaudoFilterProps>({
    startDate: undefined,
    endDate: undefined,
    state: '25',
    healthUnit: undefined,
    consultantName: undefined,
    profession: undefined,
    specialty: undefined,
    professional: undefined,
    textMatch: undefined,
    active: false,
    patient: undefined,
    orgUnit: undefined,
  });

  const { list } = useSelector((store: iStore) => store.diagnosisReport);
  const { active, patient, professional, ...data } = filterData;

  const professional_id = getProfessionalInfo();
  const dispatch = useDispatch();

  const id_user = useSelector((store: iStore) => store.auth.info.user?.id);

  const table = useReactTable({
    data: list?.records ?? [],
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_delayed = useReactTable({
    data: list?.records ?? [],
    columns: columns_delayed,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_finish = useReactTable({
    data: list?.records ?? [],
    columns: columns_finish,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_draft = useReactTable({
    data: list?.records ?? [],
    columns: columns_draft,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_laudando = useReactTable({
    data: list?.records ?? [],
    columns: columns_laudando,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_pending = useReactTable({
    data: list?.records ?? [],
    columns: columns_pending,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const table_devolution = useReactTable({
    data: list?.records ?? [],
    columns: columns_returned,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  const renderTable = {
    SUBMITTED: table,
    DELAYED: table_delayed,
    EDITING: table_draft,
    REPORTING: table_laudando,
    PENDING: table_pending,
    CANCELED: table_draft,
    PUBLISHED: table_finish,
    DISTRIBUTION: table_delayed,
    RETURNED: table_devolution,
  };

  const handleNewLaudo = () => {
    History.push('/diagnosis/create');
  };

  const handleFilter = (value: number | undefined) => {
    makeReduxListDiagnosisReport().list({
      body: {
        dataControl: {
          limit: 9999,
          offset: 0,
        },
        orderBy: [
          {
            order: 1,
            attribute:
              filterStatus === 'EDITING' || filterStatus === 'PUBLISHED'
                ? 'UPDATED'
                : 'CREATED',
            direction: 'DESC',
          },
        ],
        filters: {
          status: filterStatus === 'DELAYED' ? undefined : [filterStatus],
          delayed: filterStatus === 'DELAYED' ? true : undefined,
          specialist: value === 2 ? professional_id?.id : undefined,
          requester: value === 1 ? id_user : undefined,
          textMatch:
            filterData.textMatch !== '' ? filterData.textMatch : undefined,
          orgUnit: filterData.orgUnit,
        },
      },
    });
  };

  const handleFilterStatus = (value: Status) => {
    setFilterStatus(value);

    makeReduxListDiagnosisReport().list({
      body: {
        dataControl: {
          limit: 9999,
          offset: 0,
        },
        orderBy: [
          {
            order: 1,
            attribute:
              value === 'EDITING' || value === 'PUBLISHED'
                ? 'UPDATED'
                : 'CREATED',
            direction: 'DESC',
          },
        ],
        filters: {
          status: value === 'DELAYED' ? undefined : [value],
          delayed: value === 'DELAYED' ? true : undefined,
          specialist: filterActive === 2 ? professional_id?.id : undefined,
          requester: filterActive === 1 ? id_user : undefined,
          textMatch:
            filterData.textMatch !== '' ? filterData.textMatch : undefined,
          orgUnit: filterData.orgUnit,
        },
      },
    });
  };

  const handleClosePopover = useCallback(() => {
    setIsPopoverOpen(false);
  }, []);

  const handleDisplayModal = () => {
    if (isPopoverOpen) {
      setIsPopoverOpen(false);
    } else {
      setIsPopoverOpen(true);
    }
  };

  const handleInputSearch = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    let formattedStartDate;
    let formattedEndDate;

    if (data.startDate)
      formattedStartDate = data.startDate.split('/').reverse().join('-');

    if (data.endDate)
      formattedEndDate = data.endDate.split('/').reverse().join('-');

    makeReduxListDiagnosisReport().list({
      body: {
        dataControl: {
          limit: 9999,
          offset: 0,
        },
        orderBy: [
          {
            order: 1,
            attribute:
              filterStatus === 'EDITING' || filterStatus === 'PUBLISHED'
                ? 'UPDATED'
                : 'CREATED',
            direction: 'DESC',
          },
        ],
        filters: {
          status: filterStatus === 'DELAYED' ? undefined : [filterStatus],
          delayed: filterStatus === 'DELAYED' ? true : undefined,
          timestamp:
            formattedStartDate || formattedEndDate
              ? {
                  begin:
                    formattedStartDate !== '' ? formattedStartDate : undefined,
                  end: formattedEndDate !== '' ? formattedEndDate : undefined,
                }
              : undefined,
          ...data,
          consultant: patient ?? undefined,
          specialist: professional ? Number(professional) : undefined,
          textMatch:
            data.textMatch !== '' && data.textMatch !== undefined
              ? data.textMatch
              : undefined,
          orgUnit: filterData.orgUnit,
        },
      },
    });
  };

  useEffect(() => {
    makeReduxListDiagnosisReport().list({
      body: {
        dataControl: {
          limit: 9999,
          offset: 0,
        },
        orderBy: [
          {
            order: 1,
            attribute: role === 'CON' ? 'UPDATED' : 'CREATED',
            direction: 'DESC',
          },
        ],
        filters: {
          status: [role === 'CON' ? 'PUBLISHED' : 'SUBMITTED'],
        },
      },
    });
  }, [role]);

  return (
    <Container>
      <Menu />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fff',
          height: 48,
          border: '1px solid #dedede',
        }}
      >
        <Typography variant="b2_14semibold">Laudo</Typography>
      </div>

      <Body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          {role !== 'CON' && role !== 'ORG' ? (
            <TabsBox>
              <Chips
                text="Todos"
                variant={filterActive === 0 ? 'blue' : 'white'}
                onClick={() => {
                  setFilterActive(0);
                  handleFilter(undefined);
                }}
              />
              <Chips
                text="Solicitados por mim"
                variant={filterActive === 1 ? 'blue' : 'white'}
                onClick={() => {
                  setFilterActive(1);
                  handleFilter(1);
                }}
              />
              {role === 'PRO' && (
                <Chips
                  text="Atribuídos a mim"
                  variant={filterActive === 2 ? 'blue' : 'white'}
                  onClick={() => {
                    setFilterActive(2);
                    handleFilter(2);
                  }}
                />
              )}
            </TabsBox>
          ) : (
            <div />
          )}

          <FilterBox>
            <div
              style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}
            >
              <form onSubmit={handleInputSearch}>
                <InputSearch
                  value={filterData.textMatch}
                  onChange={(e: any) => {
                    setFilterData(prevState => ({
                      ...prevState,
                      textMatch: e.target.value,
                    }));
                    if (e.target.value === '') {
                      makeReduxListDiagnosisReport().list({
                        body: {
                          dataControl: {
                            limit: 9999,
                            offset: 0,
                          },
                          orderBy: [
                            {
                              order: 1,
                              attribute: 'CREATED',
                              direction: 'DESC',
                            },
                          ],
                          filters: {
                            status: [filterStatus],
                            ...filterData,
                          },
                        },
                      });
                    }
                  }}
                />
              </form>
            </div>

            <PopoverTiny
              onClickOutside={e => {
                const target = e.target as HTMLElement;

                if (target.id.includes('react-select')) {
                  return;
                }

                handleClosePopover();
              }}
              isOpen={isPopoverOpen}
              align="end"
              positions={['bottom']}
              content={
                <FilterLaudo
                  handleClose={handleClosePopover}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  status={filterStatus}
                />
              }
              containerStyle={{
                zIndex: '11',
              }}
            >
              <FilterIconBox onClick={handleDisplayModal}>
                <IconFilter />
              </FilterIconBox>
            </PopoverTiny>

            {role !== 'CON' && role !== 'ORG' && (
              <Button size="md" onClick={handleNewLaudo}>
                Solicitar laudo
              </Button>
            )}
          </FilterBox>
        </div>
        {role !== 'CON' && (
          <Tabs
            defaultValue="submitted"
            style={{
              border: '1px solid #dedede',
              borderRadius: 4,
              borderBottom: 'none',
            }}
          >
            <TabsList>
              <TabsTrigger
                onClick={() => handleFilterStatus('SUBMITTED')}
                value="submitted"
              >
                Em aberto ({list?.overview?.SUBMITTED})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => handleFilterStatus('DELAYED')}
                value="delayed"
              >
                Atrasados ({list?.overview?.DELAYED})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => handleFilterStatus('EDITING')}
                value="editing"
              >
                Rascunho ({list?.overview?.EDITING})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => handleFilterStatus('REPORTING')}
                value="reporting"
              >
                Laudando ({list?.overview?.REPORTING})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => handleFilterStatus('PENDING')}
                value="pending"
              >
                Pendentes ({list?.overview?.PENDING})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => handleFilterStatus('RETURNED')}
                value="returned"
              >
                Devolvidos ({list?.overview?.RETURNED})
              </TabsTrigger>
              <TabsTrigger
                onClick={() => handleFilterStatus('PUBLISHED')}
                value="published"
              >
                Concluidos ({list?.overview?.PUBLISHED})
              </TabsTrigger>
            </TabsList>
          </Tabs>
        )}
        <Table style={{ border: '1px solid #dedede' }}>
          <TableHeader>
            {renderTable[filterStatus as Status]
              .getHeaderGroups()
              .map(headerGroup => (
                <>
                  {headerGroup.headers.map(header => (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  ))}
                </>
              ))}
          </TableHeader>
          <TableBody style={{ backgroundColor: 'white' }}>
            {renderTable[filterStatus as Status].getRowModel().rows.map(row => (
              <TableRow key={row.id}>
                {row.getAllCells().map(cell => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Body>
    </Container>
  );
};

export default Laudo;
