import React, { useEffect, useState } from 'react';
import { V4hSpin } from '~/presentation/components/spin';
import { Container, ListPlaceholder, TableContainer } from './styles';
import ShiftListControls from './ShiftListControls';
import { ShiftsTable } from '~/presentation/components/shiftsTable';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import {
  ShiftByDay,
} from '~/domain/interfaces/models/TeleShifts';
import { useTeleShifts } from '~/presentation/hooks/teleShifts';
import { Typography } from '@wisecare-tech/design-system-web';

const TeleShifts: React.FC = () => {
  const { shifts, loading } = useSelector((store: iStore) => store.teleShifts);
  const { convertShiftsToShiftByDay } = useTeleShifts();
  const [shiftsByDay, setShiftsByDay] = useState<ShiftByDay[]>([]);

  useEffect(() => {
    setShiftsByDay(convertShiftsToShiftByDay(shifts));

    return () => {
      setShiftsByDay([]);
    };
  }, [shifts]);

  return (
    <Container>
      <ShiftListControls />
      {loading ? (
        <V4hSpin fullScreen background="rgba(0, 0, 0, 0)" />
      ) : (
        <TableContainer>
          <ListPlaceholder key={shiftsByDay.length}>
            {shiftsByDay && shifts.length ? (
              shiftsByDay.map(
                (shift, index) =>
                  shift && <ShiftsTable key={index} shift={shift} />,
              )
            ) : (
              <Typography variant="t4_16medium">
                Não há escala neste período
              </Typography>
            )}
          </ListPlaceholder>
        </TableContainer>
      )}
    </Container>
  );
};

export default TeleShifts;
