import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: #fff;

  border: 1px solid #dedede;
  overflow: hidden;
`;

export const ListHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  border-bottom: 1px solid #dedede;
`;

export const ContainerEmpty = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const ContainerEmptyIcon = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
`;

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
