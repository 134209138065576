import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 1.75rem 4.5rem;
  gap: 8px;
  background-color: #f8f8f8;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ListPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.5rem;
`;
