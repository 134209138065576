import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { iMessage, iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import {
  Container,
  ContainerModal,
  Header,
  Titles,
  SelectLabel,
  Footer,
  Form,
  FormRow,
  InlineSelects,
} from '../styles/StyledAddProfessionalToScaleModal';
import {
  Button,
  IconButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import { Select } from '../../UI';
import { useSelector } from 'react-redux';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { makeReduxGetAllProfession } from '~/main/factories/usecases/profession';
import { makeReduxGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import { makeRemoteListMacroRegion } from '~/main/factories/usecases/macroRegion/ListMacroRegionFactory';
import { ListMacroRegion } from '~/domain/usecases/macroRegion/remote';
import { AlertMessage } from '../../messages/AlertMessage';
import { listDaysOfTheWeek } from '~/utils/listDatesOfTheWeek';
import { generateTimeSlots } from '~/utils/generateTimeSlots';
import { zodResolver } from '@hookform/resolvers/zod';
import { updateShiftValidator } from '~/validation/validators/shift/UpdatedShift';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { filterLaterTimes } from '~/utils/filterLaterTimes';
import { makeRemoteCreateTeleshifts } from '~/main/factories/usecases/teleShifts/CreateTeleshitsFactory';
import { format, add } from 'date-fns';
import { makeRemoteListSystemModule } from '~/main/factories/usecases/systemModule/ListSystemModuleFactory';
import { ListSystemModule } from '~/domain/usecases/systemModule/remote';

import { closeModal } from '~/utils/closeModal';
import { makeReduxListShifts } from '~/main/factories/usecases/teleShifts';

type Props = {
  message: iMessage;
};

type FormData = {
  weekDay: string;
  day: string;
  begin: string;
  end: string;
  unit: string;
  macroregion: string;
  profession: string;
  specialty: string;
  professional: string;
  systemModule: string;
};

export const AddProfessionalToScaleModal: React.FC<Props> = ({ message }) => {
  const { active, actionCancel, data } = message;

  const msgName = MessageOptions.addProfessionalToScale;

  const { results } = useSelector((store: iStore) => store.orgUnit);

  const { info } = useSelector((store: iStore) => store.auth);

  const { results: resultsProfession } = useSelector(
    (store: iStore) => store.professions,
  );
  const { results: resultsSpecialty } = useSelector(
    (store: iStore) => store.specialty,
  );
  const { results: resultsProfessionals } = useSelector(
    (store: iStore) => store.professional,
  );

  const [macroRegion, setMacroRegion] =
    useState<ListMacroRegion.Model['records']>();

  const [systemModule, setSystemModule] =
    useState<ListSystemModule.Model['records']>();

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm<FormData>({
    mode: 'all',
    resolver: zodResolver(updateShiftValidator),
    defaultValues: {
      begin: '',
      end: '',
      day: '',
      macroregion: '',
      profession: '',
      professional: '',
      specialty: '',
      unit: '',
      systemModule: '',
      weekDay: '',
    },
  });

  const daysOfWeek = listDaysOfTheWeek();

  const times = generateTimeSlots();

  const hoursEnd = watch('begin')
    ? filterLaterTimes(watch('begin'), times)
    : [];

  const resetForm = () => {
    setValue('begin', '');
    setValue('end', '');
    setValue('day', '');
    setValue('macroregion', '');
    setValue('profession', '');
    setValue('professional', '');
    setValue('specialty', '');
    setValue('unit', '');
    setValue('systemModule', '');
    setValue('weekDay', '');
  };

  const onSubmit: SubmitHandler<FormData> = item => {
    const day = item?.day.split('/');
    const formattedDay = `${day[2]}-${day[1]}-${day[0]}`;

    makeRemoteCreateTeleshifts()
      .create({
        start: formattedDay,
        end: formattedDay,
        timezone: new Date().getTimezoneOffset(),
        user: Number(item?.professional),
        days: {
          [item?.weekDay]: {
            start: format(
              add(new Date(formattedDay), {
                hours: Number(item?.begin.split(':')[0]),
                minutes: Number(item?.begin.split(':')[1]),
              }),
              'HH:mm:ss',
            ),
            end: format(
              add(new Date(formattedDay), {
                hours: Number(item?.end.split(':')[0]),
                minutes: Number(item?.end.split(':')[1]),
              }),
              'HH:mm:ss',
            ),
            restrictions: [
              {
                macroregion: Number(item?.macroregion),
                orgUnit: Number(item?.unit),
                specialty: Number(item?.specialty),
                module: Number(item?.systemModule),
              },
            ],
          },
        },
      })
      .then(() => {
        AlertMessage({
          message: 'Profissional adicionado com sucesso!',
          type: 'success',
        });
        if (data) {
          makeReduxListShifts().list({
            filters: {
              start: format(data?.shiftStart, 'yyyy-MM-dd'),
              end: format(data?.shiftEnd, 'yyyy-MM-dd'),
            },
          });
        }
        resetForm();
        closeModal();
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao adicionar profissional',
          type: 'danger',
        });
      });
  };

  useEffect(() => {
    if (active !== msgName) return;

    makeReduxGetAllOrgUnitList().list({
      filter: {
        enabled: true,
      },
    });

    makeReduxGetAllProfession().getAll({
      filter: {
        enabled: true,
        hasProfessional: true,
      },
    });

    makeRemoteListMacroRegion()
      .list({})
      .then(response => {
        setMacroRegion(response.records);
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao carregar as macrorregiões',
          type: 'danger',
        });
      });

    makeRemoteListSystemModule()
      .list({})
      .then(response => {
        setSystemModule(response?.records);
      })
      .catch(() => {
        AlertMessage({
          message: 'Erro ao carregar os módulos',
          type: 'danger',
        });
      });
  }, [active]);

  useEffect(() => {
    if (active !== msgName) return;

    makeReduxGetAllSpecialty().getAll({
      filter: {
        hasProfessional: true,
        enabled: true,
        profession: Number(watch('profession')),
      },
    });

    register('specialty');
    register('professional');

    setValue('specialty', '');
    setValue('professional', '');
  }, [active, watch('profession')]);

  useEffect(() => {
    const findDate = daysOfWeek?.find(
      item => item?.dayOfWeek === watch('weekDay'),
    )?.date;

    register('day');
    setValue('day', findDate);
  }, [watch('weekDay')]);

  useEffect(() => {
    if (active !== msgName) return;

    const day = watch('day')?.split('/');
    const formattedDay = `${day[2]}-${day[1]}-${day[0]}`;

    makeReduxGetAllProfessionals().getAll({
      filter: {
        enabled: true,
        specialty: Number(watch('specialty')),
        situation: ['ACTIVE', '2FAWAIT'],
        org: info?.organizers?.[0]?.org?.id,
        shift: {
          day: formattedDay,
        },
      },
    });
  }, [active, watch('day'), watch('professional')]);

  return (
    <>
      {active === msgName && (
        <ContainerModal>
          <Container>
            <Header>
              <Titles>
                <Typography variant="t1_18semibold">
                  Adicionar profissional
                </Typography>
                <Typography variant="b1_16regular" style={{ color: '#656A6E' }}>
                  Indique um profissional para fazer parte da escala por um
                  único dia e horário selecionado.
                </Typography>
              </Titles>
              <IconButton
                icon="close"
                variant="primary"
                size="sm"
                onClick={actionCancel}
              />
            </Header>
            <Form>
              <FormRow>
                <InlineSelects>
                  <SelectLabel required>Dia da semana (Data)</SelectLabel>
                  <Controller
                    name="weekDay"
                    control={control}
                    render={({ onChange, value }) => (
                      <Select
                        {...value}
                        height="40px"
                        error={Boolean(errors.weekDay)}
                        message={errors.weekDay?.message}
                        onChange={onChange}
                      >
                        <option value="">Selecione</option>
                        {daysOfWeek?.map(item => (
                          <option key={item?.dayOfWeek} value={item?.dayOfWeek}>
                            {item?.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </InlineSelects>
                <InlineSelects>
                  <SelectLabel required>Horário</SelectLabel>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <Controller
                      name="begin"
                      control={control}
                      render={({ onChange, value }) => (
                        <Select
                          {...value}
                          height="40px"
                          error={Boolean(errors.begin)}
                          onChange={e => {
                            onChange(e);
                            if (watch('end')) {
                              setValue('end', '', { shouldValidate: true });
                            }
                          }}
                        >
                          <option value="">--:--</option>
                          {times?.map(time => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    <span>até</span>
                    <Controller
                      name="end"
                      control={control}
                      render={({ onChange, value }) => (
                        <Select
                          {...value}
                          height="40px"
                          error={Boolean(errors.end)}
                          onChange={onChange}
                          disabled={!watch('begin')}
                        >
                          <option value="">--:--</option>
                          {hoursEnd?.map(time => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                  {errors?.end?.message && (
                    <span
                      style={{ color: '#EA3E4F', marginTop: 8, fontSize: 12 }}
                    >
                      Os horários estão inválidos
                    </span>
                  )}
                </InlineSelects>
              </FormRow>
              <FormRow>
                <InlineSelects>
                  <SelectLabel required>Módulo</SelectLabel>
                  <Controller
                    name="systemModule"
                    control={control}
                    render={({ onChange, value }) => (
                      <Select
                        {...value}
                        height="40px"
                        error={Boolean(errors.systemModule)}
                        message={errors.systemModule?.message}
                        onChange={onChange}
                      >
                        <option value="">Selecione</option>
                        {systemModule?.map(item => (
                          <option key={item.id} value={item.id}>
                            {item?.descr}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </InlineSelects>
                <InlineSelects>
                  <SelectLabel required>Setor</SelectLabel>
                  <Controller
                    name="unit"
                    control={control}
                    render={({ onChange, value }) => (
                      <Select
                        {...value}
                        height="40px"
                        error={Boolean(errors.unit)}
                        message={errors.unit?.message}
                        onChange={onChange}
                      >
                        <option value="">Selecione</option>
                        {results?.map(item => (
                          <option
                            key={item.orgUnit?.id}
                            value={String(item?.orgUnit?.id)}
                          >
                            {item.orgUnit?.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </InlineSelects>
              </FormRow>
              <FormRow>
                <InlineSelects>
                  <SelectLabel required>Macrorregião</SelectLabel>
                  <Controller
                    name="macroregion"
                    control={control}
                    render={({ onChange, value }) => (
                      <Select
                        {...value}
                        height="40px"
                        error={Boolean(errors.macroregion)}
                        message={errors.macroregion?.message}
                        onChange={onChange}
                      >
                        <option value="">Selecione</option>
                        {macroRegion?.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </InlineSelects>
                <InlineSelects>
                  <SelectLabel required>Profissão</SelectLabel>
                  <Controller
                    name="profession"
                    control={control}
                    render={({ onChange, value }) => (
                      <Select
                        {...value}
                        height="40px"
                        error={Boolean(errors.profession)}
                        message={errors.profession?.message}
                        onChange={onChange}
                      >
                        <option value="">Selecione</option>
                        {resultsProfession?.map(item => (
                          <option
                            key={item?.profession?.id}
                            value={item?.profession?.id}
                          >
                            {item?.profession?.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </InlineSelects>
              </FormRow>
              <FormRow>
                <InlineSelects>
                  <SelectLabel required>Especialidade</SelectLabel>
                  <Controller
                    name="specialty"
                    control={control}
                    render={({ onChange, value }) => (
                      <Select
                        {...value}
                        height="40px"
                        error={Boolean(errors.specialty)}
                        message={errors.specialty?.message}
                        onChange={onChange}
                      >
                        <option value="">Selecione</option>
                        {watch('profession') && (
                          <>
                            {resultsSpecialty?.map(item => (
                              <option
                                key={item?.specialty?.id}
                                value={item?.specialty?.id}
                              >
                                {item?.specialty?.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Select>
                    )}
                  />
                </InlineSelects>
              </FormRow>
              <FormRow>
                <InlineSelects>
                  <SelectLabel required>Profissional</SelectLabel>
                  <Controller
                    name="professional"
                    control={control}
                    render={({ onChange, value }) => (
                      <Select
                        {...value}
                        height="40px"
                        error={Boolean(errors.professional)}
                        message={errors.professional?.message}
                        onChange={e => {
                          onChange(e);
                        }}
                      >
                        <option value="">Selecione</option>
                        {watch('specialty') && (
                          <>
                            {resultsProfessionals?.map(item => {
                              if (
                                item?.specialties?.find(
                                  specialty =>
                                    specialty?.id ===
                                    Number(watch('specialty')),
                                )
                              ) {
                                return (
                                  <option
                                    key={item?.professional?.id}
                                    value={item?.user?.id}
                                    disabled={!item?.shifts?.available}
                                  >
                                    {`${item?.user?.firstName} ${item?.user?.lastName} ${
                                      !item?.shifts?.available
                                        ? '(indisponível)'
                                        : ''
                                    }`}
                                  </option>
                                );
                              }
                            })}
                          </>
                        )}
                      </Select>
                    )}
                  />
                </InlineSelects>
              </FormRow>
            </Form>
            <Footer>
              <Button variant="secondary" onClick={actionCancel}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                Salvar alterações
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};
