import styled from 'styled-components';

export const Container = styled.div`
  width: 390px;
  max-width: 390px;
  border: 1px solid red;
  height: fit-content;
  background-color: #ffffff;
  border: 1px solid #dedede;
  border-radius: 16px;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid #dedede;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  height: 300px;
  max-height: 300px;
  overflow-y: auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  border: 1px solid #dedede;
  gap: 8px;
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Footer = styled.footer`
  border-top: 1px solid #dedede;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
