import { CreateTeleshifts } from '~/domain/usecases/teleShifts/remote/CreateTeleshifts';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  Conflict,
} from '~/domain/errors';

export class RemoteCreateTeleshifts implements CreateTeleshifts {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateTeleshifts.Model>;

  constructor(url: string, httClient: HttpClient<CreateTeleshifts.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (
    params: CreateTeleshifts.Params,
  ): Promise<CreateTeleshifts.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.conflict:
        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
