import React, { useEffect, useState } from 'react';
import { columnsTeleshifts } from '../columns/columns_teleshifts';
import { translator } from '~/presentation/components/i18n';
import { useShifts } from '~/presentation/hooks/shifts';
import { ListDetailedTeleShifts } from '~/domain/usecases/teleShifts/remote';
import { makeRemoteListDetailedShifts } from '~/main/factories/usecases/teleShifts/ListDetailedShiftsFactory';
import {
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Typography,
} from '@wisecare-tech/design-system-web';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  ContainerEmpty,
  ContainerEmptyIcon,
  Texts,
} from '../styles/StyledShiftsList';

export interface ownProps {
  professionalId: string;
  dateRange: {
    start: Date;
    end: Date;
  };
  records: {
    week: number;
    records: ListDetailedTeleShifts.Model['records'];
  };
}

type GroupedRecords = {
  week: number;
  records: ListDetailedTeleShifts.Model['records'];
};

export const ProfessionalShiftsList: React.FC<ownProps> = ({
  professionalId,
  dateRange,
  records,
}) => {
  const [info, setInfo] = useState<ListDetailedTeleShifts.Model['records']>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const table_Info = useReactTable({
    data: records.records ?? [],
    columns: columnsTeleshifts,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Table
        style={{
          overflowY: 'auto',
          height: 'auto',
          borderRadius: '8px',
        }}
      >
        <TableHeader>
          {table_Info.getHeaderGroups().map(headerGroup => (
            <>
              {headerGroup.headers.map(header => (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                </TableHead>
              ))}
            </>
          ))}
        </TableHeader>

        <TableBody
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
          }}
        >
          {table_Info?.getRowModel()?.rows?.length ? (
            <>
              {table_Info.getRowModel().rows.map(row => (
                <TableRow key={row.id}>
                  {row.getAllCells().map(cell => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow style={{ height: '595px', maxHeight: '595px' }}>
              <TableCell
                colSpan={table_Info?.getAllColumns().length}
                className="h-24 text-center"
              >
                <ContainerEmpty>
                  <ContainerEmptyIcon>
                    <Icon name="error" size="h3" color="white-7" />
                  </ContainerEmptyIcon>
                  <Texts>
                    <Typography variant="b3_14medium">
                      Nenhuma escala
                    </Typography>
                    <Typography
                      variant="b4_14regular"
                      style={{ color: '#656A6E' }}
                    >
                      Até o momento, nenhuma escala foi realizada no período de
                      vigência.
                    </Typography>
                  </Texts>
                </ContainerEmpty>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
