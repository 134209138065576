import { RemoveUnavailabilityTeleshifts } from '~/domain/usecases/teleShifts/remote/RemoveUnavailabilityTeleshifts';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  Conflict,
} from '~/domain/errors';

export class RemoteRemoveUnavailabilityTeleshifts
  implements RemoveUnavailabilityTeleshifts
{
  private readonly url: string;

  private readonly httpClient: HttpClient<RemoveUnavailabilityTeleshifts.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<RemoveUnavailabilityTeleshifts.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  delete = async (
    params: RemoveUnavailabilityTeleshifts.Params,
  ): Promise<RemoveUnavailabilityTeleshifts.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.shift}/unavailability`,
      method: 'delete',
      body: params?.body,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.conflict:
        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
