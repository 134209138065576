import { UnavailabilityTeleshifts } from '~/domain/usecases/teleShifts/remote/UnavailabilityTeleshifts';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  Conflict,
} from '~/domain/errors';

export class RemoteUnavailabilityTeleshifts
  implements UnavailabilityTeleshifts
{
  private readonly url: string;

  private readonly httpClient: HttpClient<UnavailabilityTeleshifts.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<UnavailabilityTeleshifts.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  unavailability = async (
    params: UnavailabilityTeleshifts.Params,
  ): Promise<UnavailabilityTeleshifts.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.shift}/unavailability`,
      method: 'post',
      body: params?.body,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.conflict:
        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
