import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { PostAvailabilityDetailed as UseCasePostAvailabilityDetailed } from '~/domain/usecases/availability/remote';

import { RemoteAvailabilityDetailed } from '~/data/repository/availability';

export const makeRemoteAvailabilityDetailed = (): UseCasePostAvailabilityDetailed =>
  new RemoteAvailabilityDetailed(
    makeApiUrl('/professionals/appointments/availability/detailed'),
    makeHttpClient(),
  );
