import React, { useState } from 'react';
import {
  Container,
  Content,
  Footer,
  ContainerModal,
  Text,
  SelectContainer,
} from './styles/StyledModalSketchReporting';
import { Icon, Typography, Button } from '@wisecare-tech/design-system-web';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';
import SearchSelect from '../UI/searchSelect';
import { Select } from '../UI';
import { makeRemotePreSignDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/PreSignDiagnosisReportFactory';
import { makeRemoteSignDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/SignDiagnosisReportFactory';
import { AlertMessage } from '../messages/AlertMessage';
import { Signature } from '../signature';
import { makeRemoteSignDocument } from '~/main/factories/usecases/daemon/Sign';
import { createIntlCache, createIntl } from 'react-intl';
import { getLocale } from '~/utils/getLocale';
import translations from '~/infra/i18n/locales';
import { History } from '~/main/routes';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface ownProps {
  message: iMessage;
}

const ModalSignReport: React.FC<ownProps> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.signReport;

  const [signatureData, setSignatureData] = useState<{
    certificateId: string;
    certificate: string;
    signatureSource?: string;
  }>();
  const [selectedOption, setSelectedOption] = useState<string>('1');
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const handleSubmit = () => {
    makeRemotePreSignDiagnosisReport()
      .presign({
        id: data?.id,
        body: {
          signatureSource: signatureData?.signatureSource ?? 'VIDAAS',
          certificate: signatureData?.certificate,
        },
      })
      .then(resPreSign => {
        if (signatureData?.signatureSource === 'VIDAAS') {
          const url = resPreSign.dataToSign;
          localStorage.setItem('diagnosisReportId', data?.id);
          window.location.href = url;
          AlertMessage({
            message: intl.formatMessage({
              id: 'Documento assinado com sucesso!',
            }),
            type: 'success',
          });
          closeModal();
          return;
        }

        makeRemoteSignDocument()
          .sign({
            certId: signatureData?.certificateId ?? '',
            algorithm: 'SHA256',
            dataToSignArray: [
              {
                dataToSign: resPreSign.dataToSign,
                key: 1,
              },
            ],
          })
          .then(resSign => {
            makeRemoteSignDiagnosisReport()
              .sign({
                id: data?.id,
                body: {
                  signatureSource: signatureData?.signatureSource ?? 'VIDAAS',
                  signatureValue: resSign[0].signature,
                },
              })
              .then(() => {
                AlertMessage({
                  message: intl.formatMessage({
                    id: 'Documento assinado com sucesso!',
                  }),
                  type: 'success',
                });
                History.getHistory().goBack();
                closeModal();
              })
              .catch(err => {
                AlertMessage({
                  message:
                    'Não foi possível atualizar status de assinatura do Laudo.',
                  type: 'danger',
                });
                console.log('>>> Err 3: ', err);
              });
          })
          .catch(err => {
            AlertMessage({
              message: 'Não foi possível assinar o Laudo.',
              type: 'danger',
            });
            console.log('>>> Err 2: ', err);
          });
      })
      .catch(err => {
        AlertMessage({
          message: 'Não foi possível iniciar assinatura do Laudo.',
          type: 'danger',
        });
        console.log('>>> Err 1: ', err);
      });
  };

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#E6F0FB',
                  borderRadius: '9999px',
                  aspectRatio: '1',
                  width: '40px',
                }}
              >
                <Icon name="info" color="blue-7" size="h5" />
              </div>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Assinar o laudo
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Selecione seu assinador e depois seu certificado digital para
                  finalizar a assinatura do documento.
                </Typography>
              </Text>
              <Signature
                changeSelected={(e: {
                  certificateId: string;
                  certificate: string;
                  signatureSource?: string;
                }) =>
                  setSignatureData({
                    certificate: e.certificate,
                    certificateId: e.certificateId,
                    signatureSource: e.signatureSource,
                  })
                }
                changeDisabled={(state: boolean) => setDisableButton(state)}
                onSignSubmit={handleSubmit}
                hideSignLater
              />
            </Content>
            <Footer>
              <Button variant="secondary" onClick={actionCancel && closeModal}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Assinar
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalSignReport;
