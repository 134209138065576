import { GetDiagnosisReportByID as UsecaseRemoteGetDiagnosisReportByID } from '~/domain/usecases/diagnosisReport/remote';
import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { RemoteGetDiagnosisReportByID } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */
export const makeRemoteGetDiagnosisReportByID =
  (): UsecaseRemoteGetDiagnosisReportByID =>
    new RemoteGetDiagnosisReportByID(makeApiUrl('/reports'), makeHttpClient());
