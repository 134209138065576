import {
  Button,
  IconButton,
  Typography,
} from '@wisecare-tech/design-system-web';
import axios from 'axios';
import { format, parse } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeApiUrl } from '~/main/factories/http';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import Dropdown from '~/presentation/components/drop-down';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { useTeleShifts } from '~/presentation/hooks/teleShifts';
import { closeModal } from '~/utils/closeModal';
import {
  CalendarControls,
  Container,
  ControlsContainer,
  LeftControls,
  RightControls,
} from './styles';

import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '~/presentation/base/styles/datepicker.css';

import { ptBR } from 'date-fns/locale';
import { ScaleFilter } from '~/presentation/components/scaleFilter';

const ShiftListControls: React.FC = () => {
  const { token } = useSelector((store: iStore) => store.auth.access);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const {
    weekLabel,
    goToToday,
    goToNextWeek,
    goToPreviousWeek,
    start,
    end,
    setStartWeekInterval,
    setEndWeekInterval,
    filterTeleShifts,
    resetShiftsFilter,
    isFilterActive,
    setThisWeekAsCurrent,
    isOnlyToday,
  } = useTeleShifts();
  const isAdmin = role === 'ADM';
  const isOrganizer = role === 'ORG';

  const [loading, setLoading] = useState(false);

  function formatDate(dateString: string): string {
    if (!dateString) {
      resetShiftsFilter();
      return '';
    }
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());

    return format(parsedDate, "dd 'de' MMMM", { locale: ptBR });
  }

  const resetCalendarState = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const applyCalendarFilters = () => {
    if (startDate && endDate) {
      setStartWeekInterval(startDate);
      setEndWeekInterval(endDate);
    }
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const customHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
    customHeaderCount,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) => (
    <CalendarControls style={{ padding: '0.5rem', paddingTop: '0px' }}>
      <IconButton
        icon="chevron_left"
        variant={'primary'}
        size="sm"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        style={customHeaderCount === 1 ? { visibility: 'hidden' } : undefined}
      />
      <Typography variant="t4_16medium">
        {monthDate.toLocaleString('pt-Br', {
          month: 'long',
          year: 'numeric',
        })}
      </Typography>
      <IconButton
        icon="chevron_right"
        variant={'primary'}
        size="sm"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        style={customHeaderCount === 0 ? { visibility: 'hidden' } : undefined}
      />
    </CalendarControls>
  );

  const customCalendarContainer = ({ children }: ReactDatePickerProps) => (
    <div
      style={{
        fontSize: '0.8rem',
        backgroundColor: '#fff',
        color: '#000',
        border: '1px solid #aeaeae',
        borderRadius: '0.3rem',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        gap: '1rem',
        padding: '0.9rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {children}
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'row-reverse', gap: '1rem' }}
      >
        <Button
          size="sm"
          variant="secondary"
          onClick={() => {
            resetShiftsFilter();
            resetCalendarState();
          }}
        >
          Restaurar padrões
        </Button>
      </div>
    </div>
  );

  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (isFilterActive) {
      resetCalendarState();
    }
  }, [isFilterActive]);

  return (
    <Container>
      <ControlsContainer>
        <LeftControls>
          <Button
            variant={isOnlyToday ? 'primary' : 'secondary'}
            onClick={() => {
              resetShiftsFilter();
              isOnlyToday ? setThisWeekAsCurrent() : goToToday();
            }}
          >
            Hoje
          </Button>
          <div style={{ zIndex: 12 }}>
            <DatePicker
              selectsRange
              startDate={startDate}
              endDate={endDate}
              monthsShown={2}
              onChange={onChange}
              onCalendarClose={applyCalendarFilters}
              customInput={
                <IconButton
                  icon="calendar_month"
                  onClick={() => {}}
                  variant={'primary'}
                  disabled
                  hasNotification={
                    startDate !== null && endDate !== null && !isFilterActive
                  }
                />
              }
              locale={ptBR}
              dateFormat={'dd/MM/yyyy'}
              renderCustomHeader={customHeader}
              calendarContainer={customCalendarContainer}
            />
          </div>
          <CalendarControls>
            <IconButton
              icon="chevron_left"
              variant={'primary'}
              onClick={() => {
                goToPreviousWeek();
                resetCalendarState();
              }}
              disabled={isFilterActive}
            />
            <Typography variant="t4_16medium">
              {isFilterActive
                ? formatDate(filterTeleShifts.day ?? '')
                : weekLabel}
            </Typography>
            <IconButton
              icon="chevron_right"
              variant={'primary'}
              onClick={() => {
                goToNextWeek();
                resetCalendarState();
              }}
              disabled={isFilterActive}
            />
          </CalendarControls>
        </LeftControls>
        <RightControls>
          <Dropdown
            icon={
              <IconButton
                icon="tune"
                variant={'primary'}
                hasNotification={isFilterActive}
              />
            }
          >
            <ScaleFilter />
          </Dropdown>

          <Button
            icon="download"
            onClick={() => {
              setLoading(true);
              axios
                .post(
                  makeApiUrl('/shifts/list/detailed/download'),
                  {
                    filters: {
                      start: format(start, 'yyyy-MM-dd'),
                      end: format(end, 'yyyy-MM-dd'),
                    },
                  },
                  {
                    responseType: 'blob',
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  },
                )
                .then(response => {
                  const file = new Blob([response.data], {
                    type: response?.headers['content-type'],
                  });

                  const fileURL = URL.createObjectURL(file);
                  const link = document.createElement('a');
                  link.href = fileURL;
                  link.download = 'shifts.pdf';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(fileURL);

                  AlertMessage({
                    message: 'Arquivo baixado com sucesso!',
                    type: 'success',
                  });
                })
                .catch(() => {
                  AlertMessage({
                    message: 'Erro ao baixar arquivo!',
                    type: 'danger',
                  });
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
            variant={'secondary'}
            disabled={loading}
          >
            Baixar PDF
          </Button>
          {(isAdmin || isOrganizer) && (
            <Button
              icon="add"
              onClick={() => {
                makeReduxActiveMessage().active({
                  active: 'addProfessionalToScale',
                  data: {
                    shiftStart: start,
                    shiftEnd: end,
                  },
                  actionCancel: () => {
                    closeModal();
                  },
                });
              }}
              variant={'primary'}
            >
              Adicionar profissional
            </Button>
          )}
        </RightControls>
      </ControlsContainer>
    </Container>
  );
};

export default ShiftListControls;
