import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import {
  Input as InputDs,
  Button,
  Typography,
  RadioGroupItem,
  RadioGroup,
  Checkbox,
} from '@wisecare-tech/design-system-web';
import {
  Container,
  ButtonColapse,
  Content,
  WorkTime,
  InfoBlockContainer,
  InfoBlock,
  selectStyles,
  InfoBlockRow,
  InfoAction,
  Select,
  SelectContainer,
  SelectWrapper,
} from './styles';
import { IconArrow, IconArrowSelect } from '~/presentation/base/icons';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import SearchSelect from '~/presentation/components/UI/searchSelect';
import {
  Control,
  DropdownIndicator,
  Option,
} from '~/presentation/components/filter/styles/StyledFilterAppointmentHistory';
import { findIndex } from 'lodash';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { ListMacroRegion } from '~/domain/usecases/macroRegion/remote';
import { ListSystemModule } from '~/domain/usecases/systemModule/remote';
import { makeRemoteListSystemModule } from '~/main/factories/usecases/systemModule/ListSystemModuleFactory';
import { makeRemoteListMacroRegion } from '~/main/factories/usecases/macroRegion/ListMacroRegionFactory';
import DatePickerField from '~/presentation/components/UI/NewDateInput';
import { Toggle } from 'material-ui';
import { Switch } from '~/presentation/components/UI';
import RenderRestrictions from '../Restrictions';

interface ownProps {
  id: 'SU' | 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA';
  title: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

const DayColapse = ({ id, title, disabled, children }: ownProps) => {
  const [colapsed, setColapsed] = React.useState(true);
  const [modules, setModules] = React.useState<
    ListSystemModule.Model['records']
  >([]);
  const [macroregions, setMacroregions] = React.useState<
    ListMacroRegion.Model['records']
  >([]);

  const {
    control,
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useFormContext();

  const { fields } = useFieldArray({ control, name: 'scheduleDays' });
  const fieldsByDay = fields.filter(field => field.day === id);
  const getIndex = () => {
    return fields.findIndex(field => field.day === id);
  };
  const switchRef = useRef(null);

  useEffect(() => {
    if (switchRef.current) {
      console.log('>>> Switch montado!', switchRef.current);
    }
  }, []);

  useEffect(() => {
    const checked = watch(`checkedDays.${getIndex()}.checked`);

    if (colapsed && checked) setColapsed(false);
    else if (!colapsed && !checked) setColapsed(true);
  }, [watch(`checkedDays.${getIndex()}.checked`)]);

  useEffect(() => {
    makeRemoteListSystemModule()
      .list({})
      .then(response => {
        setModules(response.records);
      });

    makeRemoteListMacroRegion()
      .list({})
      .then(response => {
        setMacroregions(response.records);
      });
  }, []);

  useEffect(() => {
    if (disabled) {
      setColapsed(true);
      setValue(`checkedDays.${getIndex()}.checked`, false);
    }
  }, [disabled]);

  return (
    <Container>
      <div className="title">
        <Controller
          name={`checkedDays.${getIndex()}.checked`}
          control={control}
          render={({ onChange, onBlur, value, ref }) => (
            <Switch
              id={`switch-${id}`}
              onBlur={onBlur}
              ref={ref}
              checked={value}
              disabled={disabled}
              onChange={e => {
                setValue(`checkedDays.${getIndex()}.checked`, e.target.checked);
                if (colapsed && e.target.checked) setColapsed(false);
                else if (!colapsed && !e.target.checked) setColapsed(true);
              }}
            ></Switch>
          )}
        />
        <strong>{title}</strong>
        <ButtonColapse
          type="button"
          isColapsed={colapsed}
          disabled={disabled}
          onClick={() => {
            setColapsed(!colapsed);
          }}
        >
          <IconArrowSelect />
        </ButtonColapse>
      </div>
      <Content colapsed={colapsed}>
        {fieldsByDay.map((field, index) => {
          return (
            <>
              <WorkTime>
                <div className="title">
                  Horário de trabalho
                  <span className="subTitle">
                    Horário em que o profissional estará disponível para
                    realizar suas atividades.
                  </span>
                </div>
                <div className="schedule">
                  <DatePickerField
                    name={`scheduleDays.${getIndex()}.start`}
                    placeholderText="HH:mm"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption=""
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    withMask={false}
                    minTime={new Date(0, 0, 0, 0, 0)}
                    maxTime={new Date(watch(`scheduleDays.${getIndex()}.end`))}
                    customInput={
                      <InputDs
                        required
                        autoComplete="off"
                        helperText={errors?.scheduleDays?.message}
                        status={
                          errors?.scheduleDays?.message &&
                          !watch(`scheduleDays.${getIndex()}.start`) &&
                          watch(`checkedDays.${getIndex()}.checked`)
                            ? 'error'
                            : 'default'
                        }
                      />
                    }
                  />
                  <Typography
                    variant="b4_14regular"
                    style={{
                      height: '40px',
                      alignContent: 'center',
                      padding: '0 8px',
                    }}
                  >
                    até
                  </Typography>
                  <DatePickerField
                    name={`scheduleDays.${getIndex()}.end`}
                    placeholderText="HH:mm"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption=""
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    withMask={false}
                    minTime={
                      new Date(watch(`scheduleDays.${getIndex()}.start`))
                    }
                    maxTime={new Date(0, 0, 0, 23, 59)}
                    customInput={
                      <InputDs
                        required
                        autoComplete="off"
                        helperText={errors?.scheduleDays?.message}
                        status={
                          errors?.scheduleDays?.message &&
                          !watch(`scheduleDays.${getIndex()}.end`) &&
                          watch(`checkedDays.${getIndex()}.checked`)
                            ? 'error'
                            : 'default'
                        }
                      />
                    }
                  />
                </div>
              </WorkTime>

              <RenderRestrictions
                control={control}
                register={register}
                mainIndex={getIndex()}
                name={`scheduleDays[${getIndex()}].restrictions`}
                modules={modules}
                macroregions={macroregions}
              />
            </>
          );
        })}
      </Content>
    </Container>
  );
};

export default DayColapse;
