import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Text = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #303950;
  width: calc(100% - 64px);
`;
