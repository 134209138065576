import { Textarea } from '@wisecare-tech/design-system-web';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { ListModalitiesDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import { makeRemoteListModalitiesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListModalitiesDiagnosisReportFactory';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import SearchSelect from '~/presentation/components/UI/searchSelect';
import { useRemoteReport } from '~/presentation/hooks/remoteReport';
import { useGetDiagnosisContext } from '~/presentation/hooks/remoteReport/loadData';
import { useUF } from '~/presentation/hooks/useUF';
import { EditDiagnosisReport } from '~/validation/validators/editDiagnosisReport';
import { Form, FormRow, SectionContainer, SectionLabel } from '../styles';

export const MedicalReportSection: React.FC = () => {
  const { disableField } = useRemoteReport();
  const { loadDiagnosisData, diagnosisData } = useGetDiagnosisContext();

  const { cities, ufs, city: citySelected, handleSelectCity } = useUF();

  const [modalities, setModalities] =
    useState<ListModalitiesDiagnosisReport.Model['records']>();

  console.log('>>> City', citySelected);

  const { records: healthUnits, loading } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { control, setValue, errors } = useFormContext<EditDiagnosisReport>();

  const isPending = useMemo(() => {
    return diagnosisData?.status === 'PENDING';
  }, [diagnosisData]);

  const loadHealthUnits = useCallback(
    (cityId?: string) => {
      makeReduxGetAllHealthUnits().getAll({
        limit: 9999,
        city: cityId ?? String(citySelected?.id),
      });
    },
    [citySelected],
  );

  const loadModalities = useCallback(() => {
    makeRemoteListModalitiesDiagnosisReport()
      .listModalities({
        body: {
          dataControl: {
            limit: 9999,
            paging: false,
          },
        },
      })
      .then(res => {
        setModalities(res.records);
      });
  }, []);

  useEffect(() => {
    loadDiagnosisData();
  }, [loadDiagnosisData]);

  useEffect(() => {
    loadHealthUnits();
  }, [loadHealthUnits, citySelected]);

  useEffect(() => {
    loadModalities();
  }, [loadModalities]);

  return (
    <SectionContainer>
      <SectionLabel>Laudo</SectionLabel>
      <Form>
        <FormRow>
          <SearchSelect
            isDisabled
            label="Estado"
            name="state"
            onChange={() => {}}
            options={ufs}
            defaultValue={{ label: 'Paraíba', value: 'PB' }}
            value={{ label: 'Paraíba', value: 'PB' }}
          />
          <SearchSelect
            isDisabled={disableField || isPending}
            options={cities}
            getOptionLabel={option => option.nome}
            getOptionValue={option => option.id}
            label="Munícipio"
            placeholder="Selecione um município"
            onChange={option => {
              handleSelectCity({
                ...option,
                id: option.id,
                nome: option.nome,
              });
              loadHealthUnits(option.id);
              setValue('healthUnit', -1, {
                shouldValidate: true,
              });
            }}
            value={citySelected}
          />
        </FormRow>

        <Controller
          control={control}
          name="healthUnit"
          key="healthUnit"
          render={field => {
            return (
              <SearchSelect
                label="Unidade de saúde"
                isLoading={loading}
                isDisabled={disableField || loading || isPending}
                placeholder="Selecione uma unidade de saúde"
                options={healthUnits}
                {...field}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                message={
                  errors.healthUnit?.message
                    ? 'Unidade de saúde inválida.'
                    : undefined
                }
              />
            );
          }}
        />
        <FormRow>
          <Controller
            control={control}
            name="modality"
            key="modality"
            render={field => (
              <SearchSelect
                isDisabled={disableField || isPending}
                placeholder="Selecione uma modalidade"
                options={modalities}
                label="Modalidade do laudo"
                {...field}
                getOptionLabel={option => option.descr}
                getOptionValue={option => option.id}
                message={
                  errors.modality?.message ? 'Modalidade inválida.' : undefined
                }
              />
            )}
          />
          <Controller
            control={control}
            name="priority"
            key="priority"
            render={field => (
              <SearchSelect
                isDisabled={disableField || isPending}
                placeholder="Selecione a prioridade"
                options={[
                  {
                    label: 'Baixa',
                    value: 'LOW',
                  },
                  {
                    label: 'Média',
                    value: 'NORMAL',
                  },
                  {
                    label: 'Alta',
                    value: 'HIGH',
                  },
                ]}
                label="Prioridade"
                {...field}
                getOptionLabel={option =>
                  option.label === 'Normal' ? 'Média' : option.label
                }
                message={
                  errors.priority?.message ? 'Prioridade inválida.' : undefined
                }
              />
            )}
          />
        </FormRow>
        <Controller
          control={control}
          name="description"
          render={field => (
            <Textarea
              label="Subjetivo e Objetivo"
              required
              disabled={disableField}
              {...field}
              status={errors.description?.message ? 'error' : undefined}
              helperText={
                errors.description?.message ? 'Campo obrigatório.' : undefined
              }
            />
          )}
        />
      </Form>
    </SectionContainer>
  );
};
