import {
  Icon,
  IconButton,
  IconsType,
  KeyOfColors,
  Typography,
} from '@wisecare-tech/design-system-web';
import React, { useEffect, useMemo, useState } from 'react';
import {
  DiagnosisReportListRecords,
  Status,
} from '~/domain/usecases/diagnosisReport/remote';
import { makeRemoteCancelDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/CancelDiagnosisReportFactory';
import { makeRemoteDownloadFilesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/DownloadFilesDiagnosisReportFactory';
import { makeRemoteRejectDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/RejectDiagnosisReport';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { History } from '~/main/routes';
import { Popover } from '~/presentation/components/UI/popover';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { closeModal } from '~/utils/closeModal';
import { Tooltip } from '@material-ui/core';
import { PopoverContentBox } from './styles';
import { makeReduxListDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListDiagnosisReportFactory';

interface ActionsProps {
  actions: DiagnosisReportListRecords['actions'];
  documentId: number;
  currentStatus: Status;
}

interface PopoverContentProps {
  items: {
    label: string;
    icon: IconsType;
    color: `${KeyOfColors}-${number}`;
    onClick: () => void;
  }[];
}

const PopoverContent: React.FC<PopoverContentProps> = ({ items }) => {
  return (
    <PopoverContentBox>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            padding: 16,
          }}
          onClick={item.onClick}
        >
          <Icon name={item.icon} size="small" color={item.color} />
          <Typography variant="b4_14regular" style={{ color: item.color }}>
            {item.label}
          </Typography>
        </div>
      ))}
    </PopoverContentBox>
  );
};

const Actions: React.FC<ActionsProps> = ({
  actions,
  documentId,
  currentStatus,
}) => {
  const [displayIcons, setDisplayIcons] = useState<
    Array<keyof DiagnosisReportListRecords['actions']>
  >([]);

  const handleRefetch = () => {
    if (currentStatus)
      makeReduxListDiagnosisReport().list({
        body: {
          dataControl: {
            limit: 9999,
            offset: 0,
          },
          orderBy: [
            {
              order: 1,
              attribute: 'CREATED',
              direction: 'DESC',
            },
          ],
          filters: {
            status: [currentStatus],
          },
        },
      });
  };

  const handleReport = () => {
    History.getHistory().push(`/diagnosis/report/${documentId}`);
  };

  const handleView = () => {
    History.getHistory().push(`/diagnosis/${documentId}/details`, {
      canEdit: actions.edit,
      canDownload: actions.edit || actions.report,
      canReport: actions.report,
      canReview: actions.review,
    });
  };

  const handleEdit = () => {
    History.getHistory().push(`/diagnosis/${documentId}/details?edit=true`, {
      canEdit: actions.edit,
      canDownload: actions.edit || actions.report,
      canReport: actions.report,
      canReview: actions.review,
    });
  };

  const handleDownload = () => {
    makeRemoteDownloadFilesDiagnosisReport()
      .download({
        report: documentId,
      })
      .then(res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const urlToPdf = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = urlToPdf;
        a.download = 'laudo.pdf';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        AlertMessage({
          type: 'success',
          message: 'Laudo baixado com sucesso',
        });
      })
      .catch(() => {
        AlertMessage({
          type: 'danger',
          message: 'Não foi possível realizar o download do laudo',
        });
      });
  };

  const handleReject = () => {
    makeReduxActiveMessage().active({
      active: 'cancelReportLaudo',
      data: {
        type: 'reject',
      },
      actionOk: () => {
        makeRemoteRejectDiagnosisReport()
          .reject({
            report: documentId,
          })
          .then(() => {
            AlertMessage({
              message: 'Laudo cancelado com sucesso',
              type: 'success',
            });
            handleRefetch();
            closeModal();
          })
          .catch(() => {
            closeModal();
            AlertMessage({
              message: 'Erro ao cancelar laudo',
              type: 'danger',
            });
          });
      },
      actionCancel: closeModal,
    });
  };

  const handleCancel = () => {
    makeReduxActiveMessage().active({
      active: 'cancelReportLaudo',
      data: {
        type: 'cancel',
      },
      actionOk: () => {
        makeRemoteCancelDiagnosisReport()
          .cancel({
            report: documentId,
          })
          .then(() => {
            AlertMessage({
              message: 'Laudo cancelado com sucesso',
              type: 'success',
            });
            handleRefetch();
            closeModal();
          })
          .catch(() => {
            closeModal();
            AlertMessage({
              message: 'Erro ao cancelar laudo',
              type: 'danger',
            });
          });
      },
      actionCancel: closeModal,
    });
  };

  const renderIcons: Record<
    keyof DiagnosisReportListRecords['actions'],
    {
      icon: IconsType;
      label: string;
      onClick: () => void;
      color: `${KeyOfColors}-${number}`;
    }
  > = {
    report: {
      icon: 'edit_document',
      label: 'Laudar',
      onClick: handleReport,
      color: 'black-1',
    },
    view: {
      icon: 'visibility',
      label: 'Ver solicitação',
      onClick: handleView,
      color: 'black-1',
    },
    edit: {
      icon: 'edit',
      label: 'Editar solicitação',
      onClick: handleEdit,
      color: 'black-1',
    },
    review: {
      icon: 'edit',
      label: 'Editar solicitação',
      onClick: handleEdit,
      color: 'black-1',
    },
    download: {
      icon: 'download',
      label: 'Baixar laudo',
      onClick: handleDownload,
      color: 'black-1',
    },
    reject: {
      icon: 'cancel',
      label: 'Rejeitar',
      onClick: handleReject,
      color: 'red-7',
    },
    cancel: {
      icon: 'cancel',
      label: 'Cancelar',
      onClick: handleCancel,
      color: 'red-7',
    },
    share: {
      icon: 'download',
      label: 'Compartilhar',
      onClick: handleDownload,
      color: 'black-1',
    },
  };

  const renderShowIcons = useMemo(() => {
    const hasMoreOptions = displayIcons.length > 2;
    const renderValues = displayIcons.slice(0, hasMoreOptions ? 1 : 2);
    const renderMoreOptions = displayIcons.slice(1);

    const formatItems = renderMoreOptions.map(item => ({
      label: renderIcons[item]?.label ?? '',
      icon: renderIcons[item].icon,
      onClick: renderIcons[item].onClick,
      color: renderIcons[item].color,
    }));

    const iconsTooltip = {
      report: 'Laudar',
      view: 'Ver solicitação',
      edit: 'Editar solicitação',
      review: 'Editar solicitação',
      download: 'Baixar laudo',
      reject: 'Rejeitar',
      cancel: 'Cancelar',
      share: 'Compartilhar',
    };

    return (
      <div style={{ display: 'flex', gap: 8 }}>
        {renderValues.map((value, index) => (
          <Tooltip
            title={iconsTooltip[value]}
            placement="top"
            arrow
            key={value}
          >
            <IconButton
              style={{ cursor: 'pointer' }}
              size="sm"
              icon={renderIcons[value].icon}
              variant="primary"
              color={renderIcons[value].color}
              onClick={renderIcons[value].onClick}
            />
          </Tooltip>
        ))}
        {hasMoreOptions && (
          <Popover
            originX="right"
            originY="bottom"
            content={<PopoverContent items={formatItems} />}
          >
            <Tooltip title="Mais opções" placement="top" arrow>
              <IconButton size="sm" icon="more_vert" variant="primary" />
            </Tooltip>
          </Popover>
        )}
      </div>
    );
  }, [displayIcons, renderIcons]);

  useEffect(() => {
    const arr: Array<keyof DiagnosisReportListRecords['actions']> = [];

    Object.entries(actions).forEach(([key, value]) => {
      if (value && key !== 'assign' && key !== 'share') {
        if (key === 'review' && arr.includes('edit')) return;
        if (key === 'edit' && arr.includes('review')) return;
        arr.push(key as keyof DiagnosisReportListRecords['actions']);
      }
    });

    setDisplayIcons(arr);
  }, [actions]);

  return renderShowIcons;
};

export default Actions;
