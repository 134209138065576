import {
  array,
  enum as enum_,
  number,
  object,
  string,
  infer as zodInferType,
  boolean,
} from 'zod';

const selectObjectSchema = object({
  id: number().nullable(),
});

export const schemaEditDiagnosisReport = object({
  healthUnit: object({
    administration: string().nullable().optional(),
    id: number(),
    cnes: string(),
    name: string(),
    fantasyName: string().nullable().optional(),
    city: object({
      id: number(),
      name: string(),
      code: string(),
      codeFull: string(),
    }),
  }).nullable(),
  modality: object({
    id: number(),
    msgKey: string(),
    descr: string(),
    specialty: number().nullable().optional(),
    client: string().nullable().optional(),
    instructions: string().nullable().optional(),
  }).nullable(),
  specialty: object({
    id: number(),
    name: string(),
    profession: object({
      id: number(),
      name: string(),
      docProfName: string().nullable().optional(),
    }),
    index: object({
      page: number().nullable().optional(),
      index: number().nullable().optional(),
    })
      .nullable()
      .optional(),
    specialty: object({
      id: number().nullable().optional(),
      name: string().nullable().optional(),
      descr: string().nullable().optional(),
      enabled: boolean().nullable().optional(),
      created: string().nullable().optional(),
      profession: number().nullable().optional(),
    })
      .nullable()
      .optional(),
  }),
  priority: object({
    label: string(),
    value: enum_(['LOW', 'NORMAL', 'HIGH']),
  }).nullable(),
  description: string().nullable(),
  specialist: object({
    id: number(),
    userId: number(),
    fullname: string(),
    avatar: string().nullable(),
  }).nullable(),
  consultantInfo: string().nullable().optional(),
  consultantExternal: object({
    id: number().nullable(),
    fullname: string().nullable(),
    sex: object({
      label: string(),
      value: enum_(['FEMALE', 'MALE', 'OTHER']),
    }),
    birthdate: string(),
    docType: object({
      name: string(),
    }),
    docValue: string(),
    mothername: string().nullable().optional(),
    phone: string(),
  }).nullable(),
  files: array(
    object({
      id: number().nullable(),
      filename: string().optional(),
      size: string().nullable(),
      examType: number().nullable(),
      examTypeName: string().nullable().optional(),
      mimetype: string().nullable().optional(),
      created: string().nullable().optional(),
      sentBy: object({
        id: number().nullable().optional(),
        fullname: string().nullable().optional(),
        avatar: string().nullable().optional(),
        healthUnit: string().nullable().optional(),
      })
        .nullable()
        .optional(),
    }).nullable(),
  ).nullable(),
});

export type EditDiagnosisReport = zodInferType<
  typeof schemaEditDiagnosisReport
>;
